<a class="producto__link" routerLink="{{item.fullLink}}">
  <div class="resultado__item__content" [ngClass]="{'resultado__item__disabled': item.habilitado == '0' || (item.enStock == '0' && item.ventaSoloPorPedido == '0')}">
    <div class="imgContainer">
      <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
      <div class="imagen">
        <img width="150" height="100" *ngIf="item.urlImagen" style="max-width: 100%; height: auto;" [ngClass]="'lazyload'" [attr.data-src]="item.urlImagen" onerror="this.src='/assets/images/producto/picGenerica.webp'" alt="">
      </div>
    </div>
    <div class="tituloContainer">
      <p *ngIf="item.nombreMostrar" class="title" [innerHTML]='item.nombreMostrar | highlight : search_term'></p>
      <p *ngIf="!item.nombreMostrar" class="title" [innerHTML]='item.titulo | highlight : search_term'></p>
      <p class="description" [innerHTML]='item.tituloAdicional | highlight : search_term'></p>
    </div>
  </div>
</a>
<div class="cantidad" *ngIf="config.switchComprasActivo">
  <div class="cantidad--right" [class.alerta]="item.incompleto">
    <div class="precio" *ngIf="(sesion || item.oferta) && item.precio_mostrado > 0" [ngClass]="{'resultado__item__disabled': item.habilitado == '0' || (item.enStock == '0' && item.ventaSoloPorPedido == '0')}">
      <p>${{item.precio_mostrado | number : '.2'}}</p>
      <span *ngIf="iva_usuario" style="margin-left: 5px">{{iva_usuario}}</span>
    </div>
    <div class="precio-oferta" *ngIf="item.oferta && item.precio_mostrado > 0 && item.precio_base > 0 && item.precio_base > item.precio">
      <p>${{item.precio_base | number : '.2'}}</p>
    </div>
    <!--div class="side">
      <p>{{item.unidadMedida}}</p>
    </div-->
  </div>
  <div class="cantidad--left" [ngClass]="{'resultado__item__disabled': item.habilitado == '0' || (item.enStock == '0' && item.ventaSoloPorPedido == '0')}">
    <div class="custom-select" *ngIf="(sesion || item.oferta) && item.precio_mostrado > 0">
      <input *ngIf="item.cantPack === '1'" name="cantidad" aria-label="Cantidad" (keyup.enter)="enterCheck()" type="number" min="1" max="999999" [(ngModel)]="item.cantidad" (keyup)="revisarCantidad($event)" [disabled]="item.habilitado == '0' || (item.enStock == '0' && item.ventaSoloPorPedido == '0')">
      <select *ngIf="item.cantPack !== '1'" name="cantidad" aria-label="Cantidad" [(ngModel)]="item.cantidad" [disabled]="item.habilitado == '0' || (item.enStock == '0' && item.ventaSoloPorPedido == '0')">
        <option *ngFor="let cant of arrayCants" [ngValue]="cant">{{cant}}</option>
      </select>
    </div>
    <div class="resultado__item__comprar w-100" *ngIf="config.switchComprasActivo && !relacionado">
      <button type="button" class="actionButton" *ngIf="sesion && item.habilitado == '1' && item.comprado == false && (item.enStock == '1' || item.ventaSoloPorPedido == '1')" (click)="newMessage(item)">
        <span class="span-comprar">COMPRAR</span>
        <span><i class="fa fa-cart-plus faButton" aria-hidden="true"></i></span>
      </button>
      <button type="button" class="bloqButton" *ngIf="sesion && (item.habilitado == '0' || (item.comprado == false && item.enStock == '0' && item.ventaSoloPorPedido == '0'))">
        <span class="span-stock">SIN STOCK</span>
        <i class="fa fa-shopping-cart faButton" aria-hidden="true"></i>
      </button>
      <button type="button" class="actionButton btn-outline-success" *ngIf="sesion && item.comprado == true" (click)="removeMessage(item)">
        <span class="span-agregado">AGREGADO</span>
        <i class="fa  fa-shopping-cart faButtonAgregado" aria-hidden="true"></i>
        <i class="fa fa-check faButtonAgregado" aria-hidden="true"></i>
      </button>
      <button type="button" class="actionButton" *ngIf="!sesion" (click)="newMessage(item)">
        <span class="span-ver-precio">VER PRECIO</span>
        <i class="fa fa-cart-plus faButton" aria-hidden="true"></i>
      </button>
    </div>
  </div>
</div>

<!-- Cucardas position absolute -->

<!-- Sin stock -->
<div *ngIf="item.habilitado == '0' || (item.enStock == '0' && item.ventaSoloPorPedido == '0')" class="sinStock">
  <img width="60" height="33" [ngClass]="'lazyload'" data-src="./assets/images/iconos/sin-stock.webp" alt="sin stock">
</div>
<div *ngIf="item.habilitado == '1' && item.ventaSoloPorPedido == '1'" class="porPedido">
  <img width="90" height="45" [ngClass]="'lazyload'" data-src="./assets/images/iconos/consultar-stock.webp" alt="por pedido">
</div>
<!-- Sustentables -->
<div *ngIf="item.habilitado == '1' && item.sustentable == '1'" class="itemSustentable" [style.margin-top]="itemcarousel === true ? '-10px' : ''">
  <img width="50" height="51" [ngClass]="'lazyload'" data-src="./assets/images/iconos/sustentable.webp" alt="Sustentable">
</div>

<!-- Productos Sina -->
<!-- TODO: agregar cucarda productos sina conseguir logo + texto "Precios corajudos" -->

<!-- Ofertas -->
<div *ngIf="item.oferta" class="oferta" [ngClass]="{'resultado__item__disabled': item.enStock == '0' && item.ventaSoloPorPedido == '0'}">
  <h3 class="badge">OFERTA</h3>
  <span class="span-oferta-guion" *ngIf="item.show_descuento && item.descuento"> -</span>
  <span class="span-oferta text-danger" *ngIf="item.show_descuento && item.descuento">{{ item.descuento }}% OFF</span>
</div>
<!-- Novedades -->
<div *ngIf="!item.oferta && item.imperdible" class="imperdible" [ngClass]="{'resultado__item__disabled': item.enStock == '0' && item.ventaSoloPorPedido == '0'}">
  <h3 class="badge">NOVEDAD</h3>
</div>
