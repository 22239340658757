<div class="header" [ngClass]="headerPosition" *ngIf="data.rutaActual.indexOf('compra/envio') === -1">
  <div [ngStyle]="{'background-color': config.stickyHeaderScrolledBackgroundColor}" *ngIf="headerPosition === 'header--fixed'" class="mensaje" [ngClass]="{'mensaje__activo': config.stickyHeaderActivo}">
    <div [ngStyle]="{'background-color': config.stickyHeaderScrolledBackgroundColor}" class="container" [ngClass]="{'mobileColumn' : !config.stickyHeaderMarquee}">
      <div *ngIf="config.stickyHeaderMarquee" class="marquee">
        <p [ngStyle]="{'color': config.stickyHeaderScrolledFontColor}" class="w-100">
          <span>{{config.stickyHeaderTitulo}}</span>
        </p>
      </div>
      <p [ngStyle]="{'color': config.stickyHeaderScrolledFontColor}" *ngIf="!config.stickyHeaderMarquee">{{config.stickyHeaderTitulo}}</p>
        <a [ngStyle]="{'background-color': config.stickyHeaderScrolledButtonBackgroundColor, 'color': config.stickyHeaderScrolledButtonFontColor, 'border-color': config.stickyHeaderScrolledButtonFontColor}" class="actionButton" href={{config.stickyHeaderLink}}>{{config.stickyHeaderCta}}</a>
        <i [ngStyle]="{'color': config.stickyHeaderScrolledCloseColor}" (click)="config.stickyHeaderActivo = false" class="fa fa-times-circle"></i>
    </div>
  </div>
  <div class="container" [ngClass]="SideState">
    <nav class="navbar navbar-fixed justify-content-center py-1 flex-nowrap my-1 my-lg-0" [ngClass]="SearchFocus">
        <div class="d-lg-flex justify-content-center align-items-center align-content-center">
          <div class="menu" [ngClass]="SideStateIcon">
            <i (click)="toggleMenu()" class="fa fa-bars" aria-hidden="true"></i>
          </div>
          <a class="navbar-brand m-0 d-block p-2" [routerLink]="['/']">
            <img width="111" height="34" [ngClass]="'lazyload'" data-src="./assets/images/header/logo.webp" alt="Sina">
            <img width="111" height="34" [ngClass]="'lazyload'" data-src="./assets/images/header/logo.webp" alt="Sina">
          </a>
          <!-- Botón del menú hamburguesa -->
          <button
            *ngIf="_existDesktop"
            mat-button
            class="menuHamburguesa"
            [matMenuTriggerFor]="familias"
            (menuOpened)="menuOpened()"
            (menuClosed)="menuClosed()">
            <i class="fa" [ngClass]="{'fa-bars': !isMenuOpen, 'fa-times': isMenuOpen}" aria-hidden="true"></i> Categorias
          </button>

          <mat-menu #familias="matMenu" yPosition="below" class="menu-principal">
            <button mat-menu-item [matMenuTriggerFor]="categorias" [matMenuTriggerData]="{ links: item.links }" *ngFor="let item of LinkList">
              <a class="text-decoration-none d-block w-100" routerLink="{{'/' + item.texto}}">
                <img [width]="25" [height]="25" [ngClass]="'me-2 lazyload'" [attr.data-src]="item.img2" alt="">
                <span>{{item.texto}}</span>
              </a>
            </button>
          </mat-menu>

          <mat-menu #categorias="matMenu" xPosition="after">
            <ng-template matMenuContent let-links="links">
              <div *ngFor="let cat of links">
                <button type="button" mat-menu-item [matMenuTriggerFor]="subcategorias" [matMenuTriggerData]="{ items: cat.items }" *ngIf="cat.items.length > 0" >
                  <a class="text-decoration-none d-block w-100" routerLink="{{'/' + cat.head.link}}">
                    {{cat.head.texto}}
                  </a>
                </button>

                <button type="button" mat-menu-item *ngIf="cat.items.length == 0">
                  <a class="text-decoration-none d-block w-100" routerLink="{{'/' + cat.head.link}}">
                    {{cat.head.texto}}
                  </a>
                </button>
              </div>
            </ng-template>
          </mat-menu>

          <mat-menu #subcategorias="matMenu" xPosition="after">
            <ng-template matMenuContent let-items="items">
              <button type="button" mat-menu-item *ngFor="let subcat of items">
                <a class="text-decoration-none d-block w-100" routerLink="{{'/' + subcat.link}}">
                  {{subcat.texto}}
                </a>
              </button>
            </ng-template>
          </mat-menu>

          <!-- <mat-menu #testcategorias="matMenu" xPosition="after">
            <button mat-menu-item [matMenuTriggerFor]="subcategorias">Fishes</button>
            <button mat-menu-item [matMenuTriggerFor]="subcategorias">Amphibians</button>
            <button mat-menu-item [matMenuTriggerFor]="subcategorias">Reptiles</button>
            <button mat-menu-item>Categoria 4</button>
            <button mat-menu-item>Categoria 5</button>
            <button mat-menu-item>Categoria 6</button>
            <button mat-menu-item>Categoria 7</button>
            <button mat-menu-item>Categoria 8</button>
            <button mat-menu-item>Categoria 9</button>
            <button mat-menu-item>Categoria 10</button>
            <button mat-menu-item>Categoria 11</button>
            <button mat-menu-item>Categoria 12</button>
          </mat-menu> -->
        </div>
        <div class="buscador__container ps-2 px-md-2">
          <a class="logoMobile" [routerLink]="['/']">
            <img width="111" height="34" [ngClass]="'lazyload'" data-src="./assets/images/header/logo.webp" alt="Sina">
          </a>
          <form class="buscador form-inline mb-2 position-absolute position-lg-relative ps-lg-3 pe-lg-1 me-lg-0">
            <div class="controles">
              <button style="display: none;" class="backButton" (click)="SearchFocus = ''">
                <i class="fa fa-chevron-left" aria-hidden="true"></i>
              </button>
              <!-- removing (keyup)="buscarPalabra($event)" -->
              <input [(ngModel)]="texto_busqueda" (keydown)="enterBusqueda($event)" style="height: 34px; display: inline-block;" class="form-control d-inline-block align-middle" name="texto_busqueda" aria-label="Texto Busqueda" (focus)="focusFunction()" (focusout)="focusoutFunction()" type="text" placeholder="¿En que podemos ayudarte?">
              <button class="btn btn-outline-sucess ms-auto" type="submit" (click)="buscarTexto()">
                  <img width="20" height="20" [ngClass]="'lazyload'" class="align-baseline" data-src="./assets/images/header/buscador.webp" alt="Sina">
              </button>
              <i class="fa fa-search" aria-hidden="true"></i>
              <div class="busqueda" *ngIf="ResultadoBusqueda?.length > 0" style="
                right: -185px;
                width: calc(100vw);
                max-width: 880px;
                font-size: 13px;">
                <ul>
                  <li *ngFor="let item of ResultadoBusqueda" (click)="clickBusqueda(item)">
                    <img width="45" height="30" class="busqueda__icono" style="max-width: 100%; height: auto;" [ngClass]="'lazyload'" [attr.data-src]="item.urlImagen" onerror="this.src='/assets/images/producto/picGenerica.webp'" alt="">
                    <p class="busqueda__titulo">{{item.nombreMostrar || item.titulo}}</p>
                    <p class="busqueda__subtitulo">{{item.tituloAdicional}}</p>
                  </li>
                </ul>
              </div>
            </div>
          </form>
        </div>
        <div #inputCantidadHeaderVerde class="col-6 col-lg-3 col-xl-2 right__form justify-content-evenly" style="max-width: none;">
          <div *ngIf="_existDesktop" class="login" (click)="toggle2('cuenta')">
            <img width="25" height="25" [ngClass]="'lazyload'" data-src="./assets/images/header/user.svg" alt="User">
            <p style="color: #fff" class="fw-bold" *ngIf="UserLog"> {{UserName || "Iniciar sesión"}} <br> {{UserJob || "Registrarse"}} </p>
            <p style="color: #fff" class="fw-bold" *ngIf="!UserLog"> {{"Iniciar sesión"}} <br> {{"Registrarse"}} </p>
            <div class="contPop">
              <div class="popup" *ngIf="popup2.cuenta">
                <div class="popup__lista">
                  <ul class="list-group">
                    <ng-container *ngFor="let item of cuenta">
                      <li (click)="headerCuentaLink(item.id)" *ngIf="!item.admin || (item.admin && representado)" class="list-group-item">
                        {{item.texto}}
                      </li>
                    </ng-container>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="_existMobile" class="logoMobile" style="cursor: pointer; margin-right: 5px;" (click)="toggle2('cuenta')">
            <img width="25" height="25" [ngClass]="'lazyload'" data-src="./assets/images/header/user.svg" alt="User">
            <div class="contPop" style="left: -200%;">
              <div class="popup" *ngIf="popup2.cuenta">
                <div class="popup__lista">
                  <ul class="list-group">
                    <ng-container *ngFor="let item of cuenta">
                      <li (click)="headerCuentaLink(item.id)" *ngIf="!item.admin || (item.admin && representado)" class="list-group-item">
                        {{item.texto}}
                      </li>
                    </ng-container>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="buy" [ngStyle]="{'display': actualRoute.indexOf('compra') !== -1 ? 'none' : 'block'}">
            <div style="cursor: pointer" (click)="toggle2('compra')">
              <span class="badge rounded-pill bg-secondary">{{greetMessage?.length}}</span>
              <img width="25" height="25" [ngClass]="'lazyload'" data-src="./assets/images/iconos/carrito.svg" alt="Carrito">
            </div>
            <div class="contPop">
              <!-- Client only code -->
              <div class="popup" *ngIf="popup2.compra && clientOnlyRun">
                <div class="popup__header">
                  <b>ESTADO DE COMPRA</b>
                  <p>Items en el carrito: {{greetMessage?.length}}</p>
                </div>
                <div style="text-align: center; padding-bottom: 15px;" *ngIf="listFilter(greetMessage)?.length === 0 || !listFilter(greetMessage)">
                  <h1 style="color: #057aff; font-size: 30px;" class="mt-3">Tu carrito está vacío</h1>
                  <h4 style="margin:auto; width: 90%; font-size: 18px;">¡Esperamos que encuentres pronto lo que estas buscando!</h4>
                </div>
                <div class="popup__lista" *ngIf="listFilter(greetMessage)?.length !== 0">
                  <ul class="list-group">
                    <li *ngFor="let item of listaResultados" class="list-group-item justify-content-between">
                    {{item.descripcion}}
                    <p *ngIf="config.switchComprasActivo">${{updatePrecio(item.precio, item.cantidad)}}</p>
                    <input *ngIf="item.cantPack === '1'" name="cantidad" aria-label="Cantidad" type="number" min="1" max="999999" (input)="data.updateMessage()" [(ngModel)]="item.cantidad" (keyup)="revisarCantidad($event)">
                    <select *ngIf="item.cantPack !== '1'" name="cantidad" aria-label="Cantidad" (input)="data.updateMessage()" [(ngModel)]="item.cantidad">
                      <option *ngFor="let cant of item.arrayCants" [value]="cant">{{cant}}</option>
                    </select>
                    <div class="eliminar">
                      <img alt="Eliminar producto" width="18" height="18" class="eliminar--initial" [ngClass]="'lazyload'" data-src="./assets/images/iconos/Eliminar-producto.png" (click)="removeMessage(item); item.comprado = false">
                      <img alt="Eliminar producto" width="18" height="18" class="eliminar--hover" [ngClass]="'lazyload'" data-src="./assets/images/iconos/Eliminar-producto-hover.png" (click)="removeMessage(item); item.comprado = false">
                    </div>
                  </li>
                  </ul>
                </div>
                <div class="control__paginado__carrito" *ngIf="modoVista === 'Paginado'">
                  <button class="boton_carrito" (click)="paginado.anterior()">Anterior</button>
                  <p class="paginado_carrito">Página {{paginado.pagina > 0 && paginado.maximo > 0 ? paginado.pagina : '0'}} de {{paginado.maximo}}</p>
                  <button class="boton_carrito" (click)="paginado.siguiente()">Siguiente</button>
                </div>
                <div class="popup__mas" (click)="carritoVerTodos2()">
                  <div *ngIf="config.switchComprasActivo" class="popup__subtotal">
                    Subtotal: <strong>${{updateSubtotal(greetMessage)}}</strong>
                  </div>
                  <p>Ver todos</p>
                </div>
              </div>
            </div>
          </div>
          <div class="iconoBusqueda" (click)="SearchFocus = 'onSearchFocus'">
              <i style="
                  font-size: 20px;
                  padding-right: 0;
                  color: #fff;
                "class="fa fa-search ps-3" aria-hidden="true"></i>
          </div>
        </div>
    </nav>
  </div>
</div>

<!-- Client only code -->
<div class="modal__container modal__container__header" [ngClass]="{'modalHide': representarHeaderStatus}" *ngIf="!representarHeaderStatus && clientOnlyRun"> <!-- (click)="closeFullRepresentar($event)"> -->
  <div class="loading" [ngClass]="{'loaded': !representarHeaderLoading}">
    <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
  </div>
  <div role="document" class="modal-dialog">
      <div class="modal-content">
      <div class="modal-header">
          <img width="111" height="34" [ngClass]="'lazyload'" data-src="./assets/images/header/logo_azul.webp" alt="Sina" class="show__logo__representar">
          <h4 class="modal-title w-100">Cambiando la cuenta representada actualmente</h4>
          <button aria-label="Close" class="close p-0 bg-transparent border-0 fs-5 fw-bold opacity-25" type="button" (click)= "cerrarRepresentarCuenta()">
            <span style="color: red;" aria-hidden="true">X</span>
          </button>
      </div>
      <div class="modal-body">
          <div class="row">
              <div class="card d-block px-0 rounded-0">
                  <h3 class="card-header">Seleccione alguna de las {{cantCuentasRepresentar}} cuentas que puede representar</h3>
                  <div class="buscador_cuenta">
                    <input type="text" placeholder="Escriba para filtrar por Cód. Cliente, Razón Social o CUIT" [(ngModel)]="filterBusqueda" (keydown)="enterBusquedaCuentas($event)"
                      style="width: 100%;
                        border: none;
                        padding: 5px 10px;"
                      name="filtroBusqueda" aria-label="Filtro por Codigo Cliente, Razon Social o CUIT"
                    >
                  </div>
                  <div class="buscador_btn">
                      <span>
                          <select (change)="cambiarCantFiltro($event)" aria-label="Cantidad de cuentas a mostrar">
                              <option value="20">20</option>
                              <option value="50">50</option>
                              <option value="100">100</option>
                          </select>
                      </span>
                      <button class="btn" type="button" (click)="filtrarCuentas()" aria-label="Buscar cuentas">
                          <i class="fa fa-search" aria-hidden="true"></i>
                      </button>
                  </div>
                  <hr style="margin-top: 5px; margin-bottom: 5px;">
                  <div class="card-block" style="max-height: 200px; overflow-y: scroll; overflow-x: hidden;">
                      <div class="row carrito__header">
                          <div class="col-rep-3">
                              <div class="border-bottom d-flex">
                                  <p class="show__web m-0 p-0 representar_cuentas_table_header">Selección</p>
                                  <p class="show__mobile m-0 p-0 representar_cuentas_table_header"><i class="fa fa-check" aria-hidden="true"></i></p>
                              </div>
                          </div>
                          <div class="col-rep-4">
                              <div class="border-bottom">
                                  <p class="m-0 p-0 representar_cuentas_table_header">CUIT</p>
                              </div>
                          </div>
                          <div class="col-rep-5">
                              <div class="border-bottom">
                              <p class="m-0 p-0 representar_cuentas_table_header">Razón social</p>
                              </div>
                          </div>
                      </div>
                      <div class="row carrito__content py-1" *ngFor="let cuenta of cuentasRepresentar; let $index = index;">
                          <div class="col-rep-3">
                              <div>
                                  <input [value]="cuenta.id" [checked]="$index === 0" type="radio" [(ngModel)]="cuenta.seleccion" name="opcionCuenta" aria-label="Seleccion cuenta" (click)="seleccionarCuenta(cuenta)" style="padding: 0; margin: auto;">
                              </div>
                          </div>
                          <div class="col-rep-4">
                              <p class="m-0 p-0">{{cuenta.cuit}}</p>
                          </div>
                          <div class="col-rep-5">
                              <p class="m-0 p-0">{{cuenta.razon_social}}</p>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
      <p style="font-weight: bold; color: red; margin-left: 35px; margin-top: 20px; margin-bottom: -10px;">{{cuentaRespuesta}}</p>
      <div class="modal-footer carrito d-block">
          <div class="row">
              <div class="col-12 col-md-6 footer__col">
                  <button class="actionButton" type="button" (click)="representarCuenta(cuentaSeleccionada)">REPRESENTAR CUENTA</button>
              </div>
              <div class="col-12 col-md-6 footer__col">
                  <button type="button" class="whiteButton" (click)="cerrarRepresentarCuenta()"> CANCELAR </button>
              </div>
          </div>
      </div>
      </div>
  </div>
</div>
