import { isPlatformBrowser } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AfterViewChecked, ChangeDetectorRef, Component, ElementRef, Inject, OnDestroy, OnInit, Pipe, PipeTransform, PLATFORM_ID, ViewChild } from '@angular/core';
import { NgSelectComponent } from '@ng-select/ng-select';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { fromEvent, Subject, Subscription } from 'rxjs';
import { GoogleAnalyticsService } from './google-analytics.service';
import { FacebookPixelService } from './facebook-pixel.service';
import { cliente, Dato, SharedService } from './shared.service';
import { environment } from 'src/environments/environment';
import { MenuService } from './menu.service';
import { AutenticacionService } from './autenticacion.service';
import { NavigationEnd, Router } from '@angular/router';
import { PromptUpdateService } from './pwa/prompt-update.service';
import { HandleUnrecoverableStateService } from './pwa/handle-unrecoverable-state.service';
import { CheckForUpdateService } from './pwa/check-for-update.service';

export class Link {
  activo!: boolean;
  url!:    string;
  texto!:  string;
  urlImg?: string;
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, AfterViewChecked, OnDestroy {
  private destroy$: Subject<void> = new Subject<void>();
  @ViewChild('ventana') el!: ElementRef;
  @ViewChild('responsable') ngSelectResponsable!: NgSelectComponent;
  @ViewChild('provincia_value') ngSelectProvincia!: NgSelectComponent;
  @ViewChild('provincia_value2') ngSelectProvincia2!: NgSelectComponent;
  @ViewChild('transporte_value') ngSelectTransporte!: NgSelectComponent;
  @ViewChild('input_razon_social') inputRazonSocial!: ElementRef;
  @ViewChild('input_email') inputEmail!: ElementRef;
  @ViewChild('input_check_email') inputCheckEmail!: ElementRef;
  @ViewChild('input_cuit') inputCuit!: ElementRef;
  @ViewChild('input_telefono') inputTelefono!: ElementRef;
  @ViewChild('input_ciudad') inputCiudad!: ElementRef;
  @ViewChild('input_celular') inputCelular!: ElementRef;
  @ViewChild('input_contrasena') inputContrasena!: ElementRef;
  @ViewChild('input_check_contrasena') inputCheckContrasena!: ElementRef;
  @ViewChild('inputCantidad') inputCantidad!: ElementRef;
  inputSub!: Subscription;

  public SocialList: Link[] = [];

  public focusingPassword!: boolean;
  public focusCampoRegistro: boolean = false;

  public recuperarClave: boolean;
  public validationCheckEmail: boolean = false;
  public validationCheckPassword: boolean = false;
  public validationPassword: boolean = false;
  public validationEmail: boolean = false;
  public validationCUIT: boolean = false;
  public validationCUITverificador: boolean = false;
  public validationTelefono: boolean = false;
  public validationCelular: boolean = false;
  public recuperarOk: string;
  public recuperarError: string;
  public usuario = {

  };

  public password_type: string = 'password';

  public mensajesEstados: any = {
    campoNoValido: '',
  };

  public contacto: any = {
    razon_social: '',
    nombre_fantasia: '',
    email: '',
    emailRepetido: '',
    contrasena: '',
    contrasenaRepetida: '',
    domicilio_ciudad: '',
    domicilio_direccion: '',
    domicilio_numero: '',
    domicilio_provincia: '',
    telefono_celular: '',
    telefono: '',
    cuit: '',
    nombre_responsable_compras: '',
    domicilio_codigo_postal: '',
    cod_categoria_iva: '',
    envio_domicilio_direccion: '',
    entrega_domicilio_numero: '',
    envio_domicilio_codigo_postal: '',
    envio_domicilio_ciudad: '',
    envio_domicilio_provincia: '',
    envio_transporte: '',
    actividad: '',
    recepcion_dias: '',
    recepcion_horarios: '',
    contacto_por_telefono: false,
    contacto_por_whatsapp: false,
    contacto_por_mail: false,
  };

  public provincia: any[] = [];
  public transportes: any[] = [];
  public reponsable: string[] = [
    'Consumidor final',
    'Monotributista',
    'Responsable inscripto',
    'Exento',
    'Iva exento operación de exportación',
    'Monotributista social',
  ];

  public migracion = {
    email_original: '',
    email_repetido: '',
    pass_original: '',
    pass_repetido: '',
  };

  public cuit: any = 'CUIT (Solo numeros)*';
  public cativa: string = '';

  cat_selected: any;
  domicilio_provincia = '';
  // envio_provincia = '';
  // envio_transporte = '';

  showScrollPromptUno = true;

  public seleccionar($herramienta: any, $codigo: any) {
    let item = false;
    if ($herramienta) {
      item = $herramienta.itemsList._items.find(($item: any) => $item.value === $codigo);
    }
    // this.data.log('seleccionar items app:', this.cat_selected, this.domicilio_provincia, item);
    if (item) {
      $herramienta.select(item);
      // this.data.log('seleccionar items app:', cat_selected, this.ngSelectResponsable.open(), this.refResponsable)
      // this._ngZone.run(() => {this.cat_selected = cat_selected.codigo});
    }
  }

  public refreshCUIT(value: any): void {
    if (value == 'CF') {
      this.cuit = 'DNI (Solo numeros)*';
    } else {
      this.cuit = 'CUIT (Solo numeros)*';
    }

    if (value) {
      this.data.log('refreshcuit value app:', value);
      const responsable = this.data.reponsable_lista.find(($item: any) => $item.codigo === value);
      this.data.log('refreshcuit responsable app:', value, responsable);
      this.cativa = responsable.text;
      this.cat_selected = responsable.codigo;
    } else {
      this.data.log('refreshcuit no value app:', value);
      delete this.cat_selected;
    }

  }
  public deleteCUIT() {
    this.cat_selected = undefined;
  }
  public refreshProvincia(value: any): void {
    this.data.log('refreshprovincia value app:', value);
    const prov: any = this.provincia.find(($item: any) => $item.id === value);
    if (prov) {
      this.domicilio_provincia = prov.text;
    }
    // this.contacto.domicilio_provincia = value.id;
    this.contacto.domicilio_provincia = value;
  }
  public deleteProvincia() {
    this.contacto.domicilio_provincia = '';
  }
  public refreshProvincia2(value: any): void {
    this.data.log('refreshprovincia2 value app:', value);
    // this.envio_provincia = value.text;
    // this.contacto.envio_domicilio_provincia = value.id;
    this.contacto.envio_domicilio_provincia = value;
  }
  public deleteProvincia2() {
    this.contacto.envio_domicilio_provincia = '';
  }
  public refreshTransporte(value: any): void {
    this.data.log('refreshtransporte value app:', value);
    // this.envio_transporte = value.text;
    // this.contacto.envio_transporte = value.id;
    this.contacto.envio_transporte = value;
  }
  public deleteTransporte() {
    this.contacto.envio_transporte = '';
  }

  processing = {
    started: false,
    right_now: false,
    finished: true,
    start() {
      this.started = true;
      this.right_now = true;
    },
    stop() {
      this.right_now = false;
      this.started = true;
    },
    finish() {
      this.right_now = false;
      this.started = false;
      this.finished = true;
    },
  };

  // sticky: any = {
  //   activo: false,
  //   call_to_action: '',
  //   link: '#',
  //   mensaje: '',
  //   inicio: '',
  //   fin: '',
  //   permanente: false
  // };
  emergentes = [];
  actualRoute = '/';
  // actualEmergente: any = {};
  actualEmergenteFlag: boolean = false;

  message!: string;
  childmessage: Dato[] = [];
  menuStatus!: number;
  currUser = {};
  hasCompras: boolean = false;
  isLoggedIn: boolean = false;
  loginStatus: boolean = true;
  carritoStatus!: boolean;
  representarStatus!: boolean;
  config: any;
  registrarStatus: boolean = false;
  login: any = {
    user: '',
    pass: '',
    error: false,
    errorMsg: '',
    confirMsg: '',
  };
  ventana: any;
  eventoclick($event: any) {
    if ($event.path) {
      if (!$event.path.some(($element: any) => ($element.className === 'buy' || $element.className === 'login ng-star-inserted'))) {
        this.menu.notifyOther(true);
      }
    } else {
      if (!('path' in Event.prototype)) {
        Object.defineProperty(Event.prototype, 'path', {
          get() {
            const path = [];
            let currentElem: any = this.target;
            while (currentElem) {
              path.push(currentElem);
              currentElem = currentElem.parentElement;
            }
            if (path.indexOf(window) === -1 && path.indexOf(document) === -1) {
              path.push(document);
            }
            if (path.indexOf(window) === -1) {
              path.push(window);
            }
            return path;
          },
        });
      }
      if (!$event.path.some(($element: any) => ($element.className === 'buy' || $element.className === 'login ng-star-inserted'))) {
        this.menu.notifyOther(true);
      }
    }
  }
  _MODES: any[] = ['over', 'push', 'slide'];
  _opened: boolean = false;
  _option: number = 2;
  _existDesktop: boolean = false;
  _existMobile: boolean = false;

  confirmacion: any = {
    error: false,
    mensaje: 'Se ha enviado un correo al mail provisto para su confirmación, por favor siga los pasos del mismo.',
    action: 'ACEPTAR',
    value: () => {
      this.processing.finish(); this.registrarStatus = false; this.statusAlta = false;
    },
  };
  error: any = {
    error: true,
    mensaje: '',
    action: 'VOLVER ATRÁS',
    value: () => {
      this.processing.finish(); this.statusAlta = false;
      setTimeout(() => {
        this.seleccionar(this.ngSelectResponsable, this.cat_selected);
        this.seleccionar(this.ngSelectProvincia, this.contacto.domicilio_provincia);
      }, 500);
      setTimeout(() => {
        if (this.contacto.envio_domicilio_provincia) {
          this.seleccionar(this.ngSelectProvincia2, this.contacto.envio_domicilio_provincia);
        }
        if (this.contacto.envio_transporte) {
          this.seleccionar(this.ngSelectTransporte, this.contacto.envio_transporte);
        }
      }, 500);
    },
    reset: () => {
      this.error.mensaje = '';
    },
  };
  statusAlta: boolean = false;
  response: any;
  validador: any = {};
  obligatorios = ['razon_social', 'domicilio_ciudad', 'email', 'cuit', 'telefono', 'contrasena'];
  no_obligatorios = ['nombre_fantasia'];
  confirmarAlta(): any {
    // this.data.log('confirmarAlta app');

    // Esto es lo que antes era el "step 1"
    if (this.contacto.contrasena !== this.contacto.contrasenaRepetida) {
      this.validationCheckPassword = true;
      this.validador['checkcontrasena'] = true;
      this.inputCheckContrasena?.nativeElement.focus();
    } else {
      this.validationCheckPassword = false;
      delete this.validador['checkcontrasena'];
    }

    if (this.contacto.email !== this.contacto.emailRepetido) {
      this.validationCheckEmail = true;
      this.validador['checkemail'] = true;
      this.inputCheckEmail?.nativeElement.focus();
    } else {
      this.validationCheckEmail = false;
      delete this.validador['checkemail'];
    }

    // this.data.log('confirmarAlta validador app', this.validador)
    // Aca paso por cada campo obligatorio
    this.obligatorios.forEach(($campo_obligatorio) => {
      if (!this.contacto[$campo_obligatorio]) {
        this.validador[$campo_obligatorio] = true;
        if ($campo_obligatorio === 'contrasena') {
          this.inputContrasena?.nativeElement.focus();
        }
        if ($campo_obligatorio === 'domicilio_ciudad') {
          this.inputCiudad?.nativeElement.focus();
        }
        if ($campo_obligatorio === 'telefono') {
          this.inputTelefono?.nativeElement.focus();
        }
        if ($campo_obligatorio === 'cuit') {
          this.inputCuit?.nativeElement.focus();
        }
        if ($campo_obligatorio === 'email') {
          this.inputEmail?.nativeElement.focus();
        }
        if ($campo_obligatorio === 'razon_social') {
          this.inputRazonSocial?.nativeElement.focus();
        }
      } else {
        delete this.validador[$campo_obligatorio];
      }

      // Chequeo que el cuit contenga solo números, sin puntos ni guiones
      if ($campo_obligatorio === 'cuit' && this.contacto[$campo_obligatorio]) {
        let cuitRegExp = new RegExp(/^\d{11}$/); // numeros del 0 al 9, obligado a 11 caracteres (CUIT)
        if (this.cuit === 'DNI (Solo numeros)*') {
          cuitRegExp = new RegExp(/^\d{8}$/); // numeros del 0 al 9, obligado a 8 caracteres (DNI)
        }
        const regExpValidation = cuitRegExp.test(this.contacto[$campo_obligatorio]);
        const digitoVerificadorValidation = this.checkCUIT(this.contacto[$campo_obligatorio]);
        if (regExpValidation && digitoVerificadorValidation) {
          delete this.validador[$campo_obligatorio];
          this.validationCUIT = false;
          this.validationCUITverificador = false;
        } else {
          this.validationCUIT = false;
          this.validationCUITverificador = false;
          if (!regExpValidation) {
            this.validador[$campo_obligatorio] = true;
            this.validationCUIT = true;
            this.inputCuit?.nativeElement.focus();
          }
          if (!digitoVerificadorValidation) {
            this.validador[$campo_obligatorio] = true;
            this.validationCUITverificador = true;
            this.inputCuit?.nativeElement.focus();
          }
        }
      }

      // Chequeo el telefono
      if ($campo_obligatorio === 'telefono' && this.contacto[$campo_obligatorio]) {
        const telefonoRegExp = new RegExp(/^[^A-z-][\d-]+[^A-z-]$/); // numeros del 0 al 9 y guiones entre ellos (no al principio ni al final)
        if (telefonoRegExp.test(this.contacto[$campo_obligatorio])) {
          delete this.validador[$campo_obligatorio];
          this.validationTelefono = false;
        } else {
          this.validador[$campo_obligatorio] = true;
          this.validationTelefono = true;
          this.inputTelefono?.nativeElement.focus();
        }
      }

      // Chequeo que el email contenga los caracteres necesarios
      if ($campo_obligatorio === 'email'  && this.contacto[$campo_obligatorio]) {
        const emailRegExp = new RegExp(/^[^\.][^\s@#!]+@[^\s@.#!]+\.[^\s@.]+\.*[^\s@.]+$/); // expresion simple como algo + @ + algo + . + algo (OPCIONAL: + . + algo)
        if (emailRegExp.test(this.contacto[$campo_obligatorio])) {
          delete this.validador[$campo_obligatorio];
          this.validationEmail = false;
        } else {
          this.validador[$campo_obligatorio] = true;
          this.validationEmail = true;
          this.inputEmail?.nativeElement.focus();
        }
      }

      // Chequeo la contraseña
      if ($campo_obligatorio === 'contrasena' && this.contacto[$campo_obligatorio]) {
        const passwordRegExp = new RegExp(/.{6,}/); // 6 caracteres o mas
        if (passwordRegExp.test(this.contacto[$campo_obligatorio])) {
          delete this.validador[$campo_obligatorio];
          this.validationPassword = false;
        } else {
          this.validador[$campo_obligatorio] = true;
          this.validationPassword = true;
          this.inputContrasena?.nativeElement.focus();
        }
      }

    });

    // Chequeo el celular si es que tiene algo escrito porque no es obligatorio
    if (this.contacto['telefono_celular']) {
      const celularRegExp = new RegExp(/^[^A-z-][\d-]+[^A-z-]$/); // numeros del 0 al 9 y guiones entre ellos (no al principio ni al final)
      if (celularRegExp.test(this.contacto['telefono_celular'])) {
        delete this.validador['telefono_celular'];
        this.validationCelular = false;
      } else {
        this.validador['telefono_celular'] = true;
        this.validationCelular = true;
        this.inputCelular?.nativeElement.focus();
      }
    } else {
      delete this.validador['telefono_celular'];
      this.validationCelular = false;
    }

    if (this.cat_selected && Object.keys(this.cat_selected).length !== 0) {
      delete this.validador['cat_selected'];
    } else {
      this.validador['cat_selected'] = true;
      this.ngSelectResponsable.element.querySelector('input')?.focus();
    }
    if (this.contacto.domicilio_provincia && Object.keys(this.contacto.domicilio_provincia).length !== 0) {
      delete this.validador['domicilio_provincia'];
    } else {
      this.validador['domicilio_provincia'] = true;
      this.ngSelectProvincia.element.querySelector('input')?.focus();
    }
    if (Object.keys(this.validador).length === 0 && this.validador.constructor === Object) {
      const Cat = this.data.reponsable_lista.find((element: any) => element.text === this.cativa);
      this.contacto['cod_categoria_iva'] = Cat ? Cat.codigo : '';

      // Lo guardo en la tabla de altas incompletas
      const bodyAltaIncompleta = new URLSearchParams();
      bodyAltaIncompleta.set('razon_social', this.contacto['razon_social']);
      bodyAltaIncompleta.set('nombre_fantasia', this.contacto['nombre_fantasia']);
      bodyAltaIncompleta.set('email', this.contacto['email']);
      bodyAltaIncompleta.set('cod_categoria_iva', this.contacto['cod_categoria_iva']);
      bodyAltaIncompleta.set('cuit', this.contacto['cuit']);
      bodyAltaIncompleta.set('telefono', this.contacto['telefono']);
      bodyAltaIncompleta.set('telefono_celular', this.contacto['telefono_celular']);
      bodyAltaIncompleta.set('domicilio_direccion', this.contacto['domicilio_direccion']);
      bodyAltaIncompleta.set('domicilio_ciudad', this.contacto['domicilio_ciudad']);
      bodyAltaIncompleta.set('domicilio_provincia', this.contacto['domicilio_provincia']);
      bodyAltaIncompleta.set('domicilio_codigo_postal', this.contacto['domicilio_codigo_postal']);

      // TODO: Checkear esto - por ahora lo comento (no debería estar acá seguramente)
      /* this.auth.post(environment.APP_API_BASE + 'public/cliente/alta_incompleta', bodyAltaIncompleta)
      .then(($response: any) => {
        this.data.log('response persistir alta_incompleta', $response);
      })
      .catch(($error: any) => {
        this.data.log('error persistir alta_incompleta', $error);
      }); */

      if (this.contacto['razon_social'] && this.contacto['email']) {

        if (environment.production && environment.APP_MAILING_SUBSCRIBE) {
          // Lo suscribo al newsletter
          const body = new URLSearchParams();
          body.set('api_key', 'RCUuYdks5u0kwkTgCVlw ');
          body.set('name', this.contacto['razon_social']);
          body.set('email', this.contacto['email']);
          body.set('list', 'cqW4SwUCeaOE36rhy8922C3A');
          body.set('country', 'AR');
          body.set('referrer', environment.APP_WEB_BASE);
          body.set('boolean', 'true');

          this.auth.post(environment.APP_MAILING_BASE + 'subscribe', body)
          .then(($response: any) => {
            this.data.log('response suscribir registro', $response);
          })
          .catch(($error: any) => {
            this.data.log('error suscribir registro', $error);
          });
        }
      }

      // Continuo con el registro normalmente
      setTimeout(() => {
        this.seleccionar(this.ngSelectResponsable, this.cat_selected);
        this.seleccionar(this.ngSelectProvincia, this.contacto.domicilio_provincia);
      }, 500);
      setTimeout(() => {
        if (this.contacto.envio_domicilio_provincia) {
          this.seleccionar(this.ngSelectProvincia2, this.contacto.envio_domicilio_provincia);
        }
        if (this.contacto.envio_transporte) {
          this.seleccionar(this.ngSelectTransporte, this.contacto.envio_transporte);
        }
      }, 500);
    }

    // Esto es lo que antes era el "step 3"
    if (this.processing.finished) {
      const Cat = this.data.reponsable_lista.find((element: any) => element.text === this.cativa);
      this.contacto['cod_categoria_iva'] = Cat ? Cat.codigo : '';
      try {
        this.no_obligatorios.forEach(($item) => {
          if (this.contacto[$item] === '') {
            delete this.contacto[$item];
          }
        });
      } catch ($catch) { }
      let validando = false;
      for (const key in this.contacto) {
        if (this.contacto.hasOwnProperty(key)) {
          const element = this.contacto[key];
          if (this.obligatorios.includes(key) && element === '') {
            validando = true;
            return 0;
          }
        }
      }
      if (!validando) {
        this.processing.start();
        const body = new URLSearchParams();

        Object.keys(this.contacto).forEach((element) => {
          if (element.includes('email') || element.includes('contrasena')) {
            body.set(element, this.contacto[element]);
          } else {
            if (!element.includes('domicilio_direccion')) {
              body.set(element, this.contacto[element].toUpperCase ? this.contacto[element].toUpperCase() : this.contacto[element]);
            } else {
              if (element.includes('envio_domicilio_direccion')) {
                body.set(element, this.contacto.envio_domicilio_direccion.toUpperCase ? (this.contacto.envio_domicilio_direccion.toUpperCase() + (this.contacto.entrega_domicilio_numero ? ' ' + this.contacto.entrega_domicilio_numero : '')) : (this.contacto.envio_domicilio_direccion + (this.contacto.entrega_domicilio_numero ? ' ' + this.contacto.entrega_domicilio_numero : '')));
              } else {
                body.set(element, this.contacto.domicilio_direccion.toUpperCase ? (this.contacto.domicilio_direccion.toUpperCase() + (this.contacto.domicilio_numero ? ' ' + this.contacto.domicilio_numero : '')) : (this.contacto.domicilio_direccion + (this.contacto.domicilio_numero ? ' ' + this.contacto.domicilio_numero : '')));
              }
            }
          }
        });
        const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });

        /*
        this.data.log('confirmarAlta body.toString() app', body.toString())
        this.processing.stop()

        this.response = this.confirmacion

        this.error.reset()
        this.response = this.error
        this.response.mensaje = "prueba"
        */

        this.http.post(this.auth.getPath('public/cliente/nuevo'), body.toString(), { headers, observe: 'response' })
          .pipe(takeUntil(this.destroy$)).subscribe({
            next: ($response) => {
              this.processing.stop();
              if (environment.production) {
                this.googleAnalyticsService.registroExitoso();
                this.facebookPixelService.registroExitoso();
              }
              this.response = this.confirmacion;
              if (this.config.mensajeModalRegistroActivo) {
                this.response.mensaje = this.config.mensajeModalRegistroMensaje;
              }

              this.statusAlta = true;
              this.showScrollPromptUno = false;
            },
            error: ($error) => {
              this.processing.stop();
              this.error.reset();
              this.response = this.error;
              try {
                Object.keys($error.error.response.error).forEach((element) => {
                  this.response.mensaje += $error.error.response.error[element] + '\n';
                });
              } catch ($throw) {
                this.data.log('postnuevocliente error app', $throw);
              }
            }
          });
      } else {
        this.data.log('no validado error app', this.contacto)
      }
    }
  }

  public checkCUIT(cuit: string) {
    let flagCheckeadoCUIT = true;
    if (cuit) {
      const cuitSoloNumeros = cuit.replaceAll(/\.|\-/ig, ''); // Elimino puntos y guiones
      if (cuitSoloNumeros.length === 11) {
        // Es un CUIT
        const digitoVerificadorCUIT = parseInt(cuitSoloNumeros.substring(cuitSoloNumeros.length-1), 10); // Ultimo digito

        let comprobacion = this.calcularDigitoVerificador(cuitSoloNumeros);

        if (digitoVerificadorCUIT !== comprobacion) {
          flagCheckeadoCUIT = false;
        }
      }
    }

    return flagCheckeadoCUIT;
  }

  public calcularDigitoVerificador(cuitSoloNumeros: string) {
    let cuitSinUltimoDigito = cuitSoloNumeros.substring(0, cuitSoloNumeros.length-1);
    const multiplos = [2, 3, 4, 5, 6, 7, 2, 3, 4, 5]; // Hasta 10 digitos entre tipo y dni
    let sumaP = 0;
    let sumaModOnce = 0;
    let onceMenos = 0;
    for (let i = 0; i < cuitSinUltimoDigito.length; i++) {
      const digitoCUIT = parseInt(cuitSinUltimoDigito[(cuitSinUltimoDigito.length - 1) - i], 10);
      sumaP = sumaP + (digitoCUIT * multiplos[i]);
    }
    sumaModOnce = sumaP % 11;

    if (sumaModOnce === 0) {
      onceMenos = 0;
    }
    else if (sumaModOnce === 1) {
      onceMenos = 9;
    }
    else {
      onceMenos = 11 - sumaModOnce;
    }

    return onceMenos; // Este es el digito verificador
  }

  public _toggleRegistrarStatus() {
    this.registrarStatus = !this.registrarStatus;

    setTimeout(() => {
      this.seleccionar(this.ngSelectResponsable, this.cat_selected);
      this.seleccionar(this.ngSelectProvincia, this.contacto.domicilio_provincia);
    }, 500);
    setTimeout(() => {
      if (this.contacto.envio_domicilio_provincia) {
        this.seleccionar(this.ngSelectProvincia2, this.contacto.envio_domicilio_provincia);
      }
      if (this.contacto.envio_transporte) {
        this.seleccionar(this.ngSelectTransporte, this.contacto.envio_transporte);
      }
    }, 500);
  }
  clientOnlyRun: boolean = false;
  serverOnlyRun: boolean = false;

  // private _toggleSidebar() {
  //   this._opened = !this._opened;
  // }
  constructor(
    // private _ngZone: NgZone,
    private menu: MenuService,
    private cdRef: ChangeDetectorRef,
    public data: SharedService,
    private http: HttpClient,
    public auth: AutenticacionService,
    private router: Router,
    private googleAnalyticsService: GoogleAnalyticsService,
    private facebookPixelService: FacebookPixelService,
    @Inject(PLATFORM_ID) private platformId: Object,
    private checkForUpdateService: CheckForUpdateService,
    private promptUpdateService: PromptUpdateService,
    private handleUnrecoverableStateService: HandleUnrecoverableStateService,
  ) {
    if (typeof window !== typeof undefined) {
      if (window.innerWidth <= 992) {
        this._existDesktop = false;
        this._existMobile = true;
      } else {
        this._existDesktop = true;
        this._existMobile = false;
      }
    } else {
      this._existDesktop = true;
      this._existMobile = true;
    }

    if (isPlatformBrowser(this.platformId)) {
      this.clientOnlyRun = true;
    } else {
      this.serverOnlyRun = true;
    }

    this.recuperarClave = false;
    this.recuperarOk = '';
    this.recuperarError = '';

    this.auth.get('public/cliente/envio/getAll').then((result: any) => {
      if (result.responseT) {
        this.transportes = [];
        result.responseT.forEach((transporte: any) => {
          this.transportes.push({
            id: transporte.codigo,
            text: transporte.nombre,
          });
        });
      }
      if (result.responseP) {
        this.provincia = [];
        result.responseP.forEach((provincia: any) => {
          this.provincia.push({
            id: provincia.codigo,
            text: provincia.nombre,
          });
        });
      }
    }).catch((error: any) => this.data.log('public/cliente/envio/getAll error app:', error));
  }
  ngAfterViewChecked() {
    this.cdRef.detectChanges();
  }

  ngOnInit() {
    // subscribing to data on carrito
    this.data.currentMessage.pipe(takeUntil(this.destroy$)).subscribe(
      (message) => {
        this.childmessage = message;
      },
    );
    // subscribing to data on sidebar
    this.data.currentSide.pipe(takeUntil(this.destroy$)).subscribe(
      (status) => {
        this._opened = status;
      },
    );
    // subscribing to data on currUser
    this.data.currentUser.pipe(takeUntil(this.destroy$)).subscribe(
      (user: any) => {
        this.currUser = user;
        this.hasCompras = user.fechaUltimaCompra ? true : false;
      },
    );
    // subscribing to data on isLoggedIn
    this.data.currentLogin.pipe(takeUntil(this.destroy$)).subscribe(
      (status) => {
        this.isLoggedIn = status;
      },
    );
    // subscribing to data on loginStatus
    this.data.currentModal.pipe(takeUntil(this.destroy$)).subscribe(
      (status) => {
        this.loginStatus = status;
        if (status && this.config) {
          this.find_index();
        }
      },
    );
    // subscribing to data on CarritoStatus
    this.data.currentModal2.pipe(takeUntil(this.destroy$)).subscribe(
      (status) => {
        this.carritoStatus = status;
        if (this.config) {
          this.find_index();
        }
      },
    );
    // subscribing to data on representarStatus
    this.data.currentRepresentar.pipe(takeUntil(this.destroy$)).subscribe(
      (status) => {
        this.representarStatus = status;
      },
    );
    // subscribing to router change
    this.router.events.pipe(takeUntil(this.destroy$)).subscribe((val: any) => {
      if (val['url']) {
        if (this.actualRoute !== val['url']) {
          this.actualRoute = (val['url']);
          this.data.rutaActual = (val['url']);
          if (this.config) {
            this.find_index();
          }
        }
      }
      if (!(val instanceof NavigationEnd)) {
        return;
      } else {
        if (val && val.url && val.url === '/registrate') {
          setTimeout(() => {
            this._toggleRegistrarStatus();
          }, 600);
        }
        if (!this.loginStatus && val && val.url && val.url == '/sumate') {
          this.data.toggleLoginModal();
        }
      }
      if (this.el && this.el.nativeElement && this.el.nativeElement.parentElement) this.el.nativeElement.parentElement.scrollTop = 0;
    });

    // subscribing to config change
    this.data.currentConfig.pipe(takeUntil(this.destroy$)).subscribe(
      (configuracion) => {
        this.config = configuracion;
        this.find_index();

        this.SocialList[0] = {
          activo: this.config.stickySocialTelActivo, texto: this.config.stickySocialTelTexto,
          url: this.config.stickySocialTelUrl, urlImg: '/assets/images/header/phone.webp',
        };
        this.SocialList[1] = {
          activo: this.config.stickySocialWhatsappActivo, texto: this.config.stickySocialWhatsappTexto,
          url: this.config.stickySocialWhatsappUrl, urlImg: '/assets/images/header/whatsapp.webp',
        };
        this.SocialList[2] = {
          activo: this.config.stickySocialFacebookActivo, texto: this.config.stickySocialFacebookTexto,
          url: this.config.stickySocialFacebookUrl, urlImg: '/assets/images/iconos/facebook.png',
        };
        this.SocialList[3] = {
          activo: this.config.stickySocialInstagramActivo, texto: this.config.stickySocialInstagramTexto,
          url: this.config.stickySocialInstagramUrl, urlImg: '/assets/images/iconos/instagram.png',
        };
        this.SocialList[4] = {
          activo: this.config.stickySocialTwitterActivo, texto: this.config.stickySocialTwitterTexto,
          url: this.config.stickySocialTwitterUrl, urlImg: '/assets/images/iconos/twitter.png',
        };
        this.SocialList[5] = {
          activo: this.config.stickySocialYoutubeActivo, texto: this.config.stickySocialYoutubeTexto,
          url: this.config.stickySocialYoutubeUrl, urlImg: '/assets/images/iconos/youtube.png',
        };
        this.SocialList[6] = {
          activo: this.config.stickySocialTikTokActivo, texto: this.config.stickySocialTikTokTexto,
          url: this.config.stickySocialTikTokUrl, urlImg: '/assets/images/iconos/tiktok.png',
        };
      },
    );

    // reading config data
    this.auth.get('public/configuracion')
    .then(($response: any) => {
      this.data.log('response publicconfiguracion app', $response);
      if ($response.response) {
        const ahora = new Date();
        const fechaDesde = $response.response.stickyHeaderDesde ? new Date($response.response.stickyHeaderDesde.date) : ahora;
        const fechaHasta = $response.response.stickyHeaderHasta ? new Date($response.response.stickyHeaderHasta.date) : ahora;
        const permanente = $response.response.stickyHeaderPermanente === '1' ? true : false;
        const marquee = $response.response.stickyHeaderMarquee === '1' ? true : false;
        const c = {
          montoMinimo: Number.parseInt($response.response.montoMinimo, 10),
          montoEnvio: Number.parseInt($response.response.montoEnvio, 10),
          montoEnvioGratis: Number.parseInt($response.response.montoEnvioGratis, 10),
          costoEnvio: Number.parseInt($response.response.costoEnvio, 10),
          stickyHeaderTitulo: $response.response.stickyHeaderTitulo,
          stickyHeaderCta: $response.response.stickyHeaderCta,
          stickyHeaderLink: $response.response.stickyHeaderLink,
          stickyHeaderFontColor: $response.response.stickyHeaderFontColor,
          stickyHeaderBackgroundColor: $response.response.stickyHeaderBackgroundColor,
          stickyHeaderScrolledFontColor: $response.response.stickyHeaderScrolledFontColor,
          stickyHeaderScrolledBackgroundColor: $response.response.stickyHeaderScrolledBackgroundColor,
          stickyHeaderButtonFontColor: $response.response.stickyHeaderButtonFontColor,
          stickyHeaderButtonBackgroundColor: $response.response.stickyHeaderButtonBackgroundColor,
          stickyHeaderScrolledButtonFontColor: $response.response.stickyHeaderScrolledButtonFontColor,
          stickyHeaderScrolledButtonBackgroundColor: $response.response.stickyHeaderScrolledButtonBackgroundColor,
          stickyHeaderCloseColor: $response.response.stickyHeaderCloseColor,
          stickyHeaderScrolledCloseColor: $response.response.stickyHeaderScrolledCloseColor,
          stickyHeaderDesde: fechaDesde,
          stickyHeaderHasta: fechaHasta,
          stickyHeaderActivo: permanente ? ($response.response.stickyHeaderActivo === '1' ? true : false) :
            ($response.response.stickyHeaderActivo === '1'  && ahora > fechaDesde && ahora < fechaHasta ? true : false),
          stickyHeaderPermanente: permanente,
          stickyHeaderMarquee: marquee,
          ventanaEmergenteTitulo: $response.response.ventanaEmergenteTitulo,
          ventanaEmergenteImagen: $response.response.ventanaEmergenteImagen,
          ventanaEmergenteActivo: $response.response.ventanaEmergenteActivo === '1' ? true : false,
          ventanaEmergenteTituloLogged: $response.response.ventanaEmergenteTituloLogged,
          ventanaEmergenteImagenLogged: $response.response.ventanaEmergenteImagenLogged,
          ventanaEmergenteActivoLogged: $response.response.ventanaEmergenteActivoLogged === '1' ? true : false,
          ventanaEmergenteTituloSinCompra: $response.response.ventanaEmergenteTituloSinCompra,
          ventanaEmergenteImagenSinCompra: $response.response.ventanaEmergenteImagenSinCompra,
          ventanaEmergenteActivoSinCompra: $response.response.ventanaEmergenteActivoSinCompra === '1' ? true : false,
          carouselHomeInterval: $response.response.carouselHomeInterval,
          carouselCompraInterval: $response.response.carouselCompraInterval,
          bannerUnoActivo: $response.response.bannerUnoActivo === '1' ? true : false,
          bannerUnoTitulo: $response.response.bannerUnoTitulo,
          bannerUnoLink: $response.response.bannerUnoLink,
          bannerUnoImagen: $response.response.bannerUnoImagen,
          bannerDosActivo: $response.response.bannerDosActivo === '1' ? true : false,
          bannerDosTitulo: $response.response.bannerDosTitulo,
          bannerDosLink: $response.response.bannerDosLink,
          bannerDosImagen: $response.response.bannerDosImagen,
          bannerTresActivo: $response.response.bannerTresActivo === '1' ? true : false,
          bannerTresTitulo: $response.response.bannerTresTitulo,
          bannerTresLink: $response.response.bannerTresLink,
          bannerTresImagen: $response.response.bannerTresImagen,
          bannerCuatroActivo: $response.response.bannerCuatroActivo === '1' ? true : false,
          bannerCuatroTitulo: $response.response.bannerCuatroTitulo,
          bannerCuatroLink: $response.response.bannerCuatroLink,
          bannerCuatroImagen: $response.response.bannerCuatroImagen,
          bannerCincoActivo: $response.response.bannerCincoActivo === '1' ? true : false,
          bannerCincoTitulo: $response.response.bannerCincoTitulo,
          bannerCincoLink: $response.response.bannerCincoLink,
          bannerCincoImagen: $response.response.bannerCincoImagen,
          bannerSeisActivo: $response.response.bannerSeisActivo === '1' ? true : false,
          bannerSeisTitulo: $response.response.bannerSeisTitulo,
          bannerSeisLink: $response.response.bannerSeisLink,
          bannerSeisImagen: $response.response.bannerSeisImagen,
          bannerSinaDesktopImagen: $response.response.bannerSinaDesktopImagen,
          bannerSinaDesktopTitulo: $response.response.bannerSinaDesktopTitulo,
          bannerSinaMobileImagen: $response.response.bannerSinaMobileImagen,
          bannerSinaMobileTitulo: $response.response.bannerSinaMobileTitulo,
          bannerOfertasTitulo: $response.response.bannerOfertasTitulo,
          bannerOfertasImagen: $response.response.bannerOfertasImagen,
          bannerOfertasSemanalesTitulo: $response.response.bannerOfertasSemanalesTitulo,
          bannerOfertasSemanalesImagen: $response.response.bannerOfertasSemanalesImagen,
          bannerNovedadesTitulo: $response.response.bannerNovedadesTitulo,
          bannerNovedadesImagen: $response.response.bannerNovedadesImagen,
          bannerCampaniasTitulo: $response.response.bannerCampaniasTitulo,
          bannerCampaniasImagen: $response.response.bannerCampaniasImagen,
          bannerReciclablesTitulo: $response.response.bannerReciclablesTitulo,
          bannerReciclablesImagen: $response.response.bannerReciclablesImagen,
          stickySocialTelActivo: $response.response.stickySocialTelActivo === '1' ? true : false,
          stickySocialTelTexto: $response.response.stickySocialTelTexto,
          stickySocialTelUrl: $response.response.stickySocialTelUrl,
          stickySocialWhatsappActivo: $response.response.stickySocialWhatsappActivo === '1' ? true : false,
          stickySocialWhatsappTexto: $response.response.stickySocialWhatsappTexto,
          stickySocialWhatsappUrl: $response.response.stickySocialWhatsappUrl,
          stickySocialFacebookActivo: $response.response.stickySocialFacebookActivo === '1' ? true : false,
          stickySocialFacebookTexto: $response.response.stickySocialFacebookTexto,
          stickySocialFacebookUrl: $response.response.stickySocialFacebookUrl,
          stickySocialInstagramActivo: $response.response.stickySocialInstagramActivo === '1' ? true : false,
          stickySocialInstagramTexto: $response.response.stickySocialInstagramTexto,
          stickySocialInstagramUrl: $response.response.stickySocialInstagramUrl,
          stickySocialTwitterActivo: $response.response.stickySocialTwitterActivo === '1' ? true : false,
          stickySocialTwitterTexto: $response.response.stickySocialTwitterTexto,
          stickySocialTwitterUrl: $response.response.stickySocialTwitterUrl,
          stickySocialYoutubeActivo: $response.response.stickySocialYoutubeActivo === '1' ? true : false,
          stickySocialYoutubeTexto: $response.response.stickySocialYoutubeTexto,
          stickySocialYoutubeUrl: $response.response.stickySocialYoutubeUrl,
          stickySocialTikTokActivo: $response.response.stickySocialTikTokActivo === '1' ? true : false,
          stickySocialTikTokTexto: $response.response.stickySocialTikTokTexto,
          stickySocialTikTokUrl: $response.response.stickySocialTikTokUrl,
          mensajeCompraActivo: $response.response.mensajeCompraActivo === '1' ? true : false,
          mensajeCompraTitulo: $response.response.mensajeCompraTitulo,
          mensajeCompraMensaje: $response.response.mensajeCompraMensaje,
          switchAltasActivo: $response.response.switchAltasActivo === '1' ? true : false,
          switchComprasActivo: $response.response.switchComprasActivo === '1' ? true : false,
          switchContactosActivo: $response.response.switchContactosActivo === '1' ? true : false,
          mensajeModalLoginActivo: $response.response.mensajeModalLoginActivo === '1' ? true : false,
          mensajeModalLoginMensaje: $response.response.mensajeModalLoginMensaje,
          mensajeModalRegistroActivo: $response.response.mensajeModalRegistroActivo === '1' ? true : false,
          mensajeModalRegistroMensaje: $response.response.mensajeModalRegistroMensaje,
          idProductoRecomendado: Number.parseInt($response.response.idProductoRecomendado, 10),
        };
        this.data.updateConfiguracion(c);
      }
    })
    .catch(($error: any) => {
      this.data.log('problemas con la configuracion app');
      this.data.log('getconfiguracion error app', $error);
    });

    // Client only code.
    if (this.clientOnlyRun) {
      // binding interval
      setInterval(() => {
        this.menuStatus = this.el?.nativeElement.parentElement.scrollTop;
      }, 400);

      // reading user data
      if (this.auth.localGet('login')) {
        this.auth.get('cliente/datos')
        .then(($response: any) => {
          const datos_locales = this.auth.localGet('user');
          if ($response.response['codigo'] !== datos_locales['codigo']) {
            window.location.href = '/'; // podría caer en un loop infinito aca? no hay ningun localset que lo pare?
          } else {
            // this.data.log('getclientedatos error codigouser app', datos_locales, $response.response)
          }
          this.auth.localSet('user', $response.response as cliente);
          this.data.updateUser($response.response);
        })
        .catch(($error: any) => {
          this.data.log('problemas con el login/token app', this.auth.localGet('login'));
          this.data.log('getdatoscliente error app', $error);
        });
      }
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  find_index() {
    const item = this.actualRoute === '/' ? 'home' : this.actualRoute;
    if (item) {
      // const index = this.emergentes.findIndex(($element) => {
      //   return item.indexOf($element.seccion) !== -1;
      // });
      if (this.actualRoute.indexOf('confirmacion') === -1 && this.actualRoute.indexOf('sumate') === -1) {
        // if (index !== -1) {
          if (this.carritoStatus && this.loginStatus) {
            if (this.config.ventanaEmergenteActivo || this.config.ventanaEmergenteActivoLogged || this.config.ventanaEmergenteActivoSinCompra) {
              if (!this.auth.localGet('actualEmergenteFlag')) {
                // this.actualEmergente = this.emergentes[index];
                this.actualEmergenteFlag = true;
                this.auth.localSet('actualEmergenteFlag', true);
              }
            }
          } else {
            this.actualEmergenteFlag = false;
            this.auth.localSet('actualEmergenteFlag', false);

            if (!this.carritoStatus) {
              setTimeout(() => {
                if (this.inputCantidad && this.inputCantidad?.nativeElement) {
                  this.inputSub = fromEvent(this.inputCantidad?.nativeElement, 'input')
                  .pipe(debounceTime(300), takeUntil(this.destroy$)).subscribe(
                    () => {
                      const body = new URLSearchParams();
                      const array = [];
                      for (const item of this.data.lista) {
                        if (item.cantidad > 0) {
                          array.push({id_producto: item.id, cantidad: item.cantidad});
                        }
                      }
                      body.set('lista', JSON.stringify(array));

                      this.auth.post('carrito/update_cantidades', body)
                      .then(($response: any) => {
                        this.data.log('response carritoupdatecantidades compra debounced', $response);
                      })
                      .catch(($error: any) => {
                        this.data.log('error carritoupdatecantidades compra debounced', $error);
                      });
                    },
                  );
                }
              }, 2000);
            }
          }
        // }
      } else {
        this.actualEmergenteFlag = false;
        // setTimeout(() => {
        //   this.data.closeLoginModal();
        // }, 1000);
      }
    }
  }

  closeFull(event: any) {
    if (event.target.className && event.target.className.includes('modal__container')) {
      if (!this.focusCampoRegistro) {
        this.closeModal();
      }
    }
  }
  closeFull2(event: any) {
    if (event.target.className && event.target.className.includes('modal__container')) {
      this.carritoCancelModal();
    }
  }
  migrandoStatus: boolean = true;
  migrandoCancelModal() {
    this.migrandoStatus = true;
  }
  closeFull3(event: any) {
    if (event.target.className && event.target.className.includes('modal__container')) {
      this.migrandoCancelModal();
    }
  }
  closeFullRepresentar(event: any) {
    if (event.target.className && event.target.className.includes('modal__container')) {
      this.representarCancel();
    }
  }
  closeEmergente(event: any) {
    if (event.target.className && event.target.className.includes('modal__container')) {
      this.closeEmergente2();
    }
  }
  closeEmergente2() {
    this.actualEmergenteFlag = false;
  }

  updatePrecio($precio: any, $cantidad: any): string {
    const subtotal = $precio * $cantidad;
    return this.formatMoney(subtotal);
  }
  closeModal() {
    this.data.toggleLoginModal();
    this.registrarStatus = false;
  }
  loginLoading = false;
  cuentasRepresentar: any[] = [];
  cantCuentasRepresentar = 0;
  cuentaSeleccionada: any;
  cuentaLoading: boolean = false;
  cuentaRespuesta: string = '';
  seleccionarCuenta(cuenta: any) {
    this.cuentaSeleccionada = Object.assign({}, cuenta);
  }
  loginModal($user: any, $pass: any) {
    this.loginLoading = true;
    this.login.error = false;
    this.auth.autorizar($user.trim(), $pass.trim())
      .then(($response: any) => {
        if (this.auth.localGet('login').primer_login) {
          this.migrandoStatus = false;
          this.loginLoading = false;
          this.loginStatus = true;
          // this.data.toggleLoginModal2()
        } else if (!this.auth.localGet('login').administrativo) {
          this.auth.localSet('actualEmergenteFlag', false);
          this.auth.get('cliente/datos')
          .then(($response: any) => {
            this.auth.localSet('user', $response.response as cliente);
            this.data.toggleLoginStatus(true);
            this.loginLoading = false;
          })
          .catch(($error: any) => {
            this.loginLoading = false;
            this.data.log('loginmodal error app', $error);
          });
        } else {
          // this.auth.get('cliente/getAll')
          // .then(($response) => {
          //   this.data.toggleLoginStatus(true);
          //   this.loginLoading = false;
          //   this.cuentasRepresentar = $response.response;
          //   this.data.toggleRepresentar();
          //   this.cuentaLoading = true;
          // })
          // .catch($error => {
          //   this.loginLoading = false;
          //   this.data.log('clientegetall error app', $error);
          // });

          this.auth.get('cliente/getPrimeros')
          .then(($response: any) => {
            this.data.toggleLoginStatus(true);
            this.loginLoading = false;
            this.cuentasRepresentar = $response.response;
            this.cantCuentasRepresentar = $response.cant;
            this.data.toggleRepresentar();
            this.cuentaLoading = true;
          })
          .catch(($error: any) => {
            this.loginLoading = false;
            this.data.log('clientegetall error app', $error);
          });
        }
      })
      .catch(($catch: any) => {
        this.loginLoading = false;
        this.data.log('loginmodal error app', $catch);
        this.login.errorMsg = ($catch.error.message);
        this.login.error = true;
      });
  }
  migrandoModal() {
    this.loginLoading = true;
    this.login.error = false;
    this.login.errorMsg = '';
    const body = new URLSearchParams();

    body.set('email', this.auth.email);
    body.set('confirmacion_email', this.auth.email);
    body.set('contrasena', this.migracion.pass_original);
    body.set('confirmacion_contrasena', this.migracion.pass_repetido);

    this.auth.post('public/cliente/verificacion_datos', body)
      .then(($response: any) => {
        this.loginLoading = false;
        this.login.confirMsg = ($response.body.response);
      })
      .catch(($error: any) => {
        this.loginLoading = false;
        if (typeof $error.error.response === 'string') {
          this.login.errorMsg = $error.error.response;
        } else {
          Object.keys($error.error.response).forEach((element) => {
            this.login.errorMsg += $error.error.response[element] + ' ';
          });
        }
        this.login.error = true;
        this.data.log('postverificaciondatos error app', $error);
      });
  }
  cerrarRepresentarCuenta() {
    // Client only code.
    if (this.clientOnlyRun) {
      this.auth.desacreditar();
      window.location.href = '/';
    }
  }
  public cambiarCantFiltro(event: any): void {
    this.filterCantidad = event.target.value;
  }
  enterBusqueda(event: any) {
    if (event.keyCode == 13) {
      this.filtrarCuentas();
    }
  }
  filtrarCuentas() {
    this.loginLoading = true;

    const body = new URLSearchParams();

    body.set('like_filter', this.filterBusqueda);
    body.set('cant_filter', this.filterCantidad.toString(10));

    this.auth.post('cliente/getFiltrados', body)
    .then(($response: any) => {
      this.cuentasRepresentar = $response.body.response;
      this.loginLoading = false;
    })
    .catch(($error: any) => {
      this.loginLoading = false;
      this.data.log('clientegetfiltrados error app', $error);
    });
  }
  representarCuenta(cuenta: any) {
    this.cuentaRespuesta = 'Esperando respuesta...';
    if (cuenta) {
      const body = new URLSearchParams();
      body.set('codigo_cliente', cuenta.codigo_cliente);
      this.auth.post('auth/admin/representar', body)
        .then(($response: any) => {
          this.cuentaRespuesta = $response.body.response;
          setTimeout(() => {
            const login = this.auth.localGet('login');
            login.token = $response.body.token;
            this.auth.localSet('login', login);
            this.auth.tokenUpdate($response.body.token);
            this.auth.get('cliente/datos')
              .then(($response) => {
                // Client only code.
                if (this.clientOnlyRun) {
                  this.auth.localSet('user', $response.response as cliente);
                  this.representarCancel();
                  // window.location.reload(true); // forceReload deprecated
                  window.location.href = '/';
                }
              })
              .catch(($error: any) => this.data.log('representarcuenta error app', $error));
          }, 1000);
        })
        .catch(($error: any) => {
          this.cuentaRespuesta = $error.error.response;
        });
    }
  }
  representarCancel() {
    this.data.toggleRepresentar();
    this.loginStatus = true;
  }
  enterLogin($event: any) {
    if ($event.keyCode == 13) {
      this.loginModal(this.login.user, this.login.pass);
    }
  }
  enterRecuperarEvento() {
    this.recuperarError = '';

    const body = new URLSearchParams();
    body.set('email', this.login.user);

    const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
    this.http.post(this.auth.getPath('public/cliente/recuperar_contrasena'), body.toString(), { headers, observe: 'response' })
      .pipe(takeUntil(this.destroy$)).subscribe(
        {
          next: ($response: any) => {
            // this.data.log('recuperarcontraseña response app', $response.body.response.mensaje)
            if ($response.body.response.mensaje) {
              this.recuperarOk = $response.body.response.mensaje;
            }
          },
          error: ($error) => {
            try {
              Object.keys($error.error.response.error).forEach((element) => {
                this.recuperarError += $error.error.response.error[element] + ' ';
              });
            } catch ($throw) {
              this.data.log('recuperarcontraseña error app', $throw);
            }
          }
        }
      );
  }
  enterRecuperar($event: any) {
    this.recuperarOk = '';
    this.recuperarError = '';
    if ($event.keyCode == 13) {
      // this.loginModal(this.login.user, this.login.pass)
      this.enterRecuperarEvento();

    }
  }
  carritoModal() {
    this.data.toggleLoginModal2();
  }
  carritoCancelModal() {
    this.data.toggleLoginModal2();
    this.auth.get('carrito/eliminar').then((result: any) => {
      this.data.cleanCarrito();
      this.data.updateMessage([]);
    }).catch((error: any) => this.data.log('carrito/eliminar error app:', error));
  }

  checkItem($item: any) {
    return {
      status: $item !== '' ? '' : 'complete',
      text: $item !== '' ? $item : '¡Campo incompleto!',
    };
  }
  filterCantidad: number = 20;
  filterBusqueda!: string;
  formatMoney(n: any, c: any = undefined, d: any = undefined, t: any = undefined) {
    let s;
    let i;
    let j;
    c = isNaN(c = Math.abs(c)) ? 2 : c,
    d = d == undefined ? ',' : d,
    t = t == undefined ? '.' : t,
    s = n < 0 ? '-' : '',
    i = String(parseInt(n = Math.abs(Number(n) || 0).toFixed(c))),
    j = (j = i.length) > 3 ? j % 3 : 0;

    return s + (j ? i.substr(0, j) + t : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + t) + (c ? d + Math.abs(n - +i).toFixed(c).slice(2) : '');
  }

  focusResponsable() {
    // Client only code.
    if (this.clientOnlyRun) {
      if (window.outerWidth && window.outerWidth < 540) {
        this.ngSelectResponsable.element.querySelector('input')?.blur();
      }
    }
  }
  focusProvincia() {
    // Client only code.
    if (this.clientOnlyRun) {
      if (window.outerWidth && window.outerWidth < 540) {
        this.ngSelectProvincia.element.querySelector('input')?.blur();
      }
    }
  }
  focusProvincia2() {
    // Client only code.
    if (this.clientOnlyRun) {
      if (window.outerWidth && window.outerWidth < 540) {
        this.ngSelectProvincia2.element.querySelector('input')?.blur();
      }
    }
  }
  focusTransporte() {
    // Client only code.
    if (this.clientOnlyRun) {
      if (window.outerWidth && window.outerWidth < 540) {
        this.ngSelectTransporte.element.querySelector('input')?.blur();
      }
    }
  }

  passwordFocused() {
    // Client only code.
    if (this.clientOnlyRun) {
      this.focusingPassword = true;
      setTimeout(() => {
        this.focusCampoRegistro = true;
      }, 500);
    }
  }
  passwordBlured() {
    // Client only code.
    if (this.clientOnlyRun) {
      this.focusingPassword = false;
      setTimeout(() => {
        this.focusCampoRegistro = false;
      }, 500);
    }
  }

  campoRegistroFocused() {
    // Client only code.
    if (this.clientOnlyRun) {
      setTimeout(() => {
        this.focusCampoRegistro = true;
      }, 500);
    }
  }
  campoRegistroBlured() {
    // Client only code.
    if (this.clientOnlyRun) {
      setTimeout(() => {
        this.focusCampoRegistro = false;
      }, 500);
    }
  }

  togglePasswordType() {
    if (this.password_type === 'password') {
      this.password_type = 'text';
    } else {
      if (this.password_type === 'text') {
        this.password_type = 'password';
      }
    }
  }

  stepUnoScroll(e: any) {
    if (e.target.offsetHeight + e.target.scrollTop >= e.target.scrollHeight - 5) {
      this.showScrollPromptUno = false;
    }
  }

  public revisarCantidad(e: any) {
    if (e.target && parseInt(e.target.value) < parseInt(e.target.min)) {
      e.target.value = e.target.min;
    }
  }
}

@Pipe({
  name: 'cuentasFilter',
})
export class BusquedaCuentaPipe implements PipeTransform {
  transform(items: any[], searchText: string): any[] {
    if (!items) { return []; }
    if (!searchText) { return items; }

    searchText = searchText.toLowerCase();
    return items.filter((it) => {
      return it.razon_social.toLowerCase().includes(searchText) || it.cuit.includes(parseInt(searchText));
    });
  }
}
