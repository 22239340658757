import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter'
})
export class FilterPipe implements PipeTransform {

  transform(items: any[], term: string): any {
    return term
      ? items.filter((item) => {
        try {
          return (item.titulo && item.titulo.indexOf(term) !== -1) || (item.nombreMostrar && item.nombreMostrar.indexOf(term) !== -1);
        } catch ($error) {
          return false;
        }
      })
      : items;
  }

}
