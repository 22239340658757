<div class="row message">
  <div class="container">
    <ngb-alert *ngIf="successMessage" (click)="alertClicked()" type="success" (close)="alertClicked();">
      <i class="fa fa-check-circle fa-3x" aria-hidden="true"></i> {{ successMessage }}
      <button class="actionButton transparentButton" [routerLink]="['/compra/carrito']">VER TODOS</button>
    </ngb-alert>
  </div>
</div>
<div class="row" style="background: #f8f8f8; margin: 0;">
  <div class="container">
    <div class="row cuerpo">
      <div class="tab p-0">
        <div class="tab__header">
          <p>Bienvenido/a {{DatosUsuario.razonSocial}}</p>
          <ul>
            <li [class.active]="transaccion.check(i)"
              (click)="cambioTab(i)"
              *ngFor="let item of ListaItems; let i = index"
              ><i class="fa fa-{{item.icon}}" aria-hidden="true"></i>
              <span>{{item.texto}}</span>
            </li>
          </ul>
        </div>
          <div class="tab__content">
            <!-- Mi cuenta Tab -->
            <div class="tab__item" *ngIf="transaccion.paso === 0">
                <div class="content">
                  <div class="row tab__item__header show__desktop">
                      <p>Tu número de Cliente es {{DatosUsuario.codigo}}</p>
                      <p class="w-auto">{{ListaItems[transaccion.paso].texto}}</p>
                  </div>
                  <div class="row tab__item__header show__mobile">
                      <p>Nº de Cliente: {{DatosUsuario.codigo}}</p>
                      <p class="w-auto">{{ListaItems[transaccion.paso].texto}}</p>
                  </div>
                  <div class="row">
                    <div class="col-md-9 col-12">
                      <div class="oferta">
                        <div class="border col-lg-6">
                          <h2 style="font-weight: bold;">¡OFERTA!</h2>
                          <h2>RECOMENDADA PARA VOS!</h2>
                        </div>
                        <div class="content d-flex flex-wrap">
                          <div class="col-12 col-md-6">
                            <h2 class="mt-3">{{DatosUsuario.razonSocial}}</h2>
                            <h3>Numero de Cliente: {{DatosUsuario.codigo}}</h3>
                            <div class="detalle">
                              <p>{{DatosUsuario.categoriaIva}}</p>
                              <p>CUIL {{DatosUsuario.cuit}}</p>
                            </div>
                          </div>
                          <div class="col-12 col-md-6" *ngIf="loadingProductoRecomendado || serverOnlyRun; else productoRecomendado">
                            <!-- Loading spinner -->
                            <div class="spinner">
                              <div class="dot1"></div>
                              <div class="dot2"></div>
                            </div>
                          </div>
                          <ng-template #productoRecomendado>
                            <div class="col-12 col-md-6" *ngIf="item; else placeholderImage">
                              <div class="resultado__item col-8 custom__col-md-15 mx-auto">
                                <app-producto-item [item]="item" [sesion]="true" [mensaje]="_success" [itemcarousel]="false"></app-producto-item>
                              </div>
                            </div>
                            <ng-template #placeholderImage>
                              <div class="d-flex flex-wrap col-12 col-md-6 d-flex justify-content-center align-items-end my-4 pt-4">
                                <div class="col-12 text-center">
                                  <img width="230" height="94" [ngClass]="'lazyload'" data-src="./assets/images/producto/1.webp" alt="" />
                                </div>
                                <div class="col-12 text-center">Escobón Escobillón Sina®</div>
                              </div>
                            </ng-template>
                          </ng-template>
                        </div>
                      </div>
                      <a href="{{ environment.APP_SATISFACCION_BASE }}" target="_blank" rel="noopener noreferrer" class="encuesta-satisfaccion d-flex justify-content-center align-items-center rounded py-5 px-4 bg-primary bg-gradient text-decoration-none text-white">
                        ¡Nos interesa conocer tu opinión! <i class="fa fa-external-link ms-2"></i>
                      </a>
                    </div>
                    <div class="col-md-3  col-12 columnaSide">
                      <div class="side">
                        <button type="button" class="whiteButton blueButton" routerLink="/como-comprar">
                          <p style="margin: auto;">¿COMO COMPRAR?</p>
                        </button>
                        <button *ngIf="config.switchComprasActivo" (click)="descargarLista()" class="whiteButton justify-content-center">
                          <i class="fa fa-download" aria-hidden="true"></i>
                          <p>LISTA DE PRECIOS</p>
                        </button>
                        <button *ngIf="config.switchComprasActivo" (click)="descargarListaFrecuentes()" class="whiteButton justify-content-center">
                          <i class="fa fa-download" aria-hidden="true"></i>
                          <p>LISTA FRECUENTES</p>
                        </button>
                        <div class="d-flex gap-2 justify-content-start my-3" style="color: #057aff;">
                          <input type="checkbox" role="switch" class="custom-switch" id="toggleFormat1" [checked]="fileFormatSelector" (click)="toggleFileFormat()"/>
                          <label for="toggleFormat1">Formato: {{ fileFormatSelector ? 'PDF' : 'Excel' }}</label>
                        </div>
                        <div class="d-inline-flex" style="margin-left:5px;">
                          <input style="width: 10%;" type="checkbox"  [(ngModel)]="listaPreciosImagen" name="lista_precios_imagen" aria-label="Lista de precios con Imagenes" class="mt-2">
                          <label style="width: 90%;" for="lista_precios_imagen" class="p-2" style="color:#057aff; font-size:12px;">Incluir fotos de productos</label>
                        </div>
            						<div class="text-center" id="loaderFile" style="display:none;">
                          <span style="color:#057aff; font-size:11px;">% {{ fileDownloadProgressValue }}</span>
            							<span style="color:#057aff; font-size:11px;">Generando archivo</span>
            							<span style="color:#057aff; font-size:11px;">Por favor espere...</span>
            							<div><img width="50" height="40" [ngClass]="'lazyload'" data-src="./assets/images/loader/ajax-loader.gif" /></div>
            						</div>
                        <ul>
                          <li *ngFor="let item of ListaItems; let i = index" [class.active]="transaccion.check(i)" (click)="cambioTab(i)">
                            <div class="list">
                              <div class="img"><img width="18" height="20" [ngClass]="'lazyload'" [attr.data-src]="item.model" alt=""></div>
                              <p>
                                {{item.texto}}
                              </p>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
            </div>
            <!-- Mis Datos Tab -->
            <div class="tab__item" *ngIf="transaccion.paso === 1">
              <div class="content">
                <div class="row tab__item__header show__desktop">
                    <p>Tu número de Cliente es {{DatosUsuario.codigo}}</p>
                    <p class="w-auto">{{ListaItems[transaccion.paso].texto}}</p>
                </div>
                <div class="row tab__item__header show__mobile">
                    <p>Nº de Cliente: {{DatosUsuario.codigo}}</p>
                    <p class="w-auto">{{ListaItems[transaccion.paso].texto}}</p>
                </div>
                <div class="row" *ngIf="!(DatosUsuario.dataFiscal == 1 || DatosUsuario.dataFiscal == true)">
                  <div class="border border-2 mb-2 col-md-6 col-12" style="margin-top: -15px;">
                    <p class="mb-0 mt-3" style="color: red;">¡ADEUDA CM05!</p>
                    <p style="padding-top:10px; text-align:left;">
                      Enviá a infoweb@sina.com.ar la información fiscal respecto de tu condición tanto ante AFIP como en rentas o cualquier modificación que tuvieras en un plazo de 30 días.
                      <br>
                      Si estás inscripto en convenio multilateral deberás presentar cada año el formulario CM05 actualizado, de lo contrario se aplicará la percepción máxima.
                    </p>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-9 col-12" style="margin-top: -15px;">
                    <ngb-accordion #acc="ngbAccordion" activeIds="datos0">
                      <ngb-panel id="datos0">
                        <ng-template ngbPanelTitle>
                          <div class="header">
                            Datos fiscales <span>&#x25bc;</span>
                          </div>
                        </ng-template>
                        <ng-template ngbPanelContent >
                          <div class="form-group row">
                            <label for="example-text-input" class="col-3 col-form-label">
                                Razon social
                            </label>
                            <div class="col-9">
                              <input disabled class="form-control" type="text" name="razon_social" aria-label="Razon Social" [ngModel]="DatosUsuario.razonSocial" >
                            </div>
                          </div>
                          <div class="form-group row">
                            <label for="example-text-input" class="col-3 col-form-label">
                                CUIT/DNI
                            </label>
                            <div class="col-9">
                              <input disabled class="form-control" type="text" name="cuit" aria-label="CUIT" [ngModel]="DatosUsuario.cuit">
                            </div>
                          </div>
                          <div class="form-group row">
                            <label for="example-text-input" class="col-3 col-form-label">
                                Email
                            </label>
                            <div class="col-9">
                              <input disabled class="form-control" type="text" name="email" aria-label="Email" [ngModel]="DatosUsuario.email">
                            </div>
                          </div>

                          <div class="form-group row py-2 py-md-0">
                            <button class="col-12 solicitudCambioDatosDisabled" style="text-align: left;" (click)="solicitarCambioDatosDisabled()">
                              Deseo solicitar un cambio de Razon social | CUIT/DNI | Email
                            </button>
                            <p *ngIf="solicitudCambioDatosDisabledEnviadaFlag" class="col-12 text-center" style="color: #057aff;">¡Solicitud enviada con éxito!</p>
                          </div>
                          <div *ngIf="solicitudCambioDatosDisabledFlag" class="modal__container">
                            <div role="document" class="modal-dialog">
                              <div class="modal-content">
                                <div class="modal-header">
                                  <h4 class="modal-title">Solicitud de cambio de datos fiscales</h4>
                                  <button aria-label="Close" class="close p-0 bg-transparent border-0 fs-5 fw-bold opacity-25" type="button">
                                    <span aria-hidden="true" (click)= "solicitudCambioDatosDisabledCancelar()">×</span>
                                  </button>
                                </div>
                                <div class="modal-body" style="padding: 10px 0;">
                                  <div class="form-group row" style="margin: 0;">
                                    <label for="example-text-input" class="col-3 col-form-label">
                                      Razon social
                                    </label>
                                    <div class="col-9">
                                      <input class="form-control" type="text" name="razon_social" aria-label="Razon Social" [(ngModel)]="newRazonSocial">
                                    </div>
                                  </div>
                                  <div class="form-group row" style="margin: 0;">
                                    <label for="example-text-input" class="col-3 col-form-label">
                                      CUIT/DNI
                                    </label>
                                    <div class="col-9">
                                      <input class="form-control" type="text" name="cuit" aria-label="CUIT" [(ngModel)]="newCuit">
                                    </div>
                                  </div>
                                  <div class="form-group row" style="margin: 15px 0;">
                                    <label for="example-text-input" class="col-3 col-form-label">
                                      Email
                                    </label>
                                    <div class="col-9">
                                      <input class="form-control" type="text" name="email" aria-label="Email" [(ngModel)]="newEmail">
                                    </div>
                                  </div>
                                  <p style="margin: 0;">Al recibir su solicitud, revisaremos y actualizaremos los datos en el sistema lo antes posible.</p>
                                  <p *ngIf="solicitudCambioDatosDisabledProcesandoFlag" style="margin: 0; color: #057aff;">Procesando solicitud...</p>
                                  <p *ngIf="solicitudCambioDatosDisabledErrorIncompletoFlag" style="margin: 0; color: red;">Todos los campos son obligatorios.</p>
                                </div>
                                <div class="modal-footer d-block">
                                  <button class="actionButton" type="button" (click)="solicitudCambioDatosDisabledEnviar()" [disabled]="disableButton">ENVIAR</button>
                                  <button class="whiteButton ms-2" type="button" (click)="solicitudCambioDatosDisabledCancelar()">CANCELAR</button>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="form-group row">
                            <label for="example-text-input" class="col-3 col-form-label">
                                Nombre comercial
                            </label>
                            <div class="col-9">
                              <input class="form-control" type="text" name="nombre_fantasia" aria-label="Nombre Comercial" [(ngModel)]="DatosUsuario.nombreFantasia">
                            </div>
                          </div>
                          <div class="form-group row">
                            <label for="example-text-input" class="col-3 col-form-label">
                                Categoria IVA
                            </label>
                            <div class="col-9">
                                <ng-select #responsable labelForId="responsable_value" style="max-height: 32px; margin: 6px 0px;"
                                  [clearable]="true"
                                  (change)="refreshCategoria($event)"
                                  ng-model="DatosUsuario.codCategoriaIva"
                                  placeholder="Categoria IVA">
                                  <ng-option *ngFor="let cat of data.reponsable_lista" [value]="cat.codigo">
                                    {{cat.text}}
                                  </ng-option>
                                </ng-select>
                            </div>
                          </div>
                          <div class="form-group row">
                            <label for="example-text-input" class="col-3 col-form-label">
                                Telefono
                            </label>
                            <div class="col-9">
                              <input class="form-control" type="text" value="{{DatosUsuario.telefono}}" [(ngModel)]="DatosUsuario.telefono" aria-label="Telefono">
                            </div>
                          </div>
                          <div class="form-group row">
                            <label for="example-text-input" class="col-3 col-form-label">
                                Tel Alternativo
                            </label>
                            <div class="col-9">
                              <input class="form-control" type="text" value="{{DatosUsuario.telefonoCelular}}" [(ngModel)]="DatosUsuario.telefonoCelular" aria-label="Telefono Alternativo">
                            </div>
                          </div>
                        <div class="form-group row">
                              <label for="example-text-input" class="col-3 col-form-label">
                                  Direccion
                              </label>
                              <div class="col-9">
                                <input class="form-control" type="text" name="domicilio_direccion" [(ngModel)]="DatosUsuario.domicilio.direccion" aria-label="Domicilio Calle">
                              </div>
                            </div>
                            <div class="form-group row">
                              <label for="example-text-input" class="col-3 col-form-label">
                                  Localidad
                              </label>
                              <div class="col-9">
                                <input class="form-control" type="text" name="domicilio_ciudad" [(ngModel)]="DatosUsuario.domicilio.ciudad" aria-label="Domicilio Ciudad">
                              </div>
                            </div>
                            <div class="form-group row">
                              <label for="example-text-input" class="col-3 col-form-label">
                                  Provincia
                              </label>
                              <div class="col-9">
                                <ng-select #provincia_value labelForId="provincia_value" style="max-height: 32px; margin: 6px 0px;"
                                  [clearable]="true"
                                  (change)="refreshProvincia($event)"
                                  ng-model="DatosUsuario.domicilio.provincia"
                                  placeholder="Provincia"
                                >
                                  <ng-option *ngFor="let prov of provincia_lista" [value]="prov.id">
                                    {{prov.text}}
                                  </ng-option>
                                </ng-select>
                              </div>
                            </div>
                            <div class="form-group row">
                              <label for="example-text-input" class="col-3 col-form-label">
                                  Codigo postal
                              </label>
                              <div class="col-9">
                                <input class="form-control" type="text" name="domicilio_codigo_postal" [(ngModel)]="DatosUsuario.domicilio.codPostal" aria-label="Domicilio Codigo Postal">
                              </div>
                            </div>
                            <div class="form-group row">
                              <label for="example-text-input" class="col-3 col-form-label">
                                  Responsable compras
                              </label>
                              <div class="col-9">
                                <input class="form-control" type="text" name="nombreResponsableFacturacion" [(ngModel)]="DatosUsuario.nombreResponsableFacturacion" aria-label="Nombre Responsable Facturacion">
                              </div>
                            </div>
                            <div class="form-group row">
                              <label for="example-text-input" class="col-3 col-form-label">
                                  Email facturacion
                              </label>
                              <div class="col-9">
                                <input class="form-control" type="text" name="emailFacturacion" [(ngModel)]="DatosUsuario.emailFacturacion" aria-label="Facturacion Email">
                              </div>
                            </div>
                            <div class="form-group row">
                              <label for="example-text-input" class="col-3 col-form-label">
                                  Telefono facturacion
                              </label>
                              <div class="col-9">
                                <input class="form-control" type="text" name="telefonoFacturacion" [(ngModel)]="DatosUsuario.telefonoFacturacion" aria-label="Facturacion Telefono">
                              </div>
                            </div>
                            <!-- <div class="form-group row">
                              <label for="example-text-input" class="col-3 col-form-label">
                                  Breve Descripcion
                              </label>
                              <div class="col-9">
                                <input class="form-control" type="text" name="descripcion" [(ngModel)]="DatosUsuario.descripcion" aria-label="Breve Descripcion Actividad">
                              </div>
                            </div> -->
                          </ng-template>
                      </ngb-panel>
                      <ngb-panel id="datos0">
                        <ng-template ngbPanelTitle>
                          <div class="header">
                            Datos de entrega <span>&#x25bc;</span>
                          </div>
                        </ng-template>
                        <ng-template ngbPanelContent *ngFor="let item of datosFiscales">
                        <div class="form-group row">
                              <label for="example-text-input" class="col-3 col-form-label">
                                  Direccion
                              </label>
                              <div class="col-9">
                                <input class="form-control" type="text" name="entrega_domicilio_direccion" aria-label="Domicio Entrega Calle" [(ngModel)]="DatosUsuario.datosEnvio.domicilioEntrega.direccion">
                              </div>
                            </div>
                            <div class="form-group row">
                              <label for="example-text-input" class="col-3 col-form-label">
                                  Localidad
                              </label>
                              <div class="col-9">
                                <input class="form-control" type="text" name="entrega_domicilio_ciudad" aria-label="Domicilio Entrega Ciudad" [(ngModel)]="DatosUsuario.datosEnvio.domicilioEntrega.ciudad">
                              </div>
                            </div>
                              <div class="form-group row">
                                <label for="example-text-input" class="col-3 col-form-label">
                                    Provincia
                                </label>
                                <div class="col-9">
                                  <ng-select #provincia_value2 labelForId="provincia_value2" style="max-height: 32px; margin: 6px 0px;"
                                    [clearable]="true"
                                    (change)="refreshProvincia2($event)"
                                    ng-model="DatosUsuario.datosEnvio.domicilioEntrega.provincia"
                                    placeholder="Provincia"
                                  >
                                    <ng-option *ngFor="let prov of provincia_lista" [value]="prov.id">
                                      {{prov.text}}
                                    </ng-option>
                                  </ng-select>
                                </div>
                              </div>
                              <div class="form-group row">
                                <label for="example-text-input" class="col-3 col-form-label">
                                    Cod. Postal
                                </label>
                                <div class="col-9">
                                  <input class="form-control" type="text" name="entrega_domicilio_codPostal" aria-label="Domicilio Entrega Codigo Postal" [(ngModel)]="DatosUsuario.datosEnvio.domicilioEntrega.codPostal">
                                </div>
                              </div>
                              <div class="form-group row">
                                <label for="example-text-input" class="col-3 col-form-label">
                                    Telefono
                                </label>
                                <div class="col-9">
                                  <input class="form-control" type="text" name="entrega_domicilio_telefono" aria-label="Domicilio Entrega Telefono" [(ngModel)]="DatosUsuario.datosEnvio.telefono">
                                </div>
                              </div>
                              <div class="form-group row">
                                <label for="example-text-input" class="col-3 col-form-label">
                                    Transporte
                                </label>
                                <div class="col-9">
                                    <ng-select #transporte labelForId="transporte" style="max-height: 32px; margin: 6px 0px;"
                                      [clearable]="true"
                                      (change)="refreshTransporte($event)"
                                      ng-model="DatosUsuario.datosEnvio.codigoTransporte"
                                      placeholder="Medio de transporte">
                                      <ng-option *ngFor="let transporte of transporte_lista" [value]="transporte.id">
                                        {{transporte.text}}
                                      </ng-option>
                                    </ng-select>
                                </div>
                              </div>
                              <div class="form-group row justify-content-end align-items-center" style="margin-top: 10px; margin-bottom: 10px;">
                                <label for="example-text-input" class="col-3 col-form-label" style="margin-top: -8px; margin-bottom: -40px;">
                                  Días que recibe mercadería
                                </label>
                                <div class="col-9" style="padding-top: 5px; padding-bottom: 5px;">
                                  <div class="check_dia_entrega">
                                    <label for="entrega_lunes" class="show__desktop_inline" style="font-weight: normal;">Lunes:</label>
                                    <label for="entrega_lunes" class="show__mobile_inline" style="font-weight: normal;">Lu:</label>
                                    <input type="checkbox" name="entrega_lunes" aria-label="Dias que recibe mercaderia Lunes" [(ngModel)]="DatosUsuario.datosEnvio.entregaLunes">
                                  </div>
                                  <div class="check_dia_entrega">
                                    <label for="entrega_martes" class="show__desktop_inline" style="font-weight: normal;">Martes:</label>
                                    <label for="entrega_martes" class="show__mobile_inline" style="font-weight: normal;">Ma:</label>
                                    <input type="checkbox" name="entrega_martes" aria-label="Dias que recibe mercaderia Martes" [(ngModel)]="DatosUsuario.datosEnvio.entregaMartes">
                                  </div>
                                  <div class="check_dia_entrega">
                                    <label for="entrega_miercoles" class="show__desktop_inline" style="font-weight: normal;">Miércoles:</label>
                                    <label for="entrega_miercoles" class="show__mobile_inline" style="font-weight: normal;">Mi:</label>
                                    <input type="checkbox" name="entrega_miercoles" aria-label="Dias que recibe mercaderia Miercoles" [(ngModel)]="DatosUsuario.datosEnvio.entregaMiercoles">
                                  </div>
                                </div>
                                <div class="col-9" style="float: right; padding-top: 5px; padding-bottom: 5px;">
                                  <div class="check_dia_entrega">
                                    <label for="entrega_jueves" class="show__desktop_inline" style="font-weight: normal;">Jueves:</label>
                                    <label for="entrega_jueves" class="show__mobile_inline" style="font-weight: normal;">Ju:</label>
                                    <input type="checkbox" name="entrega_jueves" aria-label="Dias que recibe mercaderia Jueves" [(ngModel)]="DatosUsuario.datosEnvio.entregaJueves">
                                  </div>
                                  <div class="check_dia_entrega">
                                    <label for="entrega_viernes" class="show__desktop_inline" style="font-weight: normal;">Viernes:</label>
                                    <label for="entrega_viernes" class="show__mobile_inline" style="font-weight: normal;">Vi:</label>
                                    <input type="checkbox" name="entrega_viernes" aria-label="Dias que recibe mercaderia Viernes" [(ngModel)]="DatosUsuario.datosEnvio.entregaViernes">
                                  </div>
                                  <div class="check_dia_entrega">
                                    <label for="entrega_sabado" class="show__desktop_inline" style="font-weight: normal;">Sábado:</label>
                                    <label for="entrega_sabado" class="show__mobile_inline" style="font-weight: normal;">Sá:</label>
                                    <input type="checkbox" name="entrega_sabado" aria-label="Dias que recibe mercaderia Sabado" [(ngModel)]="DatosUsuario.datosEnvio.entregaSabado">
                                  </div>
                                </div>
                              </div>
                              <div class="form-group row">
                                <label for="example-text-input" class="col-3 col-form-label" style="margin-top: -5px;">
                                  Horario Entrega
                                </label>
                                <div class="col-9">
                                  <input class="form-control" type="text" name="entrega_horario" aria-label="Horario de entrega" [(ngModel)]="DatosUsuario.recepcionDiasHorarios">
                                </div>
                              </div>
                          </ng-template>
                      </ngb-panel>
                    </ngb-accordion>
                    <div class="row" *ngIf="procesando_info_ok || procesando_info_entrega_ok">
                      <p style="color: #057aff;">{{procesando_info_ok}}</p>
                      <p style="color: #057aff;">{{procesando_info_entrega_ok}}</p>
                    </div>
                    <div class="row" *ngIf="!procesando_info_entrega && !procesando_info_entrega">
                      <p style="color: red;">{{procesando_info_error}}</p>
                      <p style="color: red;">{{procesando_info_entrega_error}}</p>
                      <button (click)="guardarDatos()" class="actionButton" style="float: right;">GUARDAR</button>
                    </div>
                  </div>
                  <div class="col-md-3  col-12 columnaSide">
                    <div class="side">
                      <button type="button" class="whiteButton blueButton"routerLink="/como-comprar">
                        <p style="margin: auto;">¿COMO COMPRAR?</p>
                      </button>
                      <button *ngIf="config.switchComprasActivo" (click)="descargarLista()" class="whiteButton justify-content-center">
                        <i class="fa fa-download" aria-hidden="true"></i>
                        <p>LISTA DE PRECIOS</p>
						          </button>
                      <button *ngIf="config.switchComprasActivo" (click)="descargarListaFrecuentes()" class="whiteButton justify-content-center">
                        <i class="fa fa-download" aria-hidden="true"></i>
                        <p>LISTA FRECUENTES</p>
                      </button>
                      <div class="d-flex gap-2 justify-content-start my-3" style="color: #057aff;">
                        <input type="checkbox" role="switch" class="custom-switch" id="toggleFormat2" [checked]="fileFormatSelector" (click)="toggleFileFormat()"/>
                        <label for="toggleFormat2">Formato: {{ fileFormatSelector ? 'PDF' : 'Excel' }}</label>
                      </div>
                      <div class="d-inline-flex" style="margin-left:5px;">
                        <input style="width: 10%;" type="checkbox"  [(ngModel)]="listaPreciosImagen" name="lista_precios_imagen" aria-label="Lista de precios con Imagenes" class="mt-2">
                        <label style="width: 90%;" for="lista_precios_imagen" class="p-2" style="color:#057aff; font-size:12px;">Incluir fotos de productos</label>
                      </div>
                      <div class="text-center" id="loaderFile" style="display:none;">
                        <span style="color:#057aff; font-size:11px;">% {{ fileDownloadProgressValue }}</span>
                        <span style="color:#057aff; font-size:11px;">Generando archivo</span>
                        <span style="color:#057aff; font-size:11px;">Por favor espere...</span>
                        <div><img width="50" height="40" [ngClass]="'lazyload'" data-src="./assets/images/loader/ajax-loader.gif" /></div>
                      </div>
                      <ul>
                        <li *ngFor="let item of ListaItems; let i = index" [class.active]="transaccion.check(i)" (click)="cambioTab(i)">
                          <div class="list">
                            <div class="img"><img width="18" height="20" [ngClass]="'lazyload'" [attr.data-src]="item.model" alt=""></div>
                            <p>
                              {{item.texto}}
                            </p>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- Mis Frecuentes Tab -->
            <div class="tab__item" *ngIf="transaccion.paso === 2">
              <div class="content">
                <div class="row tab__item__header show__desktop">
                    <p>Tu número de Cliente es {{DatosUsuario.codigo}}</p>
                    <p class="w-auto">{{ListaItems[transaccion.paso].texto}}</p>
                </div>
                <div class="row tab__item__header show__mobile">
                    <p>Nº de Cliente: {{DatosUsuario.codigo}}</p>
                    <p class="w-auto">{{ListaItems[transaccion.paso].texto}}</p>
                </div>

                <div class="row">
                  <div class="col-md-9 col-12">
                    <div class="row">
                      <h3 class="misFrecuentesTitle p-0">MIS ARTICULOS MAS COMPRADOS</h3>
                    </div>
                    <div class="row" *ngIf="comprados?.length > 0">
                      <div class="misFrecuentes">
                        <div class="row misFrecuentesHeader">
                            <div class="custom__col-1"><div class="border-bottom fw-bold pb-2" style="text-overflow: ellipsis; white-space: nowrap; overflow: hidden; max-width: 100%;">Cantidad</div></div>
                            <div class="custom__col-2"><div class="border-bottom fw-bold pb-2">Descripcion</div></div>
                            <!-- <div class="grupo"> -->
                              <div *ngIf="config.switchComprasActivo" class="custom__col-3"><div class="border-bottom fw-bold pb-2">Precio U.</div></div>
                              <div *ngIf="config.switchComprasActivo" class="custom__col-4"><div class="border-bottom fw-bold pb-2">Subtotal</div></div>
                              <div *ngIf="config.switchComprasActivo" class="custom__col-5"></div>
                            <!-- </div> -->
                        </div>
                        <div class="row misFrecuentesRow flex-nowrap" *ngFor="let item of comprados" [ngStyle]="{'color': (item.habilitado != '1' || (item.enStock != '1' && item.ventaSoloPorPedido != '1')) ? 'red' : 'inherit'}">
                          <div class="custom__col-1">
                            <input *ngIf="item.cantPack === '1'" name="cantidad" aria-label="Cantidad" type="number" min="1" max="999999" [(ngModel)]="item.cantidad" (keyup)="revisarCantidad($event)">
                            <select *ngIf="item.cantPack !== '1'" name="cantidad" aria-label="Cantidad" [(ngModel)]="item.cantidad">
                              <option *ngFor="let cant of item.arrayCants" [value]="cant">{{ cant }}</option>
                            </select>
                          </div>
                          <div class="custom__col-2">
                            <p class="misFrecuentesDescripcion">{{item.nombreMostrar || item.titulo}}</p>
                          </div>
                          <!-- <div class="grupo"> -->
                            <div *ngIf="config.switchComprasActivo" class="custom__col-3 text-nowrap">
                              <p>${{formatMoney(item.precio)}}</p>
                            </div>
                            <div *ngIf="config.switchComprasActivo" class="custom__col-4 text-nowrap">
                              <p>${{formatMoney(item.precio * (+item.cantidad))}}</p>
                            </div>
                            <div *ngIf="config.switchComprasActivo" class="custom__col-5" style="margin-top: -7px;">
                              <div *ngIf="(item.habilitado == '1' && (item.enStock == '1' || item.ventaSoloPorPedido == '1'))">
                                <button type="button" *ngIf="!item.comprado" style="margin: 5px 0;" class="actionButton" (click)="newMessage(item)">AGREGAR</button>
                                <button type="button" *ngIf="item.comprado" style="margin: 5px 0;" class="whiteButton" (click)="removeMessage(item)">AGREGADO</button>
                              </div>
                              <div *ngIf="(item.habilitado != '1' || (item.enStock != '1' && item.ventaSoloPorPedido != '1'))">
                                <button type="button" style="margin: 5px 0; cursor: not-allowed; color: red; background: white; border-radius: 5px; border: 2px solid red; font-weight: bold;" (click)="removeMessage(item)">SIN STOCK</button>
                              </div>
                            </div>
                          <!-- </div> -->
                        </div>
                    </div>
                    </div>
                    <div class="row descarga" *ngIf="comprados?.length > 0">
                        <div class="col-md-9 col-12">
                          <p class="iva__custom__border w-auto">{{iva_usuario}}</p>
                        </div>
                        <div class="col-md-3 col-12">
                          <button *ngIf="config.switchComprasActivo" (click)="descargarLista()" class="whiteButton justify-content-center">
                            <i class="fa fa-download" aria-hidden="true"></i>
                            <p>LISTA DE PRECIOS</p>
                          </button>
                          <button *ngIf="config.switchComprasActivo" (click)="descargarListaFrecuentes()" class="whiteButton justify-content-center">
                            <i class="fa fa-download" aria-hidden="true"></i>
                            <p>LISTA FRECUENTES</p>
                          </button>
                          <div class="d-flex gap-2 justify-content-start my-3" style="color: #057aff;">
                            <input type="checkbox" role="switch" class="custom-switch" id="toggleFormat3" [checked]="fileFormatSelector" (click)="toggleFileFormat()"/>
                            <label for="toggleFormat3">Formato: {{ fileFormatSelector ? 'PDF' : 'Excel' }}</label>
                          </div>
                          <div style="margin-left:5px;">
                            <input style="width: 10%;" type="checkbox"  [(ngModel)]="listaPreciosImagen" name="lista_precios_imagen" aria-label="Lista de precios con Imagenes" class="mt-2">
                            <label style="width: 90%;" for="lista_precios_imagen" class="px-2" style="color:#057aff; font-size:12px;">Incluir fotos de productos</label>
                          </div>
                          <div class="text-center" id="loaderFile" style="display:none;">
                            <span style="color:#057aff; font-size:11px;">% {{ fileDownloadProgressValue }}</span>
                            <span style="color:#057aff; font-size:11px;">Generando archivo</span>
                            <span style="color:#057aff; font-size:11px;">Por favor espere...</span>
                            <div><img width="50" height="40" [ngClass]="'lazyload'" data-src="./assets/images/loader/ajax-loader.gif" /></div>
                          </div>
                        </div>
                    </div>
                    <div class="row" *ngIf="!comprados?.length">
                      <h3 class="misFrecuentesTitle p-0">TODAVIA NO HAY ARTICULOS PARA MOSTRAR</h3>
                    </div>
                  </div>
                  <div class="col-md-3  col-12 columnaSide">
                    <div class="side">
                      <button type="button" class="whiteButton blueButton" routerLink="/como-comprar">
                        <p style="margin: auto;">¿COMO COMPRAR?</p>
                      </button>
                      <button *ngIf="config.switchComprasActivo" (click)="descargarLista()" class="whiteButton justify-content-center">
                        <i class="fa fa-download" aria-hidden="true"></i>
                        <p>LISTA DE PRECIOS</p>
                      </button>
                      <button *ngIf="config.switchComprasActivo" (click)="descargarListaFrecuentes()" class="whiteButton justify-content-center">
                        <i class="fa fa-download" aria-hidden="true"></i>
                        <p>LISTA FRECUENTES</p>
                      </button>
                      <div class="d-flex gap-2 justify-content-start my-3" style="color: #057aff;">
                        <input type="checkbox" role="switch" class="custom-switch" id="toggleFormat4" [checked]="fileFormatSelector" (click)="toggleFileFormat()"/>
                        <label for="toggleFormat4">Formato: {{ fileFormatSelector ? 'PDF' : 'Excel' }}</label>
                      </div>
                      <div class="d-inline-flex" style="margin-left:5px;">
                        <input style="width: 10%;" type="checkbox"  [(ngModel)]="listaPreciosImagen" name="lista_precios_imagen" aria-label="Lista de precios con Imagenes" class="mt-2">
                        <label style="width: 90%;" for="lista_precios_imagen" class="p-2" style="color:#057aff; font-size:12px;">Incluir fotos de productos</label>
                      </div>
                      <div class="text-center" id="loaderFile" style="display:none;">
                        <span style="color:#057aff; font-size:11px;">% {{ fileDownloadProgressValue }}</span>
                        <span style="color:#057aff; font-size:11px;">Generando archivo</span>
                        <span style="color:#057aff; font-size:11px;">Por favor espere...</span>
                        <div><img width="50" height="40" [ngClass]="'lazyload'" data-src="./assets/images/loader/ajax-loader.gif" /></div>
                      </div>
                      <ul>
                        <li *ngFor="let item of ListaItems; let i = index" [class.active]="transaccion.check(i)" (click)="cambioTab(i)">
                          <div class="list">
                            <div class="img"><img width="18" height="20" [ngClass]="'lazyload'" [attr.data-src]="item.model" alt=""></div>
                            <p>
                              {{item.texto}}
                            </p>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- Mi ultima compra Tab -->
            <div class="tab__item ultimaCompra" *ngIf="transaccion.paso === 3">
              <div class="content">
                <div class="row tab__item__header show__desktop">
                    <p>Tu número de Cliente es {{DatosUsuario.codigo}}</p>
                    <p class="w-auto">{{ListaItems[transaccion.paso].texto}}</p>
                </div>
                <div class="row tab__item__header show__mobile">
                    <p>Nº de Cliente: {{DatosUsuario.codigo}}</p>
                    <p class="w-auto">{{ListaItems[transaccion.paso].texto}}</p>
                </div>
                <div class="row">
                  <div class="col-md-9 col-12">
                    <ngb-accordion *ngIf="ultimasCompras?.length" #acc="ngbAccordion" [closeOthers]="true">
                      <ngb-panel *ngFor="let compra of ultimasCompras; let i = index" id="compra{{i}}">
                        <ng-template ngbPanelTitle>
                          <div class="header" style="font-weight: bold;">
                            Compra del {{compra.fechaCreacion?.date.date}}/{{compra.fechaCreacion?.date.month}}/{{compra.fechaCreacion?.date.year}} <span style="color: #057aff;">Ver detalle &#x25BC;</span>
                          </div>
                        </ng-template>
                        <ng-template ngbPanelContent>
                          <div class="pedido__tracking" style="padding: 25px 0px; background: linear-gradient(0deg, transparent, #0d6efd, #0d6efd, #0d6efd, #0d6efd, #0d6efd, transparent); margin: 5px 0px;">
                            <div style="text-align: center; margin-bottom: 0.5rem;">
                              <b class="d-flex flex-column flex-lg-row px-3 mb-2 text-bold justify-content-center" style="color: white;">Estado actual del pedido: <span [ngStyle]="{'color': compra.estado === 'Cancelado' ? 'black' : 'inherit'}" class="ps-1">{{ compra.estado }}</span></b>
                            </div>
                            <div [ngStyle]="{'grid-template-columns': compra.retiroEnTienda ? 'repeat(6, 1fr)' : 'repeat(7, 1fr)'}" style="display: grid; grid-template-rows: 1fr; gap: 2px; width: max-content; max-width: 100%; overflow: auto; margin: auto; margin-bottom: 0.5rem; padding: 0 2px;">
                              <div style="padding: 10px 4px; word-wrap: normal; font-weight: 700; line-height: 1; color: #fff; text-align: center; vertical-align: baseline; border-radius: 50% 50% 50% 50% / 20% 20% 20% 20%; height: 120px;" [ngStyle]="{'width': compra.retiroEnTienda ? '88px' : '73px', 'background-color': compra.estado === 'Espera confirmación' ? '#057aff' : 'white'}">
                                <p [ngStyle]="{'color': compra.estado === 'Espera confirmación' ? 'white' : '#0d6efd'}" style="font-size: 28px; margin: 0;">1</p>
                                <p [ngStyle]="{'color': compra.estado === 'Espera confirmación' ? 'white' : '#0d6efd', 'font-size': compra.fechaPedido?.date ? '12px' : '25px', 'margin': compra.fechaPedido?.date ? '12px 0' : '5px 0'}" style="padding: 0;">{{ compra.fechaPedido?.date && compra.fechaPedido?.date?.slice ? compra.fechaPedido.date.slice(0, 10).split('-').reverse().join('/') : '-' }}</p>
                                <p [ngStyle]="{'color': compra.estado === 'Espera confirmación' ? 'white' : 'black'}" style="font-size: 11px; padding: 0; padding-bottom: 0.25rem; margin: 0;">Espera confirmación</p>
                              </div>
                              <div style="padding: 10px 4px; word-wrap: normal; font-weight: 700; line-height: 1; color: #fff; text-align: center; vertical-align: baseline; border-radius: 50% 50% 50% 50% / 20% 20% 20% 20%; background-color: white; height: 120px;" [ngStyle]="{'width': compra.retiroEnTienda ? '88px' : '73px', 'background-color': compra.estado === 'Próximo a prepararse' ? '#057aff' : 'white'}">
                                <p [ngStyle]="{'color': compra.estado === 'Próximo a prepararse' ? 'white' : '#0d6efd'}" style="font-size: 28px; margin: 0;">2</p>
                                <p [ngStyle]="{'color': compra.estado === 'Próximo a prepararse' ? 'white' : '#0d6efd', 'font-size': compra.fechaProximoPreparacion?.date ? '12px' : '25px', 'margin': compra.fechaProximoPreparacion?.date ? '12px 0' : '5px 0'}" style="padding: 0;">{{ compra.fechaProximoPreparacion?.date && compra.fechaProximoPreparacion?.date?.slice ? compra.fechaProximoPreparacion.date.slice(0, 10).split('-').reverse().join('/') : '-' }}</p>
                                <p [ngStyle]="{'color': compra.estado === 'Próximo a prepararse' ? 'white' : 'black'}" style="font-size: 11px; padding: 0; padding-bottom: 0.25rem; margin: 0;">Próximo a prepararse</p>
                              </div>
                              <div style="padding: 10px 4px; word-wrap: normal; font-weight: 700; line-height: 1; color: #fff; text-align: center; vertical-align: baseline; border-radius: 50% 50% 50% 50% / 20% 20% 20% 20%; background-color: white; height: 120px;" [ngStyle]="{'width': compra.retiroEnTienda ? '88px' : '73px', 'background-color': compra.estado === 'En preparación' ? '#057aff' : 'white'}">
                                <p [ngStyle]="{'color': compra.estado === 'En preparación' ? 'white' : '#0d6efd'}" style="font-size: 28px; margin: 0;">3</p>
                                <p [ngStyle]="{'color': compra.estado === 'En preparación' ? 'white' : '#0d6efd', 'font-size': compra.fechaEnPreparacion?.date ? '12px' : '25px', 'margin': compra.fechaEnPreparacion?.date ? '12px 0' : '5px 0'}" style="padding: 0;">{{ compra.fechaEnPreparacion?.date && compra.fechaEnPreparacion?.date?.slice ? compra.fechaEnPreparacion.date.slice(0, 10).split('-').reverse().join('/') : '-' }}</p>
                                <p [ngStyle]="{'color': compra.estado === 'En preparación' ? 'white' : 'black', 'margin-top': compra.fechaEnPreparacion?.date && compra.retiroEnTienda ? '22px' : (compra.retiroEnTienda ? '16px' : '0')}" style="font-size: 11px; padding: 0; padding-bottom: 0.25rem; margin: 0;">En preparación</p>
                              </div>
                              <div style="padding: 10px 4px; word-wrap: normal; font-weight: 700; line-height: 1; color: #fff; text-align: center; vertical-align: baseline; border-radius: 50% 50% 50% 50% / 20% 20% 20% 20%; background-color: white; height: 120px;" [ngStyle]="{'width': compra.retiroEnTienda ? '88px' : '73px', 'background-color': compra.estado === 'Preparado' ? '#057aff' : 'white'}">
                                <p [ngStyle]="{'color': compra.estado === 'Preparado' ? 'white' : '#0d6efd'}" style="font-size: 28px; margin: 0;">4</p>
                                <p [ngStyle]="{'color': compra.estado === 'Preparado' ? 'white' : '#0d6efd', 'font-size': compra.fechaPreparado?.date ? '12px' : '25px', 'margin': compra.fechaPreparado?.date ? '12px 0' : '5px 0'}" style="padding: 0;">{{ compra.fechaPreparado?.date && compra.fechaPreparado?.date?.slice ? compra.fechaPreparado.date.slice(0, 10).split('-').reverse().join('/') : '-' }}</p>
                                <p [ngStyle]="{'color': compra.estado === 'Preparado' ? 'white' : 'black', 'margin-top': compra.fechaPreparado?.date ? '22px' : '16px'}" style="font-size: 11px; padding: 0; padding-bottom: 0.25rem; margin: 0;">Preparado</p>
                              </div>
                              <div style="padding: 10px 4px; word-wrap: normal; font-weight: 700; line-height: 1; color: #fff; text-align: center; vertical-align: baseline; border-radius: 50% 50% 50% 50% / 20% 20% 20% 20%; background-color: white; height: 120px;" [ngStyle]="{'width': compra.retiroEnTienda ? '88px' : '73px', 'background-color': compra.estado === 'Empaquetado' ? '#057aff' : 'white'}">
                                <p [ngStyle]="{'color': compra.estado === 'Empaquetado' ? 'white' : '#0d6efd'}" style="font-size: 28px; margin: 0;">5</p>
                                <p [ngStyle]="{'color': compra.estado === 'Empaquetado' ? 'white' : '#0d6efd', 'font-size': compra.fechaEmpaquetado?.date ? '12px' : '25px', 'margin': compra.fechaEmpaquetado?.date ? '12px 0' : '5px 0'}" style="padding: 0;">{{ compra.fechaEmpaquetado?.date && compra.fechaEmpaquetado?.date?.slice ? compra.fechaEmpaquetado.date.slice(0, 10).split('-').reverse().join('/') : '-' }}</p>
                                <p [ngStyle]="{'color': compra.estado === 'Empaquetado' ? 'white' : 'black', 'margin-top': compra.fechaEmpaquetado?.date ? '22px' : '16px'}" style="font-size: 11px; padding: 0; padding-bottom: 0.25rem; margin: 0;">Empaquetado</p>
                              </div>
                              <div *ngIf="!compra.retiroEnTienda" style="padding: 10px 4px; word-wrap: normal; font-weight: 700; line-height: 1; color: #fff; text-align: center; vertical-align: baseline; border-radius: 50% 50% 50% 50% / 20% 20% 20% 20%; background-color: white; height: 120px;" [ngStyle]="{'width': compra.retiroEnTienda ? '88px' : '73px', 'background-color': compra.estado === 'Despachado' ? '#057aff' : 'white'}">
                                <p [ngStyle]="{'color': compra.estado === 'Despachado' ? 'white' : '#0d6efd'}" style="font-size: 28px; margin: 0;">6</p>
                                <p [ngStyle]="{'color': compra.estado === 'Despachado' ? 'white' : '#0d6efd', 'font-size': compra.fechaDespachado?.date ? '12px' : '25px', 'margin': compra.fechaDespachado?.date ? '12px 0' : '5px 0'}" style="padding: 0;">{{ compra.fechaDespachado?.date && compra.fechaDespachado?.date?.slice ? compra.fechaDespachado.date.slice(0, 10).split('-').reverse().join('/') : '-' }}</p>
                                <p [ngStyle]="{'color': compra.estado === 'Despachado' ? 'white' : 'black', 'margin-top': compra.fechaDespachado?.date ? '22px' : '16px'}" style="font-size: 11px; padding: 0; padding-bottom: 0.25rem; margin: 0;">Despachado</p>
                              </div>
                              <div style="padding: 10px 4px; word-wrap: normal; font-weight: 700; line-height: 1; color: #fff; text-align: center; vertical-align: baseline; border-radius: 50% 50% 50% 50% / 20% 20% 20% 20%; background-color: white; height: 120px;" [ngStyle]="{'width': compra.retiroEnTienda ? '88px' : '73px', 'background-color': compra.estado === 'Entregado' ? '#057aff' : 'white'}">
                                <p [ngStyle]="{'color': compra.estado === 'Entregado' ? 'white' : '#0d6efd'}" style="font-size: 28px; margin: 0;">{{ compra.retiroEnTienda ? '6' : '7' }}</p>
                                <p [ngStyle]="{'color': compra.estado === 'Entregado' ? 'white' : '#0d6efd', 'font-size': compra.fechaEntregado?.date ? '12px' : '25px', 'margin': compra.fechaEntregado?.date ? '12px 0' : '5px 0'}" style="padding: 0;">{{ compra.fechaEntregado?.date && compra.fechaEntregado?.date?.slice ? compra.fechaEntregado.date.slice(0, 10).split('-').reverse().join('/') : '-' }}</p>
                                <p [ngStyle]="{'color': compra.estado === 'Entregado' ? 'white' : 'black', 'margin-top': compra.fechaEntregado?.date ? '22px' : '16px'}" style="font-size: 11px; padding: 0; padding-bottom: 0.25rem; margin: 0;">Entregado</p>
                              </div>
                            </div>
                          </div>
                          <hr>
                          <div class="misFrecuentes border-0" style="padding: 4px; margin: -1rem;">
                            <div class="row misFrecuentesHeader">
                              <div class="custom__col-1"><div class="border-bottom fw-bold pb-2" style="text-overflow: ellipsis; white-space: nowrap; overflow: hidden; max-width: 100%;">Cantidad</div></div>
                              <div class="custom__col-2"><div class="border-bottom fw-bold pb-2">Descripcion</div></div>
                              <!-- <div class="grupo"> -->
                                <div *ngIf="config.switchComprasActivo" class="custom__col-3"><div class="border-bottom fw-bold pb-2 text-nowrap">Precio U.</div></div>
                                <div *ngIf="config.switchComprasActivo" class="custom__col-4"><div class="border-bottom fw-bold pb-2 text-nowrap">Subtotal</div></div>
                              <!-- </div> -->
                            </div>
                            <div class="row misFrecuentesRow" *ngFor="let item of compra.items" style="padding-top: 4px;" [ngStyle]="{'color': (item.producto.habilitado != '1' || (item.producto.enStock != '1' && item.producto.ventaSoloPorPedido != '1')) ? 'red' : 'inherit'}">
                                <div class="custom__col-1 sinPaddingLeftMobile">
                                  <input *ngIf="item.producto.cantPack === '1'" name="cantidad" aria-label="Cantidad" type="number" min="1" max="999999" [(ngModel)]="item.cantidad" (keyup)="revisarCantidad($event)">
                                  <select *ngIf="item.producto.cantPack !== '1'" name="cantidad" aria-label="Cantidad" [(ngModel)]="item.cantidad">
                                    <option *ngFor="let cant of item.producto.arrayCants" [value]="cant">{{cant}}</option>
                                  </select>
                                  <!-- <p style="margin-left: 5px;"> {{item.cantidad}}</p> -->
                                </div>
                                <div class="custom__col-2 sinPaddingLeftMobile">
                                  <p style="margin-left: 2px;" class="misFrecuentesDescripcion">{{item.producto.nombreMostrar || item.producto.titulo}}</p>
                                </div>
                                <!-- <div class="grupo"> -->
                                  <div *ngIf="config.switchComprasActivo" class="custom__col-3 text-nowrap">
                                    <p>${{formatMoney(item.producto.precio)}}</p>
                                  </div>
                                  <div *ngIf="config.switchComprasActivo" class="custom__col-4 sinPaddingLeftMobile text-nowrap">
                                    <p class="marginLeftMobile">${{formatMoney(item.subtotal)}}</p>
                                  </div>
                                <!-- </div> -->

                                <div *ngIf="config.switchComprasActivo" class="custom__col-5" style="margin-top: -7px;">
                                  <div *ngIf="(item.producto.habilitado == '1' && (item.producto.enStock == '1' || item.producto.ventaSoloPorPedido == '1'))">
                                    <button type="button" *ngIf="!item.comprado" style="margin: 5px 0;" class="actionButton" (click)="newMessageFromPedido(item)">AGREGAR</button>
                                    <button type="button" *ngIf="item.comprado" style="margin: 5px 0;" class="whiteButton" (click)="removeMessageFromPedido(item)">AGREGADO</button>
                                  </div>
                                  <div *ngIf="(item.producto.habilitado != '1' || (item.producto.enStock != '1' && item.producto.ventaSoloPorPedido != '1'))">
                                    <button type="button" style="margin: 5px 0; cursor: not-allowed; color: red; background: white; border-radius: 5px; border: 2px solid red; font-weight: bold;" (click)="removeMessageFromPedido(item)">SIN STOCK</button>
                                  </div>
                                </div>
                              </div>
                              <div class="row d-block misFrecuentesTotal">
                                <div class="custom__col-2" style="min-width: 90px;">
                                  <button *ngIf="config.switchComprasActivo" class="actionButton me-2" (click)="repetirPregunta(compra)" style="width: 50%;">REPETIR COMPRA</button>
                                  <!--<a href="#"> -->
                                  <a target="_blank" rel="noopener noreferrer" [href]="compra.urlPdf" *ngIf="compra.urlPdf">
                                    <button class="whiteButton" style="width: 50%; padding: 5px 10px;">
                                      VER PEDIDO
                                    </button>
                                  </a>
                                </div>

                                <div *ngIf="_existDesktop" class="custom__col-1"></div>
                                <div *ngIf="_existDesktop" class="custom__col-3">
                                  <p *ngIf="config.switchComprasActivo" style="margin-top: 16px; font-weight: bold;">Subtotal</p>
                                </div>
                                <div *ngIf="_existDesktop" class="custom__col-4 text-nowrap">
                                  <p *ngIf="config.switchComprasActivo" style="margin-top: 10px;font-size: 20px; font-weight: bold;">${{formatMoney(compra.total)}}</p>
                                </div>

                                <div *ngIf="_existMobile" style="margin-left: 60%;">
                                  <p *ngIf="config.switchComprasActivo" style="margin-top: 16px; font-weight: bold; font-size: 12px;">Subtotal</p>
                                </div>
                                <div *ngIf="_existMobile" style="margin-left: 60%;" class="text-nowrap">
                                  <p *ngIf="config.switchComprasActivo" style="margin-top: 10px;font-size: 16px; font-weight: bold;">${{formatMoney(compra.total)}}</p>
                                </div>
                              </div>
                            </div>
                        </ng-template>
                      </ngb-panel>
                    </ngb-accordion>
                    <div class="row" *ngIf="!ultimasCompras?.length">
                        <h3 class="misFrecuentesTitle p-0">TODAVIA NO SE REGISTRAN COMPRAS ANTERIORES PARA MOSTRAR</h3>
                    </div>
                    <div *ngIf="repetirFlag" class="modal__container" (click)="closeRepetir($event)">
                      <div role="document" class="modal-dialog">
                        <div class="modal-content">
                          <div class="modal-header">
                            <h4 class="modal-title">REPETIR COMPRA</h4>
                            <button aria-label="Close" class="close p-0 bg-transparent border-0 fs-5 fw-bold opacity-25" type="button">
                            <span aria-hidden="true" (click)= "transaccion.cambio(0)">×</span>
                            </button>
                          </div>
                          <div class="modal-body">
                            <p>Al repetir la compra se van a eliminar todos los productos actuales en el carrito y se van a agregar los de la anterior compra seleccionada. ¿Quiere proceder?</p>
                            <p *ngIf="repetirOmitsTemp && repetirOmitsTemp.length > 0" style="font-size: 12px;"><b>Importante:</b> Solo se agregarán al carrito los productos que se encuentren <b>en stock</b> actualmente.</p>
                            <p *ngIf="repetirOmitsTemp && repetirOmitsTemp.length > 0" style="font-size: 12px;" class="mb-1">Los siguientes artículos serán omitidos:</p>
                            <ul *ngIf="repetirOmitsTemp && repetirOmitsTemp.length > 0" style="font-size: 12px; max-height: 175px; overflow: auto;">
                              <li *ngFor="let item of repetirOmitsTemp" class="original">{{ item.producto.nombreMostrar || item.producto.titulo }}</li>
                            </ul>
                          </div>
                          <div class="modal-footer d-block">
                            <button class="actionButton" type="button" (click)= "repetirCompra()">ACEPTAR</button>
                            <button class="whiteButton ms-2" type="button" (click)= "repetirCancelar()" >CANCELAR</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-3 col-12 columnaSide">
                    <div class="side">
                      <button type="button" class="whiteButton blueButton" routerLink="/como-comprar">
                        <p style="margin: auto;">¿COMO COMPRAR?</p>
                      </button>
                      <button *ngIf="config.switchComprasActivo" (click)="descargarLista()" class="whiteButton justify-content-center">
                        <i class="fa fa-download" aria-hidden="true"></i>
                        <p>LISTA DE PRECIOS</p>
                      </button>
                      <button *ngIf="config.switchComprasActivo" (click)="descargarListaFrecuentes()" class="whiteButton justify-content-center">
                        <i class="fa fa-download" aria-hidden="true"></i>
                        <p>LISTA FRECUENTES</p>
                      </button>
                      <div class="d-flex gap-2 justify-content-start my-3" style="color: #057aff;">
                        <input type="checkbox" role="switch" class="custom-switch" id="toggleFormat5" [checked]="fileFormatSelector" (click)="toggleFileFormat()"/>
                        <label for="toggleFormat5">Formato: {{ fileFormatSelector ? 'PDF' : 'Excel' }}</label>
                      </div>
                      <div class="d-inline-flex" style="margin-left:5px;">
                        <input style="width: 10%;" type="checkbox"  [(ngModel)]="listaPreciosImagen" name="lista_precios_imagen" aria-label="Lista de precios con Imagenes" class="mt-2">
                        <label style="width: 90%;" for="lista_precios_imagen" class="p-2" style="color:#057aff; font-size:12px;">Incluir fotos de productos</label>
                      </div>
                      <div class="text-center" id="loaderFile" style="display:none;">
                        <span style="color:#057aff; font-size:11px;">% {{ fileDownloadProgressValue }}</span>
                        <span style="color:#057aff; font-size:11px;">Generando archivo</span>
                        <span style="color:#057aff; font-size:11px;">Por favor espere...</span>
                        <div><img width="50" height="40" [ngClass]="'lazyload'" data-src="./assets/images/loader/ajax-loader.gif" /></div>
                      </div>
                      <ul>
                        <li *ngFor="let item of ListaItems; let i = index" [class.active]="transaccion.check(i)" (click)="cambioTab(i)">
                          <div class="list">
                            <div class="img"><img width="18" height="20" [ngClass]="'lazyload'" [attr.data-src]="item.model" alt=""></div>
                            <p>
                              {{item.texto}}
                            </p>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <br>
                  <div class="col-md-9 col-12">
                    <p class="iva__custom__border w-auto">{{iva_usuario}}</p>
                  </div>
                </div>
              </div>
            </div>
            <!-- Cerrar sesion Tab -->
            <div class="tab__item" *ngIf="transaccion.paso === 4">
                <div class="content">
                  <div class="row tab__item__header show__desktop">
                      <p>Tu número de Cliente es {{DatosUsuario.codigo}}</p>
                      <p class="w-auto">{{ListaItems[transaccion.paso].texto}}</p>
                  </div>
                  <div class="row tab__item__header show__mobile">
                      <p>Nº de Cliente: {{DatosUsuario.codigo}}</p>
                      <p class="w-auto">{{ListaItems[transaccion.paso].texto}}</p>
                  </div>
                  <div class="row">
                    <div class="col-md-9 col-12">
                      <div class="modal__container" (click)="closeFull($event)">
                          <div role="document" class="modal-dialog">
                            <div class="modal-content">
                            <div class="modal-header py-3">
                            <h4 class="modal-title">CERRAR SESIÓN</h4>
                            <button
                              aria-label="Close"
                              class="close mt-0"
                              style="
                                background: 0 0;
                                border: 0;
                                font-size: 21px;
                                font-weight: 700;
                                line-height: 1;
                                color: #999;
                                text-shadow: 0 1px 0 #fff;
                              "
                              type="button"
                            >
                              <span aria-hidden="true" (click)= "transaccion.cambio(0)">×</span>
                            </button>
                            </div>
                            <div class="modal-body">
                            <p>¿Estas seguro que deseas cerrar sesión?</p>
                            </div>
                            <div class="modal-footer d-block">
                                <button class="actionButton" type="button" (click)= "closeSession()" [routerLink]="['/']">ACEPTAR</button>
                                <button class="whiteButton ms-2" type="button" (click)= "transaccion.cambio(0)" >CANCELAR</button>
                              </div>
                            </div>
                            </div>
                      </div>
                    </div>
                    <div class="col-md-3 col-12 columnaSide">
                      <div class="side">
                        <button type="button" class="whiteButton blueButton" routerLink="/como-comprar">
                          <p style="margin: auto;">¿COMO COMPRAR?</p>
                        </button>
                        <button *ngIf="config.switchComprasActivo" (click)="descargarLista()" class="whiteButton justify-content-center">
                          <i class="fa fa-download" aria-hidden="true"></i>
                          <p>LISTA DE PRECIOS</p>
                        </button>
                        <button *ngIf="config.switchComprasActivo" (click)="descargarListaFrecuentes()" class="whiteButton justify-content-center">
                          <i class="fa fa-download" aria-hidden="true"></i>
                          <p>LISTA FRECUENTES</p>
                        </button>
                        <div class="d-flex gap-2 justify-content-start my-3" style="color: #057aff;">
                          <input type="checkbox" role="switch" class="custom-switch" id="toggleFormat6" [checked]="fileFormatSelector" (click)="toggleFileFormat()"/>
                          <label for="toggleFormat6">Formato: {{ fileFormatSelector ? 'PDF' : 'Excel' }}</label>
                        </div>
                        <div class="d-inline-flex" style="margin-left:5px;">
                          <input style="width: 10%;" type="checkbox"  [(ngModel)]="listaPreciosImagen" name="lista_precios_imagen" aria-label="Lista de precios con Imagenes" class="mt-2">
                          <label style="width: 90%;" for="lista_precios_imagen" class="p-2" style="color:#057aff; font-size:12px;">Incluir fotos de productos</label>
                        </div>
                        <div class="text-center" id="loaderFile" style="display:none;">
                          <span style="color:#057aff; font-size:11px;">% {{ fileDownloadProgressValue }}</span>
                          <span style="color:#057aff; font-size:11px;">Generando archivo</span>
                          <span style="color:#057aff; font-size:11px;">Por favor espere...</span>
                          <div><img width="50" height="40" [ngClass]="'lazyload'" data-src="./assets/images/loader/ajax-loader.gif" /></div>
                        </div>
                        <ul>
                          <li *ngFor="let item of ListaItems; let i = index" [class.active]="transaccion.check(i)" (click)="cambioTab(i)">
                            <div class="list">
                              <div class="img"><div class="img"><img width="18" height="20" [ngClass]="'lazyload'" [attr.data-src]="item.model" alt=""></div></div>
                              <p>
                                {{item.texto}}
                              </p>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
          </div>
        </div>
    </div>
  </div>

  <dialog open *ngIf="showPDFModal" id="pdf-modal">
    <div id="pdf-modal-content">
      <div><p>Estamos generando el archivo PDF...</p></div>
      <div>
        <span style="color:#0579ff; font-size:11px;">Por favor espere...</span>
        <span style="color:#0579ff; font-size:11px; margin-left: 10px;">% {{ fileDownloadProgressValue }}</span>
      </div>
      <div id="pdf-modal-main">
        <div id="Limpieza" class="d-flex flex-column justify-content-start align-items-center">
          <div>
            <img class="my-2" title="imagen" src="/assets/images/header/logo_azul.webp" width="166.5" height="51" alt="Logo SINA">
          </div>
        </div>
        <div id="Bazar" class="d-flex flex-column justify-content-start align-items-center"></div>
        <div id="Textil" class="d-flex flex-column justify-content-start align-items-center"></div>
        <div id="Liquidos" class="d-flex flex-column justify-content-start align-items-center"></div>
        <div id="Jardin-y-riego" class="d-flex flex-column justify-content-start align-items-center"></div>
        <div id="Profesional" class="d-flex flex-column justify-content-start align-items-center"></div>
        <div id="Mas-productos" class="d-flex flex-column justify-content-start align-items-center"></div>
      </div>
    </div>
  </dialog>
</div>
