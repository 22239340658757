<div class="container-fluid">
  <h1 class="title">{{(categoriaHijo || categoriaPadre) ? (categoriaHijo ? (categoriaHijo + ', ' + categoriaHijo + ' al por mayor') : (categoriaPadre + ', ' + categoriaPadre + ' al por mayor')) : 'Productos de ' + familiaActual + ' por mayor, fabrica de productos de ' + familiaActual}}</h1>
</div>
<div class="row message">
  <div class="container-fluid">
    <ngb-alert *ngIf="successMessage" (click)="alertClicked()" type="success" (close)="alertClicked();">
      <i class="fa fa-check-circle fa-3x" aria-hidden="true"></i> {{ successMessage }}
      <button class="actionButton transparentButton" [routerLink]="['/compra/carrito']">VER TODOS</button>
    </ngb-alert>
  </div>
</div>
<div class="container filter">
  <div class="row">
    <div class="col-md-2 col-12">
      <div class="containerSidebarCategorias">
        <div class="row filter__top">
          <p>Vista</p>
        </div>
        <div class="row listadoCategorias">
          <p *ngIf="modoVista === 'Listado'" style="margin-left: 20px; cursor: pointer;" (click)="cambiarVista('Listado')"><strong>Ver todos</strong></p>
          <p *ngIf="modoVista !== 'Listado'" style="margin-left: 20px; cursor: pointer;" (click)="cambiarVista('Listado')">Ver todos</p>
          <p *ngIf="modoVista === 'Paginado'" style="margin-left: 20px; cursor: pointer;" (click)="cambiarVista('Paginado')"><strong>Paginado</strong></p>
          <p *ngIf="modoVista !== 'Paginado'" style="margin-left: 20px; cursor: pointer;" (click)="cambiarVista('Paginado')">Paginado</p>
        </div>
      </div>
      <div class="containerSidebarCategorias">
        <div class="row filter__top">
          <p>Ordenar productos</p>
        </div>
        <div class="row listadoCategorias">
          <p *ngIf="!ordenamiento || (!ordenamiento.startsWith('nombre') && !ordenamiento.startsWith('precio'))" style="margin-left: 20px; cursor: pointer;" (click)="ordenPorDefecto()"><strong>Por defecto</strong></p>
          <p *ngIf="ordenamiento && (ordenamiento.startsWith('nombre') || ordenamiento.startsWith('precio'))" style="margin-left: 20px; cursor: pointer;" (click)="ordenPorDefecto()">Por defecto</p>
          <p *ngIf="ordenamiento && ordenamiento.startsWith('nombre')" style="margin-left: 20px; cursor: pointer;" (click)="ordenPorNombre()"><strong>Nombre</strong></p>
          <p *ngIf="!ordenamiento || !ordenamiento.startsWith('nombre')" style="margin-left: 20px; cursor: pointer;" (click)="ordenPorNombre()">Nombre</p>
          <p *ngIf="ordenamiento && ordenamiento.startsWith('precio') && loginStatus" style="margin-left: 20px; cursor: pointer;" (click)="ordenPorPrecio()"><strong>Precio</strong></p>
          <p *ngIf="(!ordenamiento && loginStatus) || (!ordenamiento.startsWith('precio') && loginStatus)" style="margin-left: 20px; cursor: pointer;" (click)="ordenPorPrecio()">Precio</p>
        </div>
      </div>
      <div class="containerSidebarCategorias">
        <div class="row filter__top">
        </div>
        <div class="listadoCategorias" *ngFor="let cat of id_categoria">
            <p routerLink="{{'/' + cat?.link}}" style="font-weight: bold; cursor: pointer;" [ngClass]="{'actual': num_subcategoria === -1}">{{cat?.texto}}</p>
            <p style="cursor: pointer; margin-left: 20px;" *ngFor="let element of cat.listado_subcategorias; let $index = index" [ngClass]="{'actual': num_subcategoria === $index}" routerLink="{{'/' + element?.link}}" (click)="num_subcategoria = $index">
              <input type="radio" name="subcategory" [checked]="num_subcategoria === $index" style="margin-right: 5px;">
              {{element?.texto}}
          </p>
        </div>
      </div>
      <div class="containerSidebarCategorias">
        <div class="row filter__top">
          <p routerLink="/productos" style="cursor: pointer;">Ver todas las categorías</p>
        </div>
      </div>
    </div>

    <div class="col-md-12 col-lg-10 col-12">
      <div class="w-100 mb-3 banner-category" *ngIf="bannerFamiliaActual && clientOnlyRun">
        <img class="w-100 d-none d-md-block"  [src]="bannerFamiliaActual" [alt]="'Banner '+familiaActual+' Desktop'" title="Sina">
        <img class="w-100 d-block d-md-none"  [src]="bannerFamiliaActual" [alt]="'Banner '+familiaActual+' Mobile'" title="Sina">
      </div>
      <div class="col-md-12 col-lg-10 col-12" *ngIf="mensaje" style="
      display: flex;
      align-items: center;
      flex-direction: column;
      text-align: center;
      height: 300px;
      ">
        <p
        style="
          margin-top: 100px;
          margin-left: auto;
          margin-right: auto;
          font-size: 3em;
          color: #057aff;
          opacity: 0.6;
        "
        >{{mensaje}}</p>
        <img title="Loading..." width="50" height="40" id="loadCat" *ngIf="mensaje === 'Cargando'" style="margin-left: auto; margin-right: auto;" [ngClass]="'lazyload'" data-src="./assets/images/loader/ajax-loader.gif"/>
      </div>

      <div class="containerTopCategorias" *ngIf="mensaje !== 'Cargando' && listaResultados && listaResultados?.length">
        <span style="font-weight: bold;">Ordenar productos:
          <div style="display: inline-block;">
            <button type="button" class="ordenProductos_selector fw-bold" (click)="toggleOrdenarMobile()">
              <p *ngIf="!ordenamiento || (!ordenamiento.startsWith('nombre') && !ordenamiento.startsWith('precio'))">Por defecto&nbsp;</p>
              <p *ngIf="ordenamiento && ordenamiento.startsWith('nombre')">Nombre&nbsp;</p>
              <p *ngIf="ordenamiento && ordenamiento.startsWith('precio') && loginStatus">Precio&nbsp;</p>
              <i *ngIf="!showOrdenarMobile" class="fa fa-caret-down" aria-hidden="true"></i>
              <i *ngIf="showOrdenarMobile" class="fa fa-caret-up" aria-hidden="true"></i>
            </button>
            <ul class="ordenProductos" *ngIf="showOrdenarMobile">
              <li (click)="ordenPorDefecto()" *ngIf="!ordenamiento || (!ordenamiento.startsWith('nombre') && !ordenamiento.startsWith('precio'))">
                <p><strong>Por defecto</strong></p>
                <p class="floatingRight"><strong><i class="fa fa-bullseye" aria-hidden="true"></i></strong></p>
              </li>
              <li (click)="ordenPorDefecto()" *ngIf="ordenamiento && (ordenamiento.startsWith('nombre') || ordenamiento.startsWith('precio'))" >
                <p>Por defecto</p>
                <p class="floatingRight"><i class="fa fa-circle" aria-hidden="true"></i></p>
              </li>
              <li (click)="ordenPorNombre()" *ngIf="ordenamiento && ordenamiento.startsWith('nombre')">
                <p><strong>Nombre</strong></p>
                <p class="floatingRight"><strong><i class="fa fa-bullseye" aria-hidden="true"></i></strong></p>
              </li>
              <li (click)="ordenPorNombre()" *ngIf="!ordenamiento || !ordenamiento.startsWith('nombre')">
                <p>Nombre</p>
                <p class="floatingRight"><i class="fa fa-circle" aria-hidden="true"></i></p>
              </li>
              <li (click)="ordenPorPrecio()" *ngIf="ordenamiento && ordenamiento.startsWith('precio') && loginStatus">
                <p><strong>Precio</strong></p>
                <p class="floatingRight"><strong><i class="fa fa-bullseye" aria-hidden="true"></i></strong></p>
              </li>
              <li (click)="ordenPorPrecio()" *ngIf="(!ordenamiento && loginStatus) || (!ordenamiento.startsWith('precio') && loginStatus)">
                <p>Precio</p>
                <p class="floatingRight"><i class="fa fa-circle" aria-hidden="true"></i></p>
              </li>
            </ul>
          </div>
        </span>
      </div>
      <div class="row resultado" *ngIf="mensaje !== 'Cargando' && listaResultados && listaResultados?.length">
        <div class="resultado__item col-md-6 col-lg-3 col-12" *ngFor="let item of listaResultados | filter : term">
          <app-producto-item [item]="item" [sesion]="loginStatus" [mensaje]="_success"></app-producto-item>
        </div>
      </div>
      <br>
      <div class="row w-100" *ngIf="mensaje !== 'Cargando' && listaResultados && listaResultados?.length">
        <p class="iva__custom__border w-auto">{{iva_usuario}}</p>
        <p *ngIf="show_mensaje_ofertas" style="margin: 10px 0px;">Los productos que se encuentran en oferta, tienen una validez que se limita a la modalidad de contado efectivo, hasta agotar Stock.</p>
      </div>
      <div class="row" *ngIf="mensaje !== 'Cargando' && listaResultados && listaResultados?.length">
          <div class="siguiente" *ngIf="modoVista === 'Paginado'">
              <button (click)="paginado.anterior()">Anterior</button>
              <span>Página {{paginado.pagina}} de {{paginado.maximo}}</span>
              <button (click)="paginado.siguiente()">Siguiente</button>
          </div>
      </div>
    </div>
  </div>
</div>
