<div class="row message">
  <div class="container">
    <ngb-alert *ngIf="successMessage" (click)="alertClicked()" type="success" (close)="alertClicked();">
      <i class="fa fa-check-circle fa-3x" aria-hidden="true"></i> {{ successMessage }}
      <button class="actionButton transparentButton" [routerLink]="['/compra/carrito']">VER TODOS</button>
    </ngb-alert>
  </div>
</div>
<div class="row" style="background: #f8f8f8; margin: 0;">
  <div class="container">
    <div class="row" style="padding: 5px 45px;    padding-bottom: 0;">
      <div class="tab px-0">
        <div class="tab__header">
          <p class="w-auto">{{secciones[transaccion.paso]}}</p>
          <ul>
            <li [class.active]="transaccion.check(0)"
            >Carrito<div class="arrow-pointer-1-up"></div><div class="arrow-pointer-1-down"></div></li>
            <li [class.active]="transaccion.check(1)"
            >Envío y facturación<div class="arrow-pointer-2-up"></div><div class="arrow-pointer-2-down"></div></li>
            <li [class.active]="transaccion.check(2)"
            >Confirmacion</li>
          </ul>
        </div>
        <div class="tab__content">
          <div *ngIf="config.mensajeCompraActivo" class="tab__item">
            <div class="content mensaje__compra">
              <div class="mensaje__compra__titulo">{{config.mensajeCompraTitulo}}</div>
              <div class="mensaje__compra__mensaje">{{config.mensajeCompraMensaje}}</div>
            </div>
          </div>
          <div class="tab__item" *ngIf="transaccion.check(0)">
            <div class="content">
              <div class="row tab__item__header" *ngIf="config.switchComprasActivo">
                <p>Tu código de Cliente es {{facturacion.datos.codigoCliente}}</p>
                <button type="button" class="w-auto" *ngIf="(representado && carrito.subtotal > 0) || carrito.subtotal >= config.montoMinimo" (click)="finalizarCompra()">Siguiente &#62;</button>
                <span class="w-auto">{{secciones[transaccion.paso]}}</span>
              </div>
              <div class="row" *ngIf="config.switchComprasActivo">
                <div class="col-12 col-lg-4 show__mobile">
                  <div class="row carrito__total__mobile" [ngClass]="{'borde__animado' : (!cupon.envio_gratis && carrito.subtotal < config.montoEnvioGratis), 'envio' : carrito.subtotal >= config.montoEnvioGratis}" style="margin-bottom: 10px; position: relative;">
                    <div style="position: absolute; bottom: 3px; right: 8px; font-size: 0.8em;" class="compra__mensaje__envios w-auto"><a [href]="environment.APP_WEB_BASE+'envios'" target="_blank" rel="noopener noreferrer">Conocé más sobre envíos <i class="fa fa-external-link"></i></a></div>
                    <div class="envio__total" style="font-weight: normal; color: #057aff;" *ngIf="!cupon.envio_gratis && carrito.subtotal < config.montoEnvioGratis">
                      <p>TE FALTAN $ <strong style="padding-bottom: 3px">{{formatMoney(config.montoEnvioGratis - carrito.subtotal)}}</strong> PARA TENER EL <strong style="padding-bottom: 3px">ENVÍO SIN CARGO</strong></p>
                    </div>
                    <div class="envio__total text-center" style="font-weight: normal;" *ngIf="cupon.envio_gratis || carrito.subtotal >= config.montoEnvioGratis">
                      <p>ENVÍO SIN CARGO &#10004; <span *ngIf="isSectorInterior">hasta transporte situado en CABA, Bs. As.</span></p>
                    </div>
                  </div>
                  <div class="row carrito__total__mobile">
                    <div style="padding: 0px;">
                      <div class="cupon-container">
                        <input [(ngModel)]="cupon.codigo" (keydown)="enterCupon($event)" type="text" class="cupon-input" id="codigo-cupon" autocomplete="off" placeholder="Ingresar cupon"/>
                        <label [ngClass]="{'filled' : cupon.codigo.length}" class="label-cupon" for="codigo-cupon"><i class="fa fa-gift"></i> Tengo un cupon</label>
                        <button (click)="submitCupon()" class="cupon-submit" type="submit">Aplicar</button>
                      </div>
                      <!-- Loader -->
                      <div *ngIf="cuponLoading" style="width: 50px; height: 50px; margin: 10px auto;">
                        <div class="sina__loader"></div>
                      </div>
                      <p style="margin-top: 10px; color: #057aff; text-align: center;" *ngIf="!cuponLoading && cuponValido && cuponSuccessMsg">{{ cuponSuccessMsg }}</p>
                      <p style="margin-top: 10px; color: red; text-align: center;" *ngIf="!cuponLoading && !cuponValido && cuponErrorMsg">{{ cuponErrorMsg }}</p>
                    </div>
                  </div>
                  <div class="row carrito__total__mobile" style="margin-top: 10px !important;">
                    <div style="margin-top: 10px; padding-left: 10px;" class="envio__total envio__total__confirmar">
                      <p>SUBTOTAL</p>
                      <h2 style="bottom: -4px; font-size: 24px;">$ {{updateTotal(carrito.subtotal)}}</h2>
                    </div>
                    <div class="envio__total envio__total__confirmar" style="margin-top: 20px; padding-left: 10px; padding-top: 16px;" *ngIf="cupon && cuponValido && showDescuentoCupon && descuentoCupon">
                      <p>DESCUENTOS</p>
                      <h2 style="bottom: -4px; font-size: 24px;">- $ {{updateTotal(descuentoCupon)}}</h2>
                    </div>
                    <p style="padding-right: 20px; text-align: right; font-weight: initial;">{{iva_usuario.includes('NO INCLUYE') ? '+ I.V.A.' : (iva_usuario.includes('INCLUYE') ? 'I.V.A. INCLUIDO' : (iva_usuario.includes('FINAL') ? 'FINAL' : 'SUJETO A SU CONDICIÓN HABITUAL'))}}</p>
                    <div class="row compra__confirm m-0 justify-content-center" style="margin-top: 5px;">
                      <button type="button" [disabled]="!(((representado && carrito.subtotal > 0) || carrito.subtotal >= config.montoMinimo) && !showAgregado)" class="actionButton" (click)="finalizarCompra()">IR AL PASO FINAL</button>
                      <div>
                        <p style="color: #057afe;">En el próximo paso seleccionará el método de entrega correspondiente</p>
                      </div>
                      <div *ngIf="carrito.ventaSoloPorPedido" class="mt-2">
                        <p>Tu pedido contiene artículos de venta por encargue (de los cuales no hay stock físico actualmente)
                          y estará listo para despacharse cuando ingresen los productos a depósito</p>
                      </div>
                      <div *ngIf="!representado && carrito.subtotal < config.montoMinimo && !showAgregado">
                        <p style="margin-top: 30px; padding: 0px 15px; color: red;">La compra actual no supera el monto mínimo de $ {{formatMoney(config.montoMinimo)}}</p>
                      </div>
                      <p *ngIf="!representado && carrito.subtotal >= config.montoMinimo && carrito.subtotal < config.montoEnvio && !showAgregado" style="margin-top: 15px;">*Envíos únicamente a partir de ${{formatMoney(config.montoEnvio)}}<br>Cualquier consulta contactate con nosotros.</p>
                      <button *ngIf="carrito.subtotal > 0" type="button" class="whiteButton" style="margin-top: 25px; padding: 4px 10px;" (click)="showAgregado = !showAgregado">Es un agregado</button>
                      <div *ngIf="showAgregado" class="div__agregado">
                        <p style="padding-bottom: 10px;">Ingrese el Nro del pedido al que desea agregar los productos del carrito actual:</p>
                        <input class="form-control" type="number" min="1" name="idPedido" aria-label="ID Pedido" id="idPedido" placeholder="Nro del pedido" (keydown)="enterBusquedaAgregado($event)" [(ngModel)]="idPedidoAgregado">
                        <button class="busqueda__agregado" type="button" title="Buscar"><i class="fa fa-search" aria-hidden="true" (click)="finalizarCompraAgregado()"></i></button>
                        <p *ngIf="showErrorAgregado" style="padding-top: 10px; color: red; text-align: center;">{{responseErrorAgregado}}</p>
                      </div>
                    </div>
                  </div>
                  <div class="row px-0" *ngIf="productosCarousel.length > 0"> <!-- Carousel mobile -->
                    <ngb-carousel
                      #ngcarouselcomprauno
                      id="carousel_carrito_uno"
                      class="carousel-fade px-0"
                      [ngClass]="{ 'ocultar-controles': autoSlideEventCount > 0 }"
                      (slide)="slideActivate($event)"
                      (swipeleft)="slideSwipeLeft($event)"
                      (swiperight)="slideSwipeRight($event)"
                      (mouseenter)="slideMouseenter($event)"
                      (mouseleave)="slideMouseleave($event)"
                      [showNavigationArrows]="true"
                      [showNavigationIndicators]="true"
                      [animation]="false"
                      [interval]="config.carouselCompraInterval"
                      [keyboard]="true"
                      [pauseOnHover]="true"
                      [pauseOnFocus]="false"
                      [wrap]="true"
                    >
                      <ng-template ngbSlide *ngFor="let item of productosCarousel; let $index = index" [id]="'pos'+$index">
                        <div class="resultado__item col-8 custom__col-md-15 mx-auto">
                          <app-producto-item [item]="item" [sesion]="loginStatus" [mensaje]="_success" [itemcarousel]="true"></app-producto-item>
                        </div>
                      </ng-template>
                    </ngb-carousel>
                  </div>
                  <!--div class="row carrito__busqueda">
                    <h3>¿TENÉS UN CUPÓN?</h3>
                    <input type="number" value="1">
                    <button class="whiteButton">ACEPTAR</button>
                  </div-->
                </div>
                <div class="col-12 col-lg-8">
                  <div #inputCantidad class="carrito">
                    <div class="carrito__loading" *ngIf="carritoLoading">
                      <div class="lds-ellipsis"><div></div><div></div><div></div><div></div>
                      </div>
                    </div>
                    <div class="row carrito__titulo">
                      <p style="font-weight: bold; padding: 0; margin: 0px 0px 10px;">COMPRA ACTUAL</p>
                      <!-- <p style="font-size: 10px;margin-top: -8px;">{{carrito.hora}}</p> -->
                    </div>
                    <div class="row carrito__header">
                      <div class="custom__col-1">
                        <div class="border-bottom">
                          <p>Cantidad</p>
                        </div>
                      </div>
                      <div class="custom__col-2 mobile_padding_extra" style="margin-top: 0;">
                        <div class="border-bottom">
                          <p>Descripcion</p>
                        </div>
                      </div>
                      <div class="custom__col-3 mobile_padding_extra">
                        <div class="border-bottom">
                          <p>Precio U.</p>
                        </div>
                      </div>
                      <div class="custom__col-4">
                        <div class="border-bottom">
                          <p>Subtotal</p>
                        </div>
                      </div>
                      <div class="custom__col-5 w-auto"></div>
                    </div>
                    <div class="row carrito__content" *ngFor="let compra of carrito.lista">
                      <div class="custom__col-1">
                        <input *ngIf="compra.cantPack === '1'" name="cantidad" aria-label="Cantidad" type="number" min="1" max="999999" (input)="updateValue()" [(ngModel)]="compra.cantidad" value="{{compra.cantidad}}" (keyup)="revisarCantidad($event)">
                        <select *ngIf="compra.cantPack !== '1'" name="cantidad" aria-label="Cantidad" (input)="updateValueDelayed()" [(ngModel)]="compra.cantidad">
                          <option *ngFor="let cant of compra.arrayCants" [value]="cant">{{cant}}</option>
                        </select>
                      </div>
                      <div class="custom__col-2">
                        <img width="16" height="16" *ngIf="compra.oferta == true" [ngClass]="'lazyload'" data-src="./assets/images/iconos/oferta.webp" alt="" style="float: left; margin-right: 4px;">
                        <img width="60" height="40"  style="max-width: 100%; height: auto;" [ngClass]="'lazyload'" [attr.data-src]="compra.urlImagen" onerror="this.src='/assets/images/producto/picGenerica.webp'" alt="">
                        {{compra.descripcion + ' - ' + compra.descripcionAdicional}}
                      </div>
                      <div class="custom__col-3">
                        <p>${{corregirPrecio(compra.precio)}}</p>
                      </div>
                      <div class="custom__col-4">
                        <p>${{updatePrecio(compra.precio, compra.cantidad)}}</p>
                      </div>
                      <div class="custom__col-5 w-auto">
                        <button type="button" title="Eliminar" class="eliminar" (click)="removeCompraItem(compra)">
                          <img width="18" height="18" class="eliminar--initial" [ngClass]="'lazyload'" data-src="./assets/images/iconos/Eliminar-producto.png" alt="">
                          <img width="18" height="18" class="eliminar--hover" [ngClass]="'lazyload'" data-src="./assets/images/iconos/Eliminar-producto-hover.png" alt="">
                        </button>
                      </div>
                    </div>
                    <div class="carrito__content__mobile" *ngFor="let compra of carrito.lista">
                      <div class="row">
                        <div class="custom__col-1">
                          <input *ngIf="compra.cantPack === '1'" name="cantidad" aria-label="Cantidad" type="number" min="1" max="999999" (input)="updateValue()" [(ngModel)]="compra.cantidad" value="{{compra.cantidad}}" (keyup)="revisarCantidad($event)">
                          <select *ngIf="compra.cantPack !== '1'" name="cantidad" aria-label="Cantidad" (input)="updateValueDelayed()" [(ngModel)]="compra.cantidad">
                            <option *ngFor="let cant of compra.arrayCants" [value]="cant">{{cant}}</option>
                          </select>
                        </div>
                        <div class="custom__col-2">
                          <img width="30" height="20" class="product-image" style="max-width: 100%; height: auto;" [ngClass]="'lazyload'" [attr.data-src]="compra.urlImagen" onerror="this.src='/assets/images/producto/picGenerica.webp'" alt="">

                          <img width="16" height="16" *ngIf="compra.oferta == true" [ngClass]="'lazyload'" data-src="./assets/images/iconos/oferta.webp" alt="" style="float: left; margin-right: 4px;">{{compra.descripcion}}
                        </div>
                        <div class="custom__col-3">
                          <p>${{corregirPrecio(compra.precio)}}</p>
                        </div>
                        <div class="custom__col-4">
                          <p>${{updatePrecio(compra.precio, compra.cantidad)}}</p>
                        </div>
                        <div class="custom__col-5 w-auto">
                          <button type="button" title="Eliminar" class="eliminar" (click)="removeCompraItem(compra)">
                            <img width="18" height="18" class="eliminar--initial" [ngClass]="'lazyload'" data-src="./assets/images/iconos/Eliminar-producto.png" alt="">
                            <img width="18" height="18" class="eliminar--hover" [ngClass]="'lazyload'" data-src="./assets/images/iconos/Eliminar-producto-hover.png" alt="">
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row" style="padding: 20px 20px;">
                    <p style="font-weight: 14px;" class="p-0">Los productos que se encuentran en oferta, tienen una validez que se limita a la modalidad de contado efectivo, hasta agotar Stock.</p>
                    <p style="font-weight: 14px;" class="p-0">Estimado cliente, recuerde que los productos están sujetos a la disponibilidad de stock del momento, pudiendo sufrir modificaciones en sus cantidades solicitadas.</p>
                    <div class="row buscador__general p-0">
                      <div class="col-12 col-md-10">
                        <div class="input-group" style="width: 100%;">
                          <!--span class="input-group-btn">
                            <button class="btn btn-secondary" type="button">AGREGAR</button>
                          </span-->
                          <!--(keyup)="buscarPalabra($event)"-->
                          <input [(ngModel)]="texto_busqueda" (keydown)="enterBusqueda($event)" name="texto_busqueda" aria-label="Texto Busqueda" class="form-control" type="text" placeholder="Buscar más productos para agregar a mi compra">
                          <div class="busqueda" *ngIf="ResultadoBusqueda?.length > 0">
                            <ul>
                              <li *ngFor="let item of ResultadoBusqueda" (click)="cerrarBusqueda(item)">
                                <img width="150" height="110" class="busqueda__icono" style="max-width: 100%; height: auto;" [ngClass]="'lazyload'" [attr.data-src]="item.urlImagen" onerror="this.src='/assets/images/producto/picGenerica.webp'" alt="">
                                <p class="busqueda__titulo">{{item.nombreMostrar || item.titulo}}</p>
                                <p class="busqueda__subtitulo">{{item.tituloAdicional}}</p>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div class="col-12 col-md-2">
                        <button type="button" class="whiteButton" (click)="buscarTexto()">BUSCAR</button>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-md-4 show__web">
                  <div class="row carrito__total" [ngClass]="{'borde__animado' : (!cupon.envio_gratis && carrito.subtotal < config.montoEnvioGratis), 'envio' : carrito.subtotal >= config.montoEnvioGratis}" style="margin-bottom: 10px; position: relative;">
                    <div style="position: absolute; bottom: 3px; right: 8px; font-size: 0.8em;" class="compra__mensaje__envios w-auto"><a [href]="environment.APP_WEB_BASE+'envios'" target="_blank" rel="noopener noreferrer">Conocé más sobre envíos <i class="fa fa-external-link"></i></a></div>
                    <div class="envio__total" style="font-weight: normal; color: #057aff;" *ngIf="!cupon.envio_gratis && carrito.subtotal < config.montoEnvioGratis">
                      <p>TE FALTAN $ <strong style="padding-bottom: 3px">{{formatMoney(config.montoEnvioGratis - carrito.subtotal)}}</strong> PARA TENER EL <strong style="padding-bottom: 3px">ENVÍO SIN CARGO</strong></p>
                    </div>
                    <div class="envio__total text-center" style="font-weight: normal;" *ngIf="cupon.envio_gratis || carrito.subtotal >= config.montoEnvioGratis">
                      <p>ENVÍO SIN CARGO &#10004; <span *ngIf="isSectorInterior">hasta transporte situado en CABA, Bs. As.</span></p>
                    </div>
                  </div>
                  <div class="row carrito__total">
                    <div style="padding: 0px;">
                      <div class="cupon-container">
                        <input [(ngModel)]="cupon.codigo" (keydown)="enterCupon($event)" type="text" class="cupon-input" id="codigo-cupon" autocomplete="off" placeholder="Ingresar cupon"/>
                        <label [ngClass]="{'filled' : cupon.codigo.length}" class="label-cupon" for="codigo-cupon"><i class="fa fa-gift"></i> Tengo un cupon</label>
                        <button (click)="submitCupon()" class="cupon-submit" type="submit">Aplicar</button>
                      </div>
                      <!-- Loader -->
                      <div *ngIf="cuponLoading" style="width: 50px; height: 50px; margin: 10px auto;">
                        <div class="sina__loader"></div>
                      </div>
                      <p style="margin-top: 10px; color: #057aff; text-align: center;" *ngIf="!cuponLoading && cuponValido && cuponSuccessMsg">{{ cuponSuccessMsg }}</p>
                      <p style="margin-top: 10px; color: red; text-align: center;" *ngIf="!cuponLoading && !cuponValido && cuponErrorMsg">{{ cuponErrorMsg }}</p>
                    </div>
                  </div>
                  <div class="row carrito__total">
                    <div style="margin-top: 20px" class="envio__total envio__total__confirmar">
                      <p>SUBTOTAL</p>
                      <h2>$ {{updateTotal(carrito.subtotal)}}</h2>
                    </div>
                    <div style="padding-top: 16px;" class="envio__total envio__total__confirmar" *ngIf="cupon && cuponValido && showDescuentoCupon && descuentoCupon">
                      <p>DESCUENTOS</p>
                      <h2 style="bottom: -4px; font-size: 24px;">- $ {{updateTotal(descuentoCupon)}}</h2>
                    </div>
                    <span style="text-align: right; font-weight: initial;">{{iva_usuario.includes('NO INCLUYE') ? '+ I.V.A.' : (iva_usuario.includes('INCLUYE') ? 'I.V.A. INCLUIDO' : (iva_usuario.includes('FINAL') ? 'FINAL' : 'SUJETO A SU CONDICIÓN HABITUAL'))}}</span>
                    <button type="button" [disabled]="!(((representado && carrito.subtotal > 0) || carrito.subtotal >= config.montoMinimo) && !showAgregado)" class="actionButton" (click)="finalizarCompra()">IR AL PASO FINAL</button>
                    <div class="mt-2">
                      <p style="color: #057afe;">En el próximo paso seleccionará el método de entrega correspondiente</p>
                    </div>
                    <div *ngIf="carrito.ventaSoloPorPedido" class="mt-2">
                      <p>Tu pedido contiene artículos de venta por encargue (de los cuales no hay stock físico actualmente)
                        y estará listo para despacharse cuando ingresen los productos a depósito</p>
                    </div>
                    <div *ngIf="!representado && carrito.subtotal < config.montoMinimo && !showAgregado">
                      <p style="margin-top: 30px; padding: 0px 30px; color: red;">La compra actual no supera el monto mínimo de $ {{formatMoney(config.montoMinimo)}}</p>
                    </div>
                    <p *ngIf="!representado && carrito.subtotal >= config.montoMinimo && carrito.subtotal < config.montoEnvio && !showAgregado" style="margin-top: 15px;">*Envíos únicamente a partir de ${{formatMoney(config.montoEnvio)}}<br>Cualquier consulta contactate con nosotros.</p>
                    <button *ngIf="carrito.subtotal > 0" type="button" class="whiteButton" style="margin-top: 35px; padding: 4px 20px;" (click)="showAgregado = !showAgregado">Es un agregado</button>
                    <div *ngIf="showAgregado" class="div__agregado">
                      <p style="padding-bottom: 10px;">Ingrese el Nro del pedido al que desea agregar los productos del carrito actual:</p>
                      <input class="form-control" type="number" min="1" name="idPedido" aria-label="ID Pedido" id="idPedido" placeholder="Nro del pedido" (keydown)="enterBusquedaAgregado($event)" [(ngModel)]="idPedidoAgregado">
                      <button class="busqueda__agregado" type="button" title="Buscar"><i class="fa fa-search" aria-hidden="true" (click)="finalizarCompraAgregado()"></i></button>
                      <p *ngIf="showErrorAgregado" style="padding-top: 10px; color: red; text-align: center;">{{responseErrorAgregado}}</p>
                    </div>
                  </div>
                   <div class="row px-0 ms-4" *ngIf= "productosCarousel.length > 0"> <!-- Carousel desktop -->
                    <ngb-carousel
                    #ngcarouselcomprados
                    id="carousel_carrito_dos"
                    class="carousel-fade px-0"
                    [ngClass]="{ 'ocultar-controles': autoSlideEventCount > 0 }"
                    (slide)="slideActivate($event)"
                    (mouseenter)="slideMouseenter($event)"
                    (mouseleave)="slideMouseleave($event)"
                    [showNavigationArrows]="true"
                    [showNavigationIndicators]="true"
                    [animation]="false"
                    [interval]="config.carouselCompraInterval"
                    [keyboard]="true"
                    [pauseOnHover]="true"
                    [pauseOnFocus]="false"
                    [wrap]="true"
                    >
                      <ng-template ngbSlide *ngFor="let item of productosCarousel; let $index = index" [id]="'pos'+$index">
                        <div class="resultado__item col-8 custom__col-md-15 mx-auto">
                          <app-producto-item [item]="item" [sesion]="loginStatus" [mensaje]="_success" [itemcarousel]="true"></app-producto-item>
                        </div>
                      </ng-template>
                    </ngb-carousel>
                  </div>
                  <!--div class="row carrito__busqueda">
                    <h3>¿TENÉS UN CUPÓN?</h3>
                    <input type="number" value="1">
                    <button class="whiteButton">ACEPTAR</button>
                  </div-->
                </div>
              </div>
              <div *ngIf="!config.switchComprasActivo">
                Las compras se encuentran suspendidas temporalmente.
              </div>
            </div>
          </div>
          <div class="tab__item" *ngIf="transaccion.check(1)">
            <div class="content">
              <div class="row tab__item__header">
                <p>Tu código de Cliente es {{facturacion.datos.codigoCliente}}</p>
                <button type="button" class="w-auto" (click)="transaccion.cambio(0); checkCarritoInit(0);"> &#60; Atrás</button>
                <span class="w-auto">{{secciones[transaccion.paso]}}</span>
              </div>
              <div class="row">
                <div class="col-12 col-md-4">
                  <div class="row envio__seccion">
                    <div class="envio__seccion__header ps-0">
                      <p class="p-0">FORMA DE ENTREGA</p>
                    </div>
                    <div *ngIf="datosCompra.agregado === '0'" class="row">
                      <div *ngIf="representado || carrito.subtotal >= config.montoEnvio || cupon.envio_gratis" class="col-6 envio__seccion__opcion" (click)="selectedForma('0')">
                        <img width="66" height="50" [ngClass]="'lazyload'" data-src="./assets/images/iconos/camion.webp" alt="">
                        <p>Envío</p>
                        <input type="radio" id="test1" name="test1" aria-label="Opcion Envio" value="0" [ngModel]="datosCompra.entrega" checked>
                        <label for="test1"></label>
                      </div>
                      <div class="col-6 envio__seccion__opcion" (click)="selectedForma('1')">
                        <img width="44" height="50" [ngClass]="'lazyload'" data-src="./assets/images/iconos/retiro.webp" alt="">
                        <p>Retiro en tienda</p>
                        <input type="radio" id="test2" name="test2" aria-label="Opcion Retiro en tienda" value="1" [ngModel]="datosCompra.entrega">
                        <label for="test2"></label>
                      </div>
                    </div>
                    <div *ngIf="datosCompra.agregado === '1'" class="row">
                      <div class="envio__seccion__opcion" style="cursor: unset;">
                        <p style="padding: 0px 30px; text-align: left;">Agregado al pedido ID #{{idPedidoAgregado}} realizado el día {{responseSuccessAgregado.fechaPedido}}</p>
                      </div>
                    </div>
                  </div>
                  <div class="envio__seccion--editable" *ngIf="datosCompra.entrega === '0' && datosCompra.agregado === '0'">
                    <div class="envio__seccion__header--editable ps-0">
                      <p class="p-0">DATOS DE ENVÍO</p>
                      <button type="button" title="Editar" (click)="toggleEditingEnvio()">
                        <img width="20" height="20" [ngClass]="'lazyload'" data-src="./assets/images/iconos/lapiz.webp" alt="">
                      </button>
                    </div>
                    <div class="envio__seccion__content transporte error" *ngIf="!editingEnvio && !datosEnvio_flag">
                      <p>Debes cargar los datos de envio</p>
                    </div>
                    <div class="envio__seccion__content ps-0" *ngIf="!editingEnvio">
                      <p>{{datos_envio.domicilio_direccion}}</p>
                      <p>{{datos_envio.domicilio_ciudad}} ({{datos_envio.domicilio_codigo_postal}})</p>
                      <p>{{entrega.provincia.array[datos_envio.domicilio_provincia]}}</p>
                      <p>{{datos_envio.telefono}}</p>
                      <p>{{datos_envio.cod_transporte ? entrega.transporte.array[datos_envio.cod_transporte] : "Debe seleccionar un medio de transporte"}}</p>
                    </div>
                    <div class="envio__seccion__content edit" *ngIf="editingEnvio">
                      <div class="form-group row" *ngIf="!cargarTransporte">
                        <label for="example-text-input" class="col-4 col-form-label">
                          Domicilio
                        </label>
                        <div class="col-8">
                          <input class="form-control" type="text" name="calle" aria-label="Domicilio Calle" [(ngModel)]="datos_envio.domicilio_direccion">
                        </div>
                      </div>
                      <div class="form-group row" *ngIf="!cargarTransporte">
                        <label for="example-text-input" class="col-4 col-form-label">
                          Localidad
                        </label>
                        <div class="col-8">
                          <input class="form-control" type="text" name="localidad" aria-label="Domicilio Localidad" [(ngModel)]="datos_envio.domicilio_ciudad">
                        </div>
                      </div>
                      <div class="form-group row" *ngIf="!cargarTransporte">
                        <label for="example-text-input" class="col-4 col-form-label">
                          Código Postal
                        </label>
                        <div class="col-8">
                          <input class="form-control" type="text" maxlength="8" name="cod_postal" aria-label="Domicilio Codigo Postal" [(ngModel)]="datos_envio.domicilio_codigo_postal">
                        </div>
                      </div>
                      <div class="form-group row" *ngIf="!cargarTransporte">
                        <label for="example-text-input" class="col-4 col-form-label">
                          Provincia
                        </label>
                        <div class="col-8">
                          <ng-select #provincia style="height: 32px; margin: 6px 0px;"
                            [clearable]="true"
                            (change)="refreshProvincia($event)"
                            ng-model="datos_envio.domicilio_provincia"
                            placeholder="Provincia">
                            <ng-option *ngFor="let p of entrega.provincia.lista" [value]="p.id">
                              {{p.text}}
                            </ng-option>
                          </ng-select>
                        </div>
                      </div>
                      <div class="form-group row" *ngIf="!cargarTransporte">
                        <label for="example-text-input" class="col-4 col-form-label">
                          Telefono
                        </label>
                        <div class="col-8">
                          <input class="form-control" type="text" name="telefono" aria-label="Telefono" [(ngModel)]="datos_envio.telefono">
                        </div>
                      </div>
                      <div class="form-group row" *ngIf="!cargarTransporte && false">
                        <label for="example-text-input" class="col-4 col-form-label">
                          Días de entrega
                        </label>
                        <div class="col-8" style="padding-top: 6px;">
                          <div class="emergencia">
                            Lunes
                            <input type="checkbox" [(ngModel)]="isChecked.entrega_lunes" aria-label="Check Lunes">
                            <span class="checkmark" (click)="clicked('entrega_lunes')"></span>
                          </div>
                          <div class="emergencia">
                            Martes
                            <input type="checkbox" [(ngModel)]="isChecked.entrega_martes" aria-label="Check Martes">
                            <span class="checkmark" (click)="clicked('entrega_martes')"></span>
                          </div>
                          <div class="emergencia">
                            Miércoles
                            <input type="checkbox" [(ngModel)]="isChecked.entrega_miercoles" aria-label="Check Miercoles">
                            <span class="checkmark" (click)="clicked('entrega_miercoles')"></span>
                          </div>
                          <div class="emergencia">
                            Jueves
                            <input type="checkbox" [(ngModel)]="isChecked.entrega_jueves" aria-label="Check Jueves">
                            <span class="checkmark" (click)="clicked('entrega_jueves')"></span>
                          </div>
                          <div class="emergencia">
                            Viernes
                            <input type="checkbox" [(ngModel)]="isChecked.entrega_viernes" aria-label="Check Viernes">
                            <span class="checkmark" (click)="clicked('entrega_viernes')"></span>
                          </div>
                          <div class="emergencia">
                            Sábado
                            <input type="checkbox" [(ngModel)]="isChecked.entrega_sabado" aria-label="Check Sabado">
                            <span class="checkmark" (click)="clicked('entrega_sabado')"></span>
                          </div>
                        </div>
                      </div>
                      <div class="form-group row" *ngIf="!cargarTransporte  && false">
                        <label for="example-text-input" class="col-4 col-form-label">
                          Horarios
                        </label>
                        <div class="col-8">
                          <input class="form-control" type="text" name="horario" aria-label="Horario Entrega" [(ngModel)]="datos_envio.horario_entrega">
                        </div>
                      </div>
                      <div class="form-group row">
                        <label for="example-text-input" class="col-4 col-form-label">
                          Transporte
                        </label>
                        <div class="col-8">
                          <ng-select #transporte style="height: 32px; margin: 6px 0px;"
                            [clearable]="true"
                            (change)="refreshTransporte($event)"
                            ng-model="datos_envio.cod_transporte"
                            placeholder="Medio de transporte">
                            <ng-option *ngFor="let t of entrega.transporte.lista" [value]="t.id">
                              {{t.text}}
                            </ng-option>
                          </ng-select>
                        </div>
                      </div>
                      <div class="form-group row" *ngIf="cargarTransporte">
                        <label for="example-text-input" class="col-4 col-form-label">
                          Nombre
                        </label>
                        <div class="col-8">
                          <input class="form-control" type="text" [(ngModel)]="datostransporte.form.nombreTransporte" aria-label="Nombre Transporte">
                        </div>
                      </div>
                      <div class="form-group row" *ngIf="cargarTransporte">
                        <label for="example-text-input" class="col-4 col-form-label">
                          Teléfono
                        </label>
                        <div class="col-8">
                          <input class="form-control" type="text" [(ngModel)]="datostransporte.form.telefonoTransporte" aria-label="Telefono Transporte">
                        </div>
                      </div>
                      <div class="form-group row" *ngIf="cargarTransporte">
                        <label for="example-text-input" class="col-4 col-form-label">
                          Dirección en Buenos Aires
                        </label>
                        <div class="col-8">
                          <input class="form-control" type="text" [(ngModel)]="datostransporte.form.domicilioTransporte" aria-label="Domicilio Transporte">
                        </div>
                      </div>
                      <div class="row" *ngIf="datos_envio_error !== ''">
                        <p style="color: red;">{{datos_envio_error}}</p>
                      </div>
                      <div class="row" style="text-align:right; padding: 15px;">
                        <button class="whiteButton" (click)="addTransporte()">{{cargarTransporte ? "CONFIRMAR TRANSPORTE" : "GUARDAR"}}</button>
                      </div>
                    </div>
                    <div class="envio__seccion__content ps-0"><p><i>Importante: Los días y horarios de entrega serán programados según el recorrido logístico, pudiendo sufrir modificaciones.</i></p></div>
                  </div>
                  <div class="envio__seccion" *ngIf="datosCompra.entrega === '1' && datosCompra.agregado === '0'">
                    <div class="row envio__seccion__header ps-0">
                      <p class="p-0">DATOS DEL RETIRO</p>
                    </div>
                    <div class="envio__seccion__content retiro"
                      style="max-width: 360px;
                        width: 100%;
                        margin: 0;
                        padding: 0 5px;"
                    >
                      <p style="font-weight: bold; font-size: 15px; padding-bottom: 5px;">Día y horario</p>
                      <div class="form-group d-flex w-100 gap-2">
                        <!-- <div class="horario w-100">
                          <input required [ngModel]="retiroHora | date:'yyyy-MM-dd'" (ngModelChange)="fechaUpdate($event)" type="date" [min]="hoyFormatted" name="retiroHora" aria-label="Fecha Retiro" onkeydown="return false"/>
                        </div> -->
                        <mat-form-field>
                          <input
                            style="width: 140px; font-size: 1.8em; padding-bottom: 5px;"
                            matInput
                            [matDatepicker]="picker"
                            [min]="hoyCero"
                            [matDatepickerFilter]="fechasFiltro"
                            [value]="retiroHoraDate"
                            (dateChange)="fechaUpdate($event)"
                            title="Día de retiro"
                            disabled>
                          <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                          <mat-datepicker #picker disabled="false"></mat-datepicker>
                        </mat-form-field>
                        <!-- <br> -->
                        <div class="horario">
                          <div class="col__2 w-100" style="padding-left: 0px;">
                            <select [ngModel]="inputHora" (ngModelChange)="fechaUpdate1($event)" name="retiroHora1" aria-label="Hora Retiro">
                              <option value="08" [disabled]="isHorarioDisabled('08')">De 8 a 9 hs</option>
                              <option value="09" [disabled]="isHorarioDisabled('09')">De 9 a 10 hs</option>
                              <option value="10" [disabled]="isHorarioDisabled('10')">De 10 a 11 hs</option>
                              <option value="11" [disabled]="isHorarioDisabled('11')">De 11 a 12 hs</option>
                              <!-- <option value="12" disabled>De 12 a 13 hs</option> -->
                              <!-- <option value="13" disabled>De 13 a 14 hs</option> -->
                              <option value="14" [disabled]="isHorarioDisabled('14') || dia === 'Sábado'">De 14 a 15 hs</option>
                              <option value="15" [disabled]="isHorarioDisabled('15') || dia === 'Sábado'">De 15 a 16 hs</option>
                              <option value="16" [disabled]="isHorarioDisabled('16') || dia === 'Sábado'">De 16 a 17 hs</option>
                            </select>
                          </div>
                          <!-- <div class="col__1"><p style="font-size: 14px; text-align: left;">Hora</p></div>
                          <div class="col__2 w-100" style="padding-left: 0px;">
                            <input [ngModel]="inputHora" (ngModelChange)="fechaUpdate1($event)" name="retiroHora1" type="number" min="0" max="23" aria-label="Hora Retiro"/>
                          </div>
                          <br>
                          <div class="col__1"><p style="font-size: 14px; text-align: left;">Minutos</p></div>
                          <div class="col__2" style="padding-right: 0px;">
                            {# <input [ngModel]="inputMinuto" (ngModelChange)="fechaUpdate2($event)" name="retiroHora2"/> #}
                            <div class="btn-group" role="group" aria-label="Minutos">
                              <button type="button" class="btn btn-minuto" [ngClass]="{'selected' : inputMinuto === '00'}" (click)="fechaUpdate2('00')" name="retiroHora2">00</button>
                              <button type="button" class="btn btn-minuto" [ngClass]="{'selected' : inputMinuto === '15'}" (click)="fechaUpdate2('15')" name="retiroHora3">15</button>
                              <button type="button" class="btn btn-minuto" [ngClass]="{'selected' : inputMinuto === '30'}" (click)="fechaUpdate2('30')" name="retiroHora4">30</button>
                              <button type="button" class="btn btn-minuto" [ngClass]="{'selected' : inputMinuto === '45'}" (click)="fechaUpdate2('45')" name="retiroHora5">45</button>
                            </div>
                          </div> -->
                        </div>
                      </div>
                      <div class="form-group p-0" style="margin: 0px; width: 100%; border: 1px solid black;">
                        <p tabindex="1" #mensaje_error_retiro style="color: red; margin: 10px; font-weight: bold;" class="p-0" *ngIf="flagHoraPasada">La fecha/horario seleccionados ya pasaron!</p>
                        <p tabindex="1" #mensaje_error_retiro style="color: red; margin: 10px; font-weight: bold;" class="p-0" *ngIf="dia === 'Domingo'">No es posible el retiro los días Domingo</p>
                        <p tabindex="1" #mensaje_error_retiro style="color: red; margin: 10px; font-weight: bold;" class="p-0" *ngIf="!retiro && dia === 'Sábado'">Sábados: horario de retiro es de 08 a 12hs</p>
                        <p tabindex="1" #mensaje_error_retiro style="color: red; margin: 10px; font-weight: bold;" class="p-0" *ngIf="!retiro && dia !== 'Domingo' && dia !== 'Sábado'">Lunes a Viernes: horario de retiro es de 08 a 12hs y de 14 a 17hs.<br>Sábados: horario de retiro es de 08 a 12hs</p>
                        <p style="color: #057aff; margin: 10px; font-weight: bold;" class="p-0" *ngIf="retiro && dia !== 'Domingo'">Retirás el {{dia}} {{inputFecha}} de {{+inputHora}} a {{+inputHora + 1}} hs</p>
                      </div>
                      <!-- <hr>
                      <div>
                        <p style="margin-bottom: 10px;">DEBUG INFO:</p>
                        <pre>{{ debugInfo }}</pre>
                      </div>
                      <hr> -->
                      <p style="font-weight: bold; font-size: 14px; padding-top: 10px; padding-bottom: 10px;">Dirección</p>
                      <div class="form-group row compra__info__direccion">
                        <div class="col-12">
                          <p class="mapa__container"><a href="https://g.page/Sina-messina-hnos?share" target="_blank" rel="noopener noreferrer">Madres de plaza de Mayo 949 (1878), Quilmes.</a>
                            <a>
                              <div class="mapa__content" *ngIf="mapa">
                                <span (click)="mapa = !mapa">x</span>
                                <iframe [ngClass]="'lazyload'" data-src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3278.7818186963987!2d-58.267091685238306!3d-34.735893572009736!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95a32e6449263693%3A0x2487ca7b31b60673!2sMessina+Hnos+S.A.!5e0!3m2!1sen!2sar!4v1527709856965" width="480" height="300" frameborder="0" style="border:0" allowfullscreen></iframe>
                              </div>
                              <p style="cursor: pointer;" (click)="mapa = !mapa">Ver Mapa</p>
                            </a>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-md-4">
                  <div class="envio__seccion--editable">
                    <div class="envio__seccion__header--editable ps-0">
                      <p class="p-0">DATOS DE FACTURACIÓN</p>
                      <!--button (click)="editingFacturacion = !editingFacturacion">
                      <img width="20" height="20" src="./assets/images/iconos/lapiz.webp" alt="">
                      </button-->
                    </div>
                    <div class="envio__seccion__content ps-0" *ngIf="!editingFacturacion">
                      <p>Nombre o Razón Social: {{facturacion.datos.razon_social || facturacion.datos.nombre_fantasia}}</p>
                      <p>CUIT/DNI: {{facturacion.datos.cuit}}</p>
                      <p>Cat. I.V.A.: {{facturacion.datos.categoria_iva}}</p>
                      <!-- <p>Dirección: {{facturacion.datos.domicilio_direccion}}</p> -->
                      <!-- <p>Localidad: {{facturacion.datos.domicilio_ciudad}} ({{facturacion.datos.domicilio_codigo_postal}})</p> -->
                      <!-- <p>Provincia: {{entrega.provincia.array[facturacion.datos.domicilio_provincia]}}</p> -->
                      <p>Email: {{facturacion.datos.email}}</p>
                      <!-- <p>Teléfono: {{facturacion.datos.telefono}}</p> -->
                    </div>
                    <div class="envio__seccion__content edit" *ngIf="editingFacturacion">
                      <div class="form-group row">
                        <label for="example-text-input" class="col-4 col-form-label">
                          Razón social
                        </label>
                        <div class="col-8">
                          <input class="form-control" type="text" [(ngModel)]="facturacion.datos.razon_social" aria-label="Razon Social">
                        </div>
                      </div>
                      <div class="form-group row">
                        <label for="example-text-input" class="col-4 col-form-label">
                          Nombre comercial
                        </label>
                        <div class="col-8">
                          <input class="form-control" type="text" [(ngModel)]="facturacion.datos.nombre_fantasia" aria-label="Nombre Comercial">
                        </div>
                      </div>
                      <div class="form-group row">
                        <label for="example-text-input" class="col-4 col-form-label">
                          CAT. IVA.
                        </label>
                        <div class="col-8">
                          <input class="form-control" type="text" [(ngModel)]="facturacion.datos.cod_categoria_iva" aria-label="Categoria IVA">
                        </div>
                      </div>
                      <div class="form-group row">
                        <label for="example-text-input" class="col-4 col-form-label">
                          Responsable compra
                        </label>
                        <div class="col-8">
                          <input class="form-control" type="text" [(ngModel)]="facturacion.datos.nombre_responsable_compras" aria-label="Nombre Responsable Compras">
                        </div>
                      </div>
                      <div class="form-group row">
                        <label for="example-text-input" class="col-4 col-form-label">
                          Responsable facturacion
                        </label>
                        <div class="col-8">
                          <input class="form-control" type="text" [(ngModel)]="facturacion.datos.facturacion_nombre_responsable" aria-label="Nombre Responsable Facturacion">
                        </div>
                      </div>
                      <!--div class="form-group row">
                        <label for="example-text-input" class="col-4 col-form-label">
                          {{facturacion.datos.cod_categoria_iva === "Consumidor Final" ? "DNI" : "CUIT"}}
                        </label>
                        <div class="col-8">
                          <input class="form-control" type="text" [(ngModel)]="facturacion.datos.cod_categoria_iva">
                          <img width="20" height="20" src="./assets/images/iconos/lapiz.webp" alt="">
                        </div>
                      </div>-->
                      <div class="form-group row">
                        <label for="example-text-input" class="col-4 col-form-label">
                          Dirección
                        </label>
                        <div class="col-8">
                          <input class="form-control" type="text" [(ngModel)]="facturacion.datos.domicilio_direccion" aria-label="Domicilio Facturacion Calle">
                        </div>
                      </div>
                      <div class="form-group row">
                        <label for="example-text-input" class="col-4 col-form-label">
                          Localidad
                        </label>
                        <div class="col-8">
                          <input class="form-control" type="text" [(ngModel)]="facturacion.datos.domicilio_ciudad" aria-label="Domicilio Facturacion Localidad">
                        </div>
                      </div>
                      <div class="form-group row">
                        <label for="example-text-input" class="col-4 col-form-label">
                          Código Postal
                        </label>
                        <div class="col-8">
                          <input class="form-control" type="text" maxlength="8" [(ngModel)]="facturacion.datos.cod_postal" aria-label="Domicilio Facturacion Codigo Postal">
                        </div>
                      </div>
                      <div class="form-group row">
                        <label for="example-text-input" class="col-4 col-form-label">
                          Provincia
                        </label>
                        <div class="col-8">
                          <input class="form-control" type="text" [(ngModel)]="facturacion.datos.domicilio_provincia" aria-label="Domicilio Facturacion Provincia">
                        </div>
                      </div>
                      <div class="form-group row">
                        <label for="example-text-input" class="col-4 col-form-label">
                          Email Fact.
                        </label>
                        <div class="col-8">
                          <input class="form-control" type="text" [(ngModel)]="facturacion.datos.facturacion_email" aria-label="Facturacion Email">
                        </div>
                      </div>
                      <div class="form-group row">
                        <label for="example-text-input" class="col-4 col-form-label">
                          Tel. Fact.
                        </label>
                        <div class="col-8">
                          <input class="form-control" type="text" [(ngModel)]="facturacion.datos.facturacion_telefono" aria-label="Facturacion Telefono">
                        </div>
                      </div>
                      <div class="form-group row">
                        <label for="example-text-input" class="col-4 col-form-label">
                          Telefono
                        </label>
                        <div class="col-8">
                          <input class="form-control" type="text" [(ngModel)]="facturacion.datos.telefono" aria-label="Telefono">
                        </div>
                      </div>
                      <div class="form-group row">
                        <label for="example-text-input" class="col-4 col-form-label">
                          Telefono Alternativo
                        </label>
                        <div class="col-8">
                          <input class="form-control" type="text" [(ngModel)]="facturacion.datos.telefono_celular" aria-label="Telefono Alternativo">
                        </div>
                      </div>
                      <div class="row" style="text-align:right; padding: 15px;">
                        <button (click)="facturacion.update()" class="whiteButton">GUARDAR</button>
                      </div>
                    </div>
                  </div>
                  <div class="envio__seccion">
                    <div class="row envio__seccion__header ps-0">
                      <p class="p-0">OBSERVACIONES</p>
                    </div>
                    <div class="envio__seccion__content">
                      <div class="form-group row">
                        <div class="col-12" style="margin-left: -15px;">
                          <textarea [(ngModel)]="observaciones" class="form-control" id="exampleTextarea" rows="3" aria-label="Observaciones"></textarea>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-md-4">
                  <div class="row carrito__titulo">
                    <p class="p-0 m-0">RESUMEN DE TU PEDIDO</p>
                  </div>
                  <div class="row envio">
                    <div class="envio__total">
                      <p>SUBTOTAL</p>
                      <h4>$ {{updateTotal(carrito.subtotal)}}</h4>
                    </div>
                    <div class="envio__total" style="font-weight: normal; padding-top: 0px;" *ngIf="(cupon.envio_gratis || carrito.subtotal >= config.montoEnvioGratis) && datosCompra.entrega === '0'">
                      <p style="padding-top: 22px;">ENVÍO SIN CARGO</p>
                      <h3 style="padding-left: 20px; font-size: 16px; font-weight: normal;" class="text-end"><span><s>$ {{config.costoEnvio}}</s><span *ngIf="isSectorInterior">*</span></span><br><span *ngIf="isSectorInterior">*Sin cargo hasta transporte situado en CABA, Bs. As.</span></h3>
                    </div>
                    <div class="envio__total" style="font-weight: normal; padding-top: 0px;" *ngIf="!cupon.envio_gratis && carrito.subtotal >= config.montoMinimo && carrito.subtotal < config.montoEnvioGratis && config.montoEnvio < config.montoEnvioGratis && datosCompra.entrega === '0' && datosCompra.agregado === '0' && config.costoEnvio > 0">
                      <p style="padding-top: 22px;">ENVÍO</p>
                      <h3 style="font-size: 16px; font-weight: normal;" class="text-end">$ {{config.costoEnvio}}<span *ngIf="isSectorInterior">*</span><br><span *ngIf="isSectorInterior">*Hasta transporte situado en CABA, Bs. As.</span></h3>
                    </div>
                    <div style="margin-top: 30px; padding-top: 20px;" class="envio__total envio__total__confirmar" *ngIf="cupon && cuponValido && showDescuentoCupon && descuentoCupon">
                      <p>DESCUENTOS</p>
                      <h2 style="bottom: -4px; font-size: 24px;">- $ {{updateTotal(descuentoCupon)}}</h2>
                    </div>
                    <hr *ngIf="cupon && cuponValido && cupon.codigo" style="margin-top: 20px;">
                    <div *ngIf="cupon && cuponValido && cupon.codigo">
                      <p style="text-align: center;">Cupón aplicado: "{{ cupon.codigo }}"</p>
                    </div>
                    <!-- Loader -->
                    <div *ngIf="cuponLoading" style="width: 50px; height: 50px; margin: 10px auto;">
                      <div class="sina__loader"></div>
                    </div>
                    <hr *ngIf="!cuponLoading && !cuponValido && cuponErrorMsg" style="margin-top: 20px;">
                    <div *ngIf="!cuponLoading && !cuponValido && cuponErrorMsg">
                      <p style="margin-top: 10px; color: red; text-align: center;" *ngIf="!cuponLoading && !cuponValido && cuponErrorMsg">
                        {{ cuponErrorMsg }}
                        <br>
                        Si deseas aplicar otro cupón, podes hacerlo volviendo
                        <button type="button" class="w-auto" style="border: none; background: transparent; padding: 0; color: red; text-decoration: underline;" (click)="transaccion.cambio(0); checkCarritoInit(0);">Atrás</button>,
                        o bien, podés finalizar la compra sin cupón haciendo clic abajo.
                      </p>
                    </div>
                    <hr style="margin-top: 20px;">
                    <div class="envio__total">
                      <p>TOTAL</p>
                      <h2 *ngIf="!cupon.envio_gratis && carrito.subtotal >= config.montoMinimo && carrito.subtotal < config.montoEnvioGratis && config.montoEnvio < config.montoEnvioGratis && datosCompra.entrega === '0' && datosCompra.agregado === '0' && config.costoEnvio > 0">$ {{updateTotal(carrito.subtotal + config.costoEnvio - descuentoCupon)}}</h2>
                      <h2 *ngIf="!(!cupon.envio_gratis && carrito.subtotal >= config.montoMinimo && carrito.subtotal < config.montoEnvioGratis && config.montoEnvio < config.montoEnvioGratis && datosCompra.entrega === '0' && datosCompra.agregado === '0' && config.costoEnvio > 0)">$ {{updateTotal(carrito.subtotal - descuentoCupon)}}</h2>
                      <span style="position: absolute; bottom: -30px; right: 20px; font-weight: initial;">{{iva_usuario.includes('NO INCLUYE') ? '+ I.V.A.' : (iva_usuario.includes('INCLUYE') ? 'I.V.A. INCLUIDO' : (iva_usuario.includes('FINAL') ? 'FINAL' : 'SUJETO A SU CONDICIÓN HABITUAL'))}}</span>
                    </div>

                    <div class="envio__total" *ngIf="datosCompra.entrega === '1'">
                      <img width="44" height="50" [ngClass]="'lazyload'" data-src="./assets/images/iconos/retiro.webp" alt="" style="height: 50px; margin-right: 10px;">
                      <p>Retiro en tienda</p>
                    </div>
                    <div class="envio__confirm" style="margin-bottom: 15px;">
                      <button type="button" class="actionButton" [ngClass]="{'deshabilitado': carritoLoading}" (click)="completarCompra()">FINALIZAR COMPRA</button>
                      <div *ngIf="datosCompra.agregado === '0' && datosCompra.entrega === '1' && (flagHoraPasada || dia === 'Domingo' || !retiro)">
                        <p style="color: red;">Error en el horario de retiro seleccionado</p>
                      </div>
                      <div [ngClass]="{textoVerde: pedidoCorrecto === true, textoRojo: pedidoCorrecto !== true}">
                        <p> {{completarCompraTexto}} <a href="/compra/envio" *ngIf="pedidoCorrecto !== true">Hacé click acá para recargar la página</a></p>
                      </div>
                    </div>
                    <div class="envio__header p-0">
                      <div class="custom__col-1">
                        <p>Cant.</p>
                      </div>
                      <div class="custom__col-5">
                        <p>Artículo</p>
                      </div>
                      <div class="custom__col-4">
                        <p>Subtotal</p>
                      </div>
                    </div>
                    <div class="envio__content p-0" *ngFor="let compra of carrito.lista">
                      <div class="custom__col-1">
                        <p>{{compra.cantidad}}</p>
                      </div>
                      <div class="custom__col-5 w-auto">
                        <p style="text-overflow: ellipsis; overflow: hidden; white-space: nowrap;">{{compra.descripcion}}</p>
                      </div>
                      <div class="custom__col-4">
                        <p>${{updatePrecio(compra.precio, compra.cantidad)}}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="tab__item" *ngIf="transaccion.check(2)">
            <div class="content">
              <div class="row tab__item__header">
                <p>Tu código de Cliente es {{facturacion.datos.codigoCliente}}</p>
                <span class="w-auto">{{secciones[transaccion.paso]}}</span>
              </div>
              <div class="row">
                <div class="cuadro">
                  <h1>¡MUCHAS GRACIAS POR SU PEDIDO!</h1>
                  <p>{{completarCompraTexto}}</p>
                  <p *ngIf="completarCompraTextoExtra"><strong>{{completarCompraTextoExtra}}</strong></p>
                  <p>Tené en cuenta que el mismo es válido únicamente como solicitud de cotización.</p>
                  <p *ngIf="!(facturacion.datos.dataFiscal == 1 || facturacion.datos.dataFiscal == true)" style="color: red;">¡ADEUDA CM05!</p>
                  <p *ngIf="!(facturacion.datos.dataFiscal == 1 || facturacion.datos.dataFiscal == true)"  style="padding-top:10px;text-align:left;">
                    Enviá a infoweb@sina.com.ar la información fiscal respecto de tu condición tanto ante AFIP como en rentas o cualquier modificación que tuvieras en un plazo de 30 días.
                    <br>
                    Si estás inscripto en convenio multilateral deberás presentar cada año el formulario CM05 actualizado, de lo contrario se aplicará la percepción máxima.
                  </p>
                  <p style="font-size: 15px;">Los envíos al interior del país son sin cargo <strong style="text-decoration: underline;">únicamente</strong> hasta el deposito del transporte u expreso de cargas ubicado en Capital Federal o Buenos Aires; el costo de servicio logístico desde el deposito del transporte hacia el destino solicitado es a cuenta del cliente.</p>
                  <a href="{{completarCompraLink}}" *ngIf="completarCompraLink" target="_blank" rel="noopener noreferrer">
                    <button class="actionButton">IMPRIMIR SOLICITUD</button>
                  </a>
                  <img width="35" height="35" [ngClass]="'lazyload'" data-src="./assets/images/header/logo_mobile_azul.webp" alt="">
                </div>
              </div>
              <div class="row confirmacion">
                <div class="col-12 col-md-3">
                  <div class="envio__seccion__header">
                    <p style="padding: 0px 15px;">FORMA DE ENTREGA</p>
                  </div>
                  <div *ngIf="datosCompra.agregado === '0'" class="row">
                    <div class="envio__seccion__opcion" *ngIf="datosCompra.entrega === '0'">
                      <img width="66" height="50" [ngClass]="'lazyload'" data-src="./assets/images/iconos/camion.webp" alt="">
                      <p>Envío</p>
                    </div>
                    <div class="envio__seccion__opcion" *ngIf="datosCompra.entrega === '1'">
                      <img width="44" height="50" [ngClass]="'lazyload'" data-src="./assets/images/iconos/retiro.webp" alt="">
                      <p>Retiro en tienda</p>
                    </div>
                  </div>
                  <div *ngIf="datosCompra.agregado === '1'">
                    <p style="padding: 0px 20px;">Agregado al pedido ID #{{idPedidoAgregado}}</p>
                  </div>
                </div>
                <div class="col-12 col-md-3 envio__seccion--editable">
                  <div class="envio__seccion__header--editable ps-0">
                    <p>DATOS DE ENVÍO</p>
                  </div>
                  <div class="envio__seccion__content">
                    <p>{{datos_envio.domicilio_direccion}}</p>
                    <p>{{datos_envio.domicilio_ciudad}} ({{datos_envio.domicilio_codigo_postal}})</p>
                    <p>{{entrega.provincia.array[datos_envio.domicilio_provincia]}}</p>
                    <p>{{datos_envio.telefono}}</p>
                    <p>{{datos_envio.cod_transporte ? entrega.transporte.array[datos_envio.cod_transporte] : "Debe seleccionar un medio de transporte"}}</p>
                  </div>
                </div>
                <div class="col-12 col-md-3 envio__seccion--editable">
                  <div class="envio__seccion__header--editable ps-0">
                    <p>DATOS DE FACTURACIÓN</p>
                  </div>
                  <div class="envio__seccion__content">
                    <p>Nombre o Razón Social: {{facturacion.datos.razon_social || facturacion.datos.nombre_fantasia}}</p>
                    <p>CUIT/DNI: {{facturacion.datos.cuit}}</p>
                    <p>Cat. I.V.A.: {{facturacion.datos.categoria_iva}}</p>
                    <p>Email: {{facturacion.datos.email}}</p>
                    <!-- <p>Nombre comercial: {{facturacion.datos.nombre_fantasia}}</p>
                    <p>Telefono: {{facturacion.datos.telefono}}</p>
                    <p>Telefono alternativo: {{facturacion.datos.telefono_celular}}</p>
                    <p>Nombre responsable de compras: {{facturacion.datos.nombre_responsable_compras}}</p>
                    <p>Nombre responsable de facturación: {{facturacion.datos.facturacion_nombre_responsable}}</p>
                    <p>Email de facturación: {{facturacion.datos.facturacion_email}}</p>
                    <p>Telefono de facturación: {{facturacion.datos.facturacion_telefono}}</p>
                    <p>Dirección: {{facturacion.datos.domicilio_direccion}}</p>
                    <p>Ciudad: {{facturacion.datos.domicilio_ciudad}} CP: {{facturacion.datos.domicilio_codigo_postal}}</p>
                    <p>Provincia: {{entrega.provincia.array[facturacion.datos.domicilio_provincia]}}</p> -->
                  </div>
                </div>
                <div class="col-12 col-md-3">
                  <div class="row envio" style="margin-left: 0px; margin-right: 0px;">
                    <div class="envio__total">
                      <p>TOTAL</p>
                      <h3>$ {{formatMoney(total)}}</h3>
                      <span style="position: absolute; bottom: -20px; right: 20px; font-weight: initial;">{{iva_usuario.includes('NO INCLUYE') ? '+ I.V.A.' : (iva_usuario.includes('INCLUYE') ? 'I.V.A. INCLUIDO' : (iva_usuario.includes('FINAL') ? 'FINAL' : 'SUJETO A SU CONDICIÓN HABITUAL'))}}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row volver">
                <a href="{{completarCompraLink}}" *ngIf="completarCompraLink" target="_blank" rel="noopener noreferrer" style="margin-right: -5px;">
                  <button class="whiteButton">IMPRIMIR</button>
                </a>
              </div>
            </div>
          </div>
        </div>
        <br>
        <div *ngIf="config.switchComprasActivo">
          <p class="iva__custom__border w-auto">{{iva_usuario}}</p>
        </div>
        <br>
      </div>
    </div>
  </div>
</div>

<app-loading *ngIf="modalLoading" ></app-loading>
